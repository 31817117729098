import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";
import type { ButtonVariant } from "./GhostBtn";

const alert = (theme: Theme) => css`
  position: relative;
  padding: 0;
  font: ${theme.font.medium_15};
  color: ${theme.color.gray_50};

  &:not(:disabled):hover {
    color: ${theme.color.gray_70};

    &::before {
      background-color: ${theme.color.gray_70};
    }
  }

  &:disabled {
    opacity: 0.6;
  }
`;

const alert_blue = (theme: Theme) => css`
  position: relative;
  padding: 0;
  font: ${theme.font.medium_15};
  color: ${theme.color.blue_10};

  &:not(:disabled):hover {
    color: ${theme.color.blue_30};

    &::before {
      background-color: ${theme.color.blue_30};
    }
  }

  &:disabled {
    color: ${theme.color.blue_10_10};

    &::before {
      background-color: ${theme.color.blue_10_10};
    }
  }
`;

const alert_red = (theme: Theme) => css`
  position: relative;
  padding: 0;
  font: ${theme.font.medium_15};
  color: ${theme.color.red_20};

  &:not(:disabled):hover {
    color: ${theme.color.red_30};

    &::before {
      background-color: ${theme.color.red_30};
    }
  }

  &:disabled {
    color: ${theme.color.red_10};

    &::before {
      background-color: ${theme.color.red_10};
    }
  }
`;

const ghost = (theme: Theme) => css`
  position: relative;
  padding: 0;
  font: ${theme.font.regular_14};
  color: ${theme.color.gray_70};

  &:not(:disabled):hover {
    color: ${theme.color.gray_80};
  }
`;

const ghost_blue = (theme: Theme) => css`
  position: relative;
  padding: 0;
  font: ${theme.font.regular_14};
  color: ${theme.color.blue_10};

  &:not(:disabled):hover {
    color: ${theme.color.blue_30};
  }
`;

const ghost_red = (theme: Theme) => css`
  position: relative;
  padding: 0;
  font: ${theme.font.regular_14};
  color: ${theme.color.red_20};

  &:not(:disabled):hover {
    color: ${theme.color.red_30};
  }
`;

export const GhostBtn = styled.button<{
  variant: ButtonVariant;
  isLoading?: boolean;
}>`
  ${({ theme, variant, isLoading }) => css`
    ${variant === "alert" && alert(theme)};
    ${variant === "alert_blue" && alert_blue(theme)};
    ${variant === "alert_red" && alert_red(theme)};
    ${variant === "ghost" && ghost(theme)};
    ${variant === "ghost_blue" && ghost_blue(theme)};
    ${variant === "ghost_red" && ghost_red(theme)};
    pointer-events: ${isLoading && "none"};
  `}
`;
