import React, { useState, useCallback } from "react";

import { Button } from "components";
import { formatDate } from "utils";
import { ResetIcon, DownloadIcon } from "assets";
import * as S from "./DataSection.styled";
import Table from "./table/Table";
import CheckTable from "./checkTable/CheckTable";

interface DataSectionProps {
  className?: string;
  children: React.ReactNode;
  totalData: number;
  activeBtns?: React.ReactNode;
  dataUpdatedAt?: number;
  refetch: () => void;
  title?: string;
}

interface ActiveWrapperProps {
  className?: string;
  activeBtn: React.ReactNode;
  isActive?: boolean;
  isDisabled?: boolean;
  handleConfirm?: (e: React.MouseEvent) => void;
  handleUnActiveCheckTable?: () => void;
}

interface ExcelDownloadBtnProps {
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  handleDownload: () => void;
}

export interface ActiveBtnProps {
  className?: string;
  label: string;
  variant?: "primary" | "secondary";
  isDisabled?: boolean;
  handleClickActiveBtn: () => void;
}

const DataSection = ({
  children,
  className,
  totalData,
  activeBtns,
  dataUpdatedAt,
  refetch,
  title = "목록",
}: DataSectionProps) => {
  const [rotationAngle, setRotationAngle] = useState(0);

  const handleRefetch = useCallback(() => {
    setRotationAngle(rotationAngle + 360);

    refetch && refetch();
  }, [refetch, rotationAngle]);

  return (
    <section className={className}>
      <S.Header>
        <S.LeftContent>
          <h2>
            {title}&#32;({totalData})
          </h2>
          <S.Refetch>
            <span>마지막 업데이트:</span>
            {dataUpdatedAt && <time>{formatDate(dataUpdatedAt)}</time>}
            <S.RefetchBtn
              type="button"
              rotationAngle={rotationAngle}
              onClick={handleRefetch}
            >
              <ResetIcon />
            </S.RefetchBtn>
          </S.Refetch>
        </S.LeftContent>
        {activeBtns}
      </S.Header>
      <S.TableWrapper>{children}</S.TableWrapper>
    </section>
  );
};

DataSection.ActiveWrapper = function ActiveWrapper({
  className,
  activeBtn,
  isActive,
  isDisabled,
  handleConfirm,
  handleUnActiveCheckTable,
}: ActiveWrapperProps) {
  return (
    <S.ActiveWrapper className={className}>
      {isActive ? (
        <>
          <Button
            variant="secondary"
            label="Cancel"
            handleClickBtn={handleUnActiveCheckTable}
          />
          <Button
            isDisabled={!isDisabled}
            variant="primary"
            label="Confirm"
            handleClickBtn={handleConfirm}
          />
        </>
      ) : (
        <>{activeBtn}</>
      )}
    </S.ActiveWrapper>
  );
};

DataSection.ExcelDownloadBtn = function ExcelDownloadBtn({
  handleDownload,
  isLoading,
  isDisabled,
}: ExcelDownloadBtnProps) {
  return (
    <Button
      icon={<DownloadIcon />}
      isLoading={isLoading}
      isDisabled={isDisabled}
      variant="secondary"
      label="엑셀 다운로드"
      handleClickBtn={handleDownload}
    />
  );
};

DataSection.ActiveBtn = function CheckTableActiveBtn({
  className,
  label,
  variant = "secondary",
  isDisabled = false,
  handleClickActiveBtn,
}: ActiveBtnProps) {
  return (
    <Button
      className={className}
      variant={variant}
      label={label}
      isDisabled={isDisabled}
      handleClickBtn={handleClickActiveBtn}
    />
  );
};

DataSection.Table = Table;

DataSection.CheckTable = CheckTable;

export default DataSection;
