import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import {
  deleteFilterQuery,
  getFilterConditionKeys,
  getFilterKeyNames,
  getFilterQuery,
  pickQueryWithParseDate,
} from "utils";
import type { FilterCondition } from "types";

interface FilterState {
  [key: string]: string;
}

const useSearchLabel = (filterConditions: FilterCondition[][]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchLabel, setSearchLabel] = useState<FilterState>({});

  const filterKeyNames = getFilterKeyNames(filterConditions);

  const isEmptyLabel =
    Object.values(searchLabel).filter((item) => item.length !== 0).length === 0;

  const handleDeleteSearchLabel = useCallback(
    (target: { key: string; value: string }) => () => {
      const queries = getFilterQuery(searchParams);
      const newQuery = deleteFilterQuery(queries, target);

      setSearchParams(newQuery);
    },
    [searchParams],
  );

  const handleClickReset = useCallback(() => {
    const tabQuery = searchParams.get("tab");

    setSearchParams({ ...(tabQuery && { tab: tabQuery }) });
  }, []);

  const getFilterList = (filterConditions: FilterCondition[][]) =>
    filterConditions
      .flatMap((condition) =>
        condition
          .filter((item) => item.type === "radio")
          .map((item) => ({
            [item.key]: item.filterList,
          })),
      )
      .reduce((acc, current) => ({ ...acc, ...current }), {});

  useEffect(() => {
    const getSearchLabel = <T extends Object>(query: T) => {
      const filterConditionKeys = getFilterConditionKeys(
        filterConditions,
        (filter) => filter.type !== "notice",
      );

      return pickQueryWithParseDate(query, filterConditionKeys);
    };

    const query = getFilterQuery(searchParams);
    const filterSearchLabel = getSearchLabel(query);
    const filterList = getFilterList(filterConditions);

    const searchLabel = Object.entries(filterSearchLabel).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]:
          filterList[key]?.find((item) => item.key === value)?.label ?? value,
      }),
      {},
    );

    setSearchLabel(searchLabel);
  }, [searchParams]);

  return {
    isEmptyLabel,
    filterKeyNames,
    searchLabel,
    handleDeleteSearchLabel,
    handleClickReset,
  };
};

export default useSearchLabel;
