import type { AxiosResponse } from "axios";

import type { S3PresignedServerModel } from "types";

export const getS3FileFormData = (
  s3Info: S3PresignedServerModel,
  file: File,
) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(s3Info.data.fields)) {
    formData.append(key, value);
  }
  formData.append("Content-type", file.type);
  formData.append("file", file);

  return formData;
};

export const downloadFile = (url: string, name: string) => {
  const a = document.createElement("a");

  a.href = url;
  a.download = name;
  document.body.appendChild(a);
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 100);
};

export const downloadExcelFile = (data: AxiosResponse<any, any>) => {
  const url = window.URL.createObjectURL(new Blob([data?.data]));
  const filename = data.headers["content-disposition"].split("filename=")[1];

  downloadFile(url, filename);
};
