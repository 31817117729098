import React from "react";

import * as S from "./ImgPreviewLink.styled";

interface ImgPreviewLinksProps {
  imgUrls: string[];
}

const ImgPreviewLinks = ({ imgUrls }: ImgPreviewLinksProps) => {
  return (
    <S.ImgWrapper>
      {imgUrls.map((item, i) => (
        <S.ImgLink key={i} href={item} target="_blank" rel="noreferrer">
          {`첨부파일 ${i + 1}`}
        </S.ImgLink>
      ))}
    </S.ImgWrapper>
  );
};

export default ImgPreviewLinks;
