import React from "react";

import { DataSectionSkeleton } from "components";
import {
  NOTICE_SUPPORT_TABLE_GRID_COLUMNS,
  NOTICE_SUPPORT_TABLE_HEADER,
} from "assets";

const NoticeTableSkeleton = () => {
  return (
    <DataSectionSkeleton>
      <DataSectionSkeleton.CheckTable
        columnTable={NOTICE_SUPPORT_TABLE_HEADER}
        gridTemplateColumns={NOTICE_SUPPORT_TABLE_GRID_COLUMNS}
      />
    </DataSectionSkeleton>
  );
};

export default NoticeTableSkeleton;
