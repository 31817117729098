import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { LabelContent } from "components";

export const CusomtLabelContent = styled(LabelContent)`
  ${({ theme }) => css`
    margin-bottom: 40px;
    & + & {
      margin-bottom: 41px;
    }

    & > div:nth-of-type(2) {
      margin-bottom: 14px;
    }

    & > div:nth-of-type(3) {
      border-top: 1px solid ${theme.color.gray_20};
    }
  `}
`;

export const RedText = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.red_30};
  `};
`;

export const division = css`
  margin-bottom: 40px;
`;

export const LinkButton = styled.button`
  ${({ theme }) => css`
    font: ${theme.font.regular_14};
    color: ${theme.color.blue_10};
    text-decoration-line: underline;
  `}
`;
