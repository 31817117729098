import React, { Suspense } from "react";

import {
  PageLayout,
  RepairshopDataTable,
  RepairshopDataTableSkeleton,
  RepairshopDetailBtns,
  Division,
} from "components";
import { REPAIRSHOP_DETAIL_PAGE_LAYOUT } from "assets";
import * as S from "./RepairshopDetail.styled";

const RepairshopDetail = () => {
  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={REPAIRSHOP_DETAIL_PAGE_LAYOUT}
    >
      <Division css={S.division} />
      <Suspense fallback={<RepairshopDataTableSkeleton />}>
        <RepairshopDataTable />
      </Suspense>
      <Division css={S.division} />
      <RepairshopDetailBtns />
    </PageLayout>
  );
};

export default RepairshopDetail;
