import React from "react";

import {
  PageLayout,
  NoticeSupportDetailBtns,
  NoticeSupportDetailTable,
} from "components";
import { REPAIRSHOP_NOTICE_SUPPORT_DETAIL_PAGE_LAYOUT } from "assets";
import * as S from "./RepairshopNoticeSupportDetail.styled";

const RepairshopNoticeSupportDetail = () => {
  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={REPAIRSHOP_NOTICE_SUPPORT_DETAIL_PAGE_LAYOUT}
    >
      <NoticeSupportDetailTable />
      <NoticeSupportDetailBtns />
    </PageLayout>
  );
};

export default RepairshopNoticeSupportDetail;
