import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Form = styled.form`
  & > div:last-of-type {
    margin-bottom: 40px;
  }
`;

export const content = (theme: Theme) => css`
  &:focus-within {
    border-bottom: 1px solid ${theme.color.blue_10};

    & > dt {
      color: ${theme.color.blue_10};
      background-color: ${theme.color.blue_10_10};
    }
  }
`;

export const title = css`
  width: 528px;
`;

export const answerContent = css`
  padding-bottom: 25px;
`;
