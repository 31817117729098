import React from "react";

import { ConfirmModal } from "components";

interface TruckDeleteModalProps {
  handleDelete: () => void;
}

const TruckDeleteModal = React.forwardRef(
  (
    { handleDelete }: TruckDeleteModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        title="차량 삭제"
        desc="삭제된 차량은 복구가 불가합니다. 정말 삭제하시겠습니까?"
        btnType="alert"
        activeBtnName="삭제"
        activeFn={handleDelete}
      />
    );
  },
);

TruckDeleteModal.displayName = "TruckDeleteModal";

export default TruckDeleteModal;
