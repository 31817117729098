import styled from "@emotion/styled";
import { css } from "@emotion/react";

interface LabelProps {
  hasBg?: boolean;
}

const Status = styled.span<LabelProps>`
  ${({ theme, hasBg }) => css`
    padding: ${hasBg && "4px 10px"};
    border-radius: ${hasBg && "4px"};
    font: ${theme.font.medium_13};
  `}
`;

export const GrayLabel = styled(Status)<LabelProps>`
  ${({ theme, hasBg }) => css`
    color: ${hasBg ? theme.color.gray_50 : theme.color.gray_60};
    background-color: ${hasBg && theme.color.gray_20};
  `}
`;

export const RedLabel = styled(Status)<LabelProps>`
  ${({ theme, hasBg }) => css`
    color: ${theme.color.red_20};
    background-color: ${hasBg && theme.color.red_10};
  `}
`;

export const OrangeLabel = styled(Status)<LabelProps>`
  ${({ theme, hasBg }) => css`
    color: ${theme.color.orange_20};
    background-color: ${hasBg && theme.color.orange_10};
  `}
`;

export const BlueLabel = styled(Status)<LabelProps>`
  ${({ theme, hasBg }) => css`
    color: ${theme.color.blue_10};
    background-color: ${hasBg && theme.color.blue_10_10};
  `}
`;

export const GreenLabel = styled(Status)<LabelProps>`
  ${({ theme, hasBg }) => css`
    color: ${theme.color.green_20};
    background-color: ${hasBg && theme.color.green_10};
  `}
`;

export const BlackLabel = styled(Status)<LabelProps>`
  ${({ theme }) => css`
    color: ${theme.color.gray_80};
  `}
`;
