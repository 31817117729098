import React from "react";
import DaumPostcode from "react-daum-postcode";

import * as S from "./DaumPostcodeModal.styled";
import { ModalPortal } from "../../portals";

interface DaumPostcodeModalProps {
  handleModalClose: any;
  handleSearchPostcode: any;
}

const daumPostcodeStyle = {
  display: "block",
  width: "100%",
  height: "100%",
};

const DaumPostcodeModal = ({
  handleModalClose,
  handleSearchPostcode,
}: DaumPostcodeModalProps) => {
  return (
    <ModalPortal>
      <S.Dim onClick={handleModalClose}>
        <S.Modal open={true}>
          <S.CloseBtn type="button" onClick={handleModalClose}>
            x
          </S.CloseBtn>
          <DaumPostcode
            style={daumPostcodeStyle}
            onClose={handleModalClose}
            onComplete={handleSearchPostcode}
          />
        </S.Modal>
      </S.Dim>
    </ModalPortal>
  );
};

export default DaumPostcodeModal;
