import {
  REPAIRSHOP_DETAIL,
  REPAIRSHOP_CREATE,
  REPAIRSHOP_EDIT,
  TRUCK_DETAIL,
  REPORT_REVIEW_DETAIL,
  REPORT_USER_DETAIL,
  REPORT_BULLETIN_DETAIL,
  REPORT_COMMENT_DETAIL,
  OWNER_SUPPORT_DETAIL,
  REPAIRSHOP_SUPPORT_DETAIL,
  USER_NOTICE_SUPPORT_DETAIL,
  REPAIRSHOP_NOTICE_SUPPORT_DETAIL,
  USER_NOTICE_SUPPORT_CREATE,
  REPAIRSHOP_NOTICE_SUPPORT_CREATE,
  USER_NOTICE_SUPPORT_EDIT,
  REPAIRSHOP_NOTICE_SUPPORT_EDIT,
} from "./history";

export const REPAIRSHOP_DETAIL_PAGE_LAYOUT = {
  heading: "정비소 상세",
  histories: REPAIRSHOP_DETAIL,
};

export const REPAIRSHOP_CREATE_PAGE_LAYOUT = {
  heading: "정비소 추가",
  subHeading: "필수 정보를 입력하세요.",
  histories: REPAIRSHOP_CREATE,
};

export const REPAIRSHOP_EDIT_PAGE_LAYOUT = {
  heading: "정비소 수정",
  subHeading: "필수 정보를 입력하세요.",
  histories: REPAIRSHOP_EDIT,
};

export const TRUCK_DETAIL_PAGE_LAYOUT = {
  heading: "차량 상세",
  histories: TRUCK_DETAIL,
};

export const REVIEW_REPORT_DETAIL_PAGE_LAYOUT = {
  heading: "신고 상세",
  histories: REPORT_REVIEW_DETAIL,
};

export const USER_REPORT_DETAIL_PAGE_LAYOUT = {
  heading: "신고 상세",
  histories: REPORT_USER_DETAIL,
};

export const BULLETIN_REPORT_DETAIL_PAGE_LAYOUT = {
  heading: "신고 상세",
  histories: REPORT_BULLETIN_DETAIL,
};

export const COMMENT_REPORT_DETAIL_PAGE_LAYOUT = {
  heading: "신고 상세",
  histories: REPORT_COMMENT_DETAIL,
};

export const OWNER_SUPPORT_DETAIL_PAGE_LAYOUT = {
  heading: "문의 상세",
  histories: OWNER_SUPPORT_DETAIL,
};

export const REPAIRSHOP_SUPPORT_DETAIL_PAGE_LAYOUT = {
  heading: "문의 상세",
  histories: REPAIRSHOP_SUPPORT_DETAIL,
};

export const USER_NOTICE_SUPPORT_DETAIL_PAGE_LAYOUT = {
  heading: "공지 상세",
  histories: USER_NOTICE_SUPPORT_DETAIL,
};

export const REPAIRSHOP_NOTICE_SUPPORT_DETAIL_PAGE_LAYOUT = {
  heading: "공지 상세",
  histories: REPAIRSHOP_NOTICE_SUPPORT_DETAIL,
};

export const USER_NOTICE_SUPPORT_CREATE_PAGE_LAYOUT = {
  heading: "공지 추가",
  histories: USER_NOTICE_SUPPORT_CREATE,
};

export const REPAIRSHOP_NOTICE_SUPPORT_CREATE_PAGE_LAYOUT = {
  heading: "공지 추가",
  histories: REPAIRSHOP_NOTICE_SUPPORT_CREATE,
};

export const USER_NOTICE_SUPPORT_EDIT_PAGE_LAYOUT = {
  heading: "공지 수정",
  histories: USER_NOTICE_SUPPORT_EDIT,
};

export const REPAIRSHOP_NOTICE_SUPPORT_EDIT_PAGE_LAYOUT = {
  heading: "공지 수정",
  histories: REPAIRSHOP_NOTICE_SUPPORT_EDIT,
};
