import styled from "@emotion/styled";
export const Dim = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${({ theme }) => theme.zIndex.MODAL};
`;

export const Modal = styled.dialog`
  display: flex;
  flex-flow: column;
  align-items: end;
  width: 500px;
  height: 470px;
  margin: 0 auto;
  border: none;
  background-color: ${({ theme }) => theme.color.white};
`;

export const CloseBtn = styled.button`
  width: 10px;
  margin-right: 10px;
  font-size: 2rem;
`;
