import React from "react";

import { QueryPagination } from "components";
import { useGetStatics } from "services";
import { useFilterParams } from "hooks";
import type { GetStaticsQueryModel } from "types";

const StaticsPagination = () => {
  const { filters } = useFilterParams<GetStaticsQueryModel["query"]>([
    "page",
    "repairshopName",
    "dateStart",
    "dateEnd",
  ]);

  const { data } = useGetStatics({
    query: filters,
  });

  return <QueryPagination pageInfo={data?.pageInfo!} />;
};

export default StaticsPagination;
