import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const fieldTableForm = (theme: Theme) => css`
  width: 476px;

  tr > th {
    padding: 14px 20px;
  }

  caption {
    height: 40px;
    font: ${theme.font.medium_13};
  }
`;

export const radioContent = (theme: Theme, isRegistered: boolean) => css`
  border-top: 1px solid ${!isRegistered ? theme.color.gray_20 : "transparent"};
  margin-top: ${!isRegistered && "14px"};

  & > td {
    padding: 0;
  }
`;

export const formRadioBtn = css`
  height: 48px;
  column-gap: 40px;
  padding: 0 20px;
`;

export const formMargin = (theme: Theme) => css`
  border-top: 1px solid ${theme.color.gray_20};
  margin-top: 14px;
`;

export const Textarea = styled.div`
  ${({ theme }) => css`
    height: 208px;
    border-top: 1px solid ${theme.color.gray_20};
    padding: 13px 20px;
    white-space: pre-wrap;
    overflow-y: auto;

    & > span {
      left: 0;
    }
  `}
`;

export const deleteBtn = (theme: Theme) => css`
  width: fit-content;
  margin: 33px 0 0;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.5px;
    background-color: ${theme.color.gray_70};
  }

  &:not(:disabled):hover {
    &::before {
      background-color: ${theme.color.gray_80};
    }
  }
`;
