import React, { useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import { PasswordHideIcon, PasswordShowIcon } from "assets/icon";
import * as S from "./AccountInput.styled";

interface AccountInputProps {
  className?: string;
  label: string;
  isError: boolean;
  register: UseFormRegisterReturn;
  type?: "password";
  id: string;
  value: string;
  maxLength: number;
}

const AccountInput = ({
  className,
  label,
  type,
  register,
  id,
  value,
  isError,
  maxLength,
}: AccountInputProps) => {
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const handlePasswordShow = () => {
    setIsPasswordShow(!isPasswordShow);
  };

  return (
    <S.AccountInput className={className} hasValue={!!value}>
      <S.Label htmlFor={id}>{label}</S.Label>
      <S.Input
        isError={isError}
        id={id}
        maxLength={maxLength}
        type={type !== "password" ? type : isPasswordShow ? "text" : "password"}
        {...register}
      />
      <S.IconWrapper>
        {type === "password" && (
          <S.PasswordShowButton
            type="button"
            onClick={handlePasswordShow}
            tabIndex={-1}
          >
            {value &&
              (isPasswordShow ? <PasswordHideIcon /> : <PasswordShowIcon />)}
          </S.PasswordShowButton>
        )}
      </S.IconWrapper>
    </S.AccountInput>
  );
};

export default AccountInput;
