import { atom } from "recoil";

export const modalCompoState = atom<React.ReactNode[] | []>({
  key: "modalCompoState",
  default: [],
});

export const isModalOpenAtom = atom({
  key: "isModalOpenAtom",
  default: false,
});

interface ModalAtom {
  heading: string;
  content: string;
  cancelBtn: string;
  submitBtn: string;
  submitFn: () => void;
}

export const modalAtom = atom<ModalAtom>({
  key: "modalAtom",
  default: {
    heading: "",
    content: "",
    cancelBtn: "",
    submitBtn: "",
    submitFn: () => {},
  },
});

export const headerAtom = atom<{
  truckNumber?: string;
  repairshopName?: string;
}>({
  key: "headerAtom",
  default: {
    truckNumber: "",
    repairshopName: "",
  },
});

export const toastAtom = atom({
  key: "toastAtom",
  default: [],
});
