import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import Cookies from "js-cookie";

export const baseUrl = process.env.REACT_APP_BASE_URL;

const deleteCookie = (code: string) => {
  if (code === "UNAUTHORIZED") {
    alert("토큰이 유효하지 않습니다.");
  }

  if (code === "INVALID_REFRESH_TOKEN") {
    alert("로그인 인증이 만료되어, 재 로그인이 필요합니다.");
  }

  if (code === "DUPLICATE_SIGNIN_DETECTED") {
    alert("다른 기기에서 로그인하여, 재 로그인이 필요합니다.");
  }

  Cookies.remove("TRUCK_DOCTOR_ACCESSTOKEN");
  Cookies.remove("TRUCK_DOCTOR_REFRESHTOKEN");
  Cookies.remove("TRUCK_DOCTOR_PWUPDATED");
  window.location.reload();
};

const instance = axios.create({
  // url, body, header
  baseURL: baseUrl,
});

const createInstance = () => setInterceptors(instance);

// accessToken 유효기간 만료 시 재요청하는 함수
const getAccessToken = async (err: AxiosError) => {
  const refreshToken = Cookies.get("TRUCK_DOCTOR_REFRESHTOKEN");

  try {
    const updateAccessToken = await axios.post(`${baseUrl}/admin/auth/token`, {
      refreshToken,
    });

    Cookies.set("TRUCK_DOCTOR_ACCESSTOKEN", updateAccessToken.data.accessToken);

    return instance(err.config!);
  } catch (err: any) {
    // accessToken 만료로 refreshToken을 사용해서 재요청했으나
    // refreshToken까지 만료되어 재로그인을 유도하는 로직

    deleteCookie(err.response.data.code);
  }
};

const setInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    // 요청을 보내기 전에 수행할 일
    (config: InternalAxiosRequestConfig) => {
      const accesstoken = Cookies.get("TRUCK_DOCTOR_ACCESSTOKEN");
      config.headers["authorization"] = accesstoken
        ? "bearer " + accesstoken
        : null;
      return config;
    },
    // 오류 요청을 보내기전 수행할 일
    (err: AxiosError) => Promise.reject(err),
  );
  instance.interceptors.response.use(
    // 응답 데이터를 가공
    (response: AxiosResponse) => response,
    // 오류 응답을 처리
    async (err: AxiosError<{ code: string }>): Promise<unknown> => {
      const status = err?.response?.status;
      const code = err?.response?.data.code;

      if (status === 401) {
        if (code === "INVALID_ACCESS_TOKEN") {
          return getAccessToken(err);
        }

        deleteCookie(code!);
      }

      return Promise.reject(err);
    },
  );

  return instance;
};

export const ax = createInstance();
