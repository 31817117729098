import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getTruckListAPI,
  getTruckDetailAPI,
  approvalTruckAPI,
  denyTruckAPI,
  getCheckVinAPI,
  deleteTruckAPI,
} from "api";
import {
  numberComma,
  phoneNumHyphen,
  truckNum,
  utcTimeToLocalTime,
} from "utils";
import { TRUCK_DETAIL_STATUS } from "assets";
import type {
  APIError,
  ApprovalTruckQueryModel,
  DenyTruckQueryModel,
  GetTrucksQueryModel,
  TruckVinServerModel,
} from "types";

const truckKeys = {
  all: ["truck"],
  lists: () => [...truckKeys.all, "list"],
  list: (filters: GetTrucksQueryModel) => [...truckKeys.lists(), { filters }],
  details: () => [...truckKeys.all, "detail"],
  detail: (id: string) => [...truckKeys.details(), id],
};

export const useGetTrucks = (req: GetTrucksQueryModel) => {
  return useQuery({
    queryKey: truckKeys.list(req),
    queryFn: () => getTruckListAPI(req),
    select: (res) => ({
      pageInfo: res.pageInfo,
      trucks: res.trucks.map((truck) => ({
        addr: truck.addr ? truck.addr : "-",
        brandId: truck.brandId ? truck.brandId : "-",
        brandTitle: truck.brandTitle ? truck.brandTitle : "-",
        created: truck.created ? utcTimeToLocalTime(truck.created) : "-",
        updated: truck.updated ? utcTimeToLocalTime(truck.updated) : "-",
        mileage: truck.mileage ? `${numberComma(truck.mileage)}km` : "-",
        modelName: truck.modelName ? truck.modelName : "-",
        modelYear: truck.modelYear ? truck.modelYear : "-",
        regNum: truck.regNum ? `${truckNum(truck.regNum)}` : "-",
        status: TRUCK_DETAIL_STATUS[truck.status],
        detailId: truck.truckId ? truck.truckId : "-",
        userId: truck.userId ? truck.userId : "-",
        userName: truck.userName ? truck.userName : "-",
        userPhone: truck.userPhone ? `${phoneNumHyphen(truck.userPhone)}` : "-",
        vin: truck.vin ? truck.vin : "-",
      })),
    }),
    suspense: true,
  });
};

export const useGetTruckDetail = (req: string) => {
  return useQuery({
    queryKey: truckKeys.detail(req),
    queryFn: () => getTruckDetailAPI(req),
    select: (res) => ({
      addr: res.addr ? res.addr : "-",
      brandId: res.brandId ? res.brandId : "-",
      brandTitle: res.brandTitle ? res.brandTitle : "-",
      failedReason: res.failedReason ? res.failedReason : "",
      imgUrls: res.imgUrls,
      mileage: res.mileage ? `${numberComma(+res.mileage)}km` : "-",
      modelName: res.modelName ? res.modelName : "-",
      modelYear: res.modelYear ? res.modelYear : "-",
      regNum: res.regNum ? res.regNum : "-",
      status: TRUCK_DETAIL_STATUS[res.status],
      truckId: res.truckId ? res.truckId : "-",
      userId: res.userId ? res.userId : "-",
      userName: res.userName ? res.userName : "-",
      userPhone: res.userPhone ? res.userPhone : "-",
      vin: res.vin ? res.vin : "-",
      updated: res.updated ? utcTimeToLocalTime(res.updated) : "-",
    }),
    suspense: true,
  });
};

export const useApprovalTruck = () => {
  return useMutation<unknown, APIError, ApprovalTruckQueryModel>({
    mutationFn: (req) => approvalTruckAPI(req),
  });
};

export const useDenyTruck = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, DenyTruckQueryModel>({
    mutationFn: (req) => denyTruckAPI(req),
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(truckKeys.detail(req.truckId));
    },
  });
};

export const useGetCheckVin = (req: string, options: any) => {
  return useQuery<TruckVinServerModel>({
    ...options,
    queryKey: truckKeys.detail(req),
    queryFn: () => getCheckVinAPI(req),
    select: (res) => ({
      truckId: res.truckId ? res.truckId : null,
    }),
    enabled: false,
  });
};

export const useDeleteTruck = () => {
  return useMutation<unknown, APIError, string>({
    mutationFn: (req) => deleteTruckAPI(req),
  });
};
