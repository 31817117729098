export const REPARISHOP_DETAIL_REGISTRATION_INFO = [
  [
    { key: "name", heading: "정비소명" },
    { key: "created", heading: "등록 일시" },
  ],
  [
    { key: "email", heading: "이메일 주소" },
    { key: "emailYn", heading: "마케팅 정보 수신 동의" },
  ],
  [{ key: "phone", heading: "대표 연락처" }],
  [{ key: "addr", heading: "주소" }],
  [{ key: "brand", heading: "정비 가능 브랜드" }],
] as const;

export const REPARISHOP_DETAIL_SETTING = [
  [{ key: "status", heading: "상태" }],
  [{ key: "available", heading: "인앱 예약" }],
] as const;

export const REVIEW_REPORT_DETAIL = [
  [
    { key: "repairshopName", heading: "신고자(정비소명)" },
    { key: "created", heading: "신고 일시" },
  ],
  [{ key: "reason", heading: "신고사유" }],
] as const;

export const USER_REPORT_DETAIL = [
  [
    { key: "targetNickname", heading: "피신고자" },
    { key: "reportCount", heading: "누적 신고 접수 수" },
  ],
  [{ key: "targetStatus", heading: "피신고자 활동상태" }],
  [
    { key: "userNickname", heading: "신고자" },
    { key: "created", heading: "신고 일시" },
  ],
  [{ key: "reason", heading: "신고사유" }],
] as const;

export const BULLETIN_REPORT_DETAIL = [
  [
    { key: "userNickname", heading: "신고자(닉네임)" },
    { key: "created", heading: "신고 일시" },
  ],
  [{ key: "reason", heading: "신고사유" }],
] as const;

export const COMMENT_REPORT_DETAIL = [
  [
    { key: "userNickname", heading: "신고자(닉네임)" },
    { key: "created", heading: "신고 일시" },
  ],
  [{ key: "reason", heading: "신고사유" }],
] as const;
