import React from "react";

import { ConfirmModal } from "components";
import * as S from "./TruckNotDeleteModal.styled";

interface TruckNotDeleteModalProps {
  handleDelete: () => void;
}

const TruckNotDeleteModal = React.forwardRef(
  (
    { handleDelete }: TruckNotDeleteModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        css={S.deleteBtn}
        title="차량 삭제 불가"
        activeBtnName="확인"
        btnType="active"
        desc={"진행중인 정비건이 있어 차량 삭제가 불가합니다."}
        activeFn={handleDelete}
      />
    );
  },
);

TruckNotDeleteModal.displayName = "TruckNotDeleteModal";

export default TruckNotDeleteModal;
