import { ax } from "api/axios";

import type {
  GetRepairshopsQueryModel,
  GetRepairshopsServerModel,
  GetRepairshopDetailServerModel,
  UpdateRepairshopQueryModel,
  ChangeRepairshopStatusQueryModel,
  ChangeRepairshopAvailableQueryModel,
  CreateRepairshopQueryModel,
} from "types/index";

export const getRepairshopListAPI = async (req: GetRepairshopsQueryModel) => {
  const { data } = await ax.get<GetRepairshopsServerModel>(
    "/admin/repairshops",
    { params: req.query },
  );

  return data;
};

export const getRepairshopDetailAPI = async (repairshopId: string) => {
  const { data } = await ax.get<GetRepairshopDetailServerModel>(
    `/admin/repairshops/${repairshopId}`,
  );

  return data;
};

export const editRepairshopAPI = async (
  req:
    | UpdateRepairshopQueryModel
    | ChangeRepairshopStatusQueryModel
    | ChangeRepairshopAvailableQueryModel,
) => await ax.put(`/admin/repairshops/${req.repairshopId}`, req.body);

export const createRepairshopAPI = async (req: CreateRepairshopQueryModel) => {
  const { data } = await ax.post("/admin/repairshops", req.body);

  return data;
};

export const deleteRepairshopAPI = (repairshopId: string) =>
  ax.delete(`/admin/repairshops/${repairshopId}`);
