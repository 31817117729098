import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getOwnerSupportListAPI,
  getOwnerSupportDetailAPI,
  createAnswerOwnerAPI,
} from "api";
import { checkDeletedUser, utcTimeToLocalTime } from "utils";
import type {
  APIError,
  CreateSupportQueryModel,
  GetOwnerSupportsQueryModel,
} from "types";

const ownerSupportKeys = {
  all: ["owner"],
  lists: () => [...ownerSupportKeys.all, "list"],
  list: (filters: GetOwnerSupportsQueryModel) => [
    ...ownerSupportKeys.lists(),
    { filters },
  ],
  details: () => [...ownerSupportKeys.all, "detail"],
  detail: (id: string) => [...ownerSupportKeys.details(), id],
};

export const useGetOwnerSupports = (req: GetOwnerSupportsQueryModel) => {
  return useQuery({
    queryKey: ownerSupportKeys.list(req),
    queryFn: () => getOwnerSupportListAPI(req),
    select: (res) => ({
      pageInfo: res.pageInfo,
      helps: res.helps.map((help) => ({
        detailId: help.helpId,
        category:
          help.category === "증빙 서류 제출" ? "증빙서류제출" : help.category,
        userId: help.userId,
        userName: checkDeletedUser(help.userName),
        title: help.title,
        created: utcTimeToLocalTime(help.created),
        askStatus: help.status,
      })),
    }),
    suspense: true,
    keepPreviousData: true,
  });
};

export const useGetOwnerSupportDetail = (req: string) => {
  return useQuery({
    queryKey: ownerSupportKeys.detail(req),
    queryFn: () => getOwnerSupportDetailAPI(req),
    select: (res) => ({
      imgUrls: res.imgUrls,
      answerContent: res.answerContent,
      answerCreated: utcTimeToLocalTime(res.answerCreated),
      answerTitle: res.answerTitle,
      category: res.category,
      content: res.content,
      created: utcTimeToLocalTime(res.created) as string,
      helpId: res.helpId,
      status: res.status,
      title: res.title,
      userId: res.userId,
      userName: checkDeletedUser(res.userName),
    }),
    suspense: true,
  });
};

export const useCreateAnswerOwnerSupport = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, CreateSupportQueryModel>({
    mutationFn: (req) => createAnswerOwnerAPI(req),
    onSuccess: (_, { helpId }) => {
      queryClient.invalidateQueries(ownerSupportKeys.detail(helpId));
    },
  });
};
