import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  useEditRepairshop,
  useGetRepairshopDetail,
  useGetTruckBrand,
} from "services";
import { useToast } from "hooks";
import { formatPhoneNum, phoneNumSubtractMinus } from "utils";
import { HOOKFORM_ERR_OBJ, TOAST_MSG } from "assets";
import type { RepairshopFormType } from "types";

export const useEditRepairshopForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { addToast } = useToast();

  const { data: truckBrands } = useGetTruckBrand();
  const { data } = useGetRepairshopDetail(id!);
  const { mutate: updateRepairshopMutate } = useEditRepairshop();

  const {
    register,
    watch,
    reset,
    setValue,
    setError,
    setFocus,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = useForm<RepairshopFormType>({
    defaultValues: {
      name: "",
      email: "",
      emailError: "",
      phone: "",
      addr: "",
      addrDetail: "",
      zipcode: "",
      districtCode: "",
      longitude: "",
      latitude: "",
      brands: [],
      status: "inactive",
      available: "n",
    },
    mode: "all",
  });

  const checkValidate = () => {
    if (!watch("brands").length) setError("brands", HOOKFORM_ERR_OBJ.REQUIRED);
    if (!watch("addr")) setError("addr", HOOKFORM_ERR_OBJ.REQUIRED);
  };

  const handleUpdateRepairshop = (data: RepairshopFormType) => {
    checkValidate();

    updateRepairshopMutate(
      {
        repairshopId: id!,
        body: {
          ...data,
          brands: data.brands.map(({ brandId }) => ({ brandId })),
          phone: phoneNumSubtractMinus(data.phone),
          longitude: +data.longitude,
          latitude: +data.latitude,
        },
      },
      {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.REPAIRSHOP_EDIT);
          navigate(`/repairshop/${id}`);
        },
        onError: (error) => {
          if (error.response?.data.code === "REPAIRSHOP_ALREADY_EXISTS") {
            setError("emailError", {
              message: HOOKFORM_ERR_OBJ.EXIST_REPAIRSHOP_EMAIL.content,
            });
            setFocus("email");
          } else {
            addToast({
              type: "warning",
              content: error.response?.data.message,
            });
          }
        },
      },
    );
  };

  const handleErrorUpdateRepairshop = () => checkValidate();

  useEffect(() => {
    if (!data || !truckBrands) return;

    const selectedBrands = data.brands.map((brand) => ({
      brandId: brand.brandId,
      brandName: brand.title,
    }));

    reset({
      name: data.name,
      addr: data.addr,
      addrDetail: data.addrDetail,
      available: data.available === "가능" ? "y" : "n",
      brands: selectedBrands,
      districtCode: data.districtCode,
      email: data.email,
      latitude: `${data.latitude}`,
      longitude: `${data.longitude}`,
      phone: formatPhoneNum(data.phone),
      status: data.status === "활성화" ? "active" : "inactive",
      zipcode: data.zipcode,
    });
  }, [data, truckBrands]);

  return {
    request: data?.requests,
    truckBrands,
    register,
    watch,
    errors,
    setValue,
    setError,
    clearErrors,
    handleSubmit: handleSubmit(
      handleUpdateRepairshop,
      handleErrorUpdateRepairshop,
    ),
  };
};
