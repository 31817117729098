import styled from "@emotion/styled";
export const SearchLabelBoxWrapper = styled.div`
  display: flex;
  padding: 10px 20px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.gray_20}`};
`;

export const SearchLabelWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 8px;
  row-gap: 12px;
  max-width: fit-content;
`;

export const ResetBtn = styled.button`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 73px;
  height: 32px;
  margin-right: 33px;
  padding: 5px 8px;
  font: ${({ theme }) => theme.font.regular_14};
  color: ${({ theme }) => theme.color.gray_60};

  ::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -8px;
    transform: translateY(-50%);
    width: 1px;
    height: 20px;
    border-right: ${({ theme }) => `1px solid ${theme.color.gray_20}`};
  }
`;

export const SearchLabel = styled.div`
  position: relative;
  max-width: 300px;
  height: 32px;
  padding: 5px 32px 5px 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.color.gray_10};
`;

export const SearchName = styled.span`
  margin-right: 4px;
  font: ${({ theme }) => theme.font.medium_14};
  color: ${({ theme }) => theme.color.black};
`;

export const SearchValue = styled.span`
  font: ${({ theme }) => theme.font.regular_14};
  color: ${({ theme }) => theme.color.black};
`;

export const CloseBtn = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  margin-left: 8px;
`;
