import { useQuery, useMutation, UseQueryOptions } from "@tanstack/react-query";
import Cookies from "js-cookie";

import { loginAPI, logoutAPI, unrestricLoginAPI, resetPasswordAPI } from "api";
import type {
  APIError,
  LoginQueryModel,
  LoginServerModel,
  ResetPasswordQueryModel,
  UnrestrickLoginQueryModel,
} from "types";

const authKeys = {
  all: ["auth"],
  unrestric: ["unrestric"],
};

export const useLogin = () => {
  return useMutation<LoginServerModel, APIError, LoginQueryModel>({
    mutationFn: (req) => loginAPI(req),
    onSuccess: (res) => {
      Cookies.set("TRUCK_DOCTOR_ACCESSTOKEN", res.data.accessToken, {
        expires: 60,
      });
      Cookies.set("TRUCK_DOCTOR_REFRESHTOKEN", res.data.refreshToken, {
        expires: 60,
      });
      Cookies.set("TRUCK_DOCTOR_PWUPDATED", res.data.pwUpdated, {
        expires: 60,
      });
    },
  });
};

export const useLogout = () => {
  return useMutation({
    mutationFn: () => logoutAPI(),
    onSuccess: () => {
      Cookies.remove("TRUCK_DOCTOR_ACCESSTOKEN");
      Cookies.remove("TRUCK_DOCTOR_REFRESHTOKEN");
      Cookies.remove("TRUCK_DOCTOR_PWUPDATED");
    },
  });
};

export const useFetchUnrestricLogin = (
  req: UnrestrickLoginQueryModel,
  options: UseQueryOptions,
) => {
  return useQuery({
    queryKey: authKeys.unrestric,
    queryFn: () => unrestricLoginAPI(req),
    enabled: false,
    onSuccess: options.onSuccess,
    onError: options.onError,
  });
};

export const useResetPassword = () => {
  return useMutation<unknown, APIError, ResetPasswordQueryModel>({
    mutationFn: (req) => resetPasswordAPI(req),
  });
};
