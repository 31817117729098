import React, { useState, useEffect } from "react";

import useToast from "hooks/useToast";
import { ToastErrorIcon, ToastSuccessIcon, ToastAlertIcon } from "assets";
import * as S from "./ToastItem.styled";

interface ToastItemPrpos {
  type: "success" | "warning" | "alert";
  id: string;
  content: string;
}

const TRANSITION_DURATION = 1000;
const TOAST_DURATION = 3000;

const ToastItem = ({ type, id, content }: ToastItemPrpos) => {
  const [isClosing, setIsClosing] = useState(false);
  const { removeToast } = useToast();

  useEffect(() => {
    const existTimeout = setTimeout(() => {
      setIsClosing(true);
    }, TOAST_DURATION);

    const expireToastTimeout = setTimeout(() => {
      removeToast(id);
    }, TOAST_DURATION + TRANSITION_DURATION);

    return () => {
      clearTimeout(existTimeout);
      clearTimeout(expireToastTimeout);
    };
  }, []);

  return (
    <S.ToastItem isClosing={isClosing}>
      <S.Item data-type={type}>
        {type === "success" ? (
          <ToastSuccessIcon />
        ) : type === "alert" ? (
          <ToastAlertIcon />
        ) : (
          <ToastErrorIcon />
        )}
        <p>{content}</p>
      </S.Item>
    </S.ToastItem>
  );
};

export default ToastItem;
