import React from "react";
import { useParams } from "react-router-dom";

import { PageLayout, Report, Comment, CommentForm } from "components";
import { useGetCommentDetail } from "services";
import {
  COMMENT_REPORT_DETAIL,
  COMMENT_REPORT_DETAIL_PAGE_LAYOUT,
} from "assets";
import * as S from "./CommentReportDetail.styled";

const CommentReportDetail = () => {
  const { id } = useParams();

  const { data } = useGetCommentDetail(id!);

  if (!data) return null;

  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={COMMENT_REPORT_DETAIL_PAGE_LAYOUT}
    >
      <Report data={data} contents={COMMENT_REPORT_DETAIL} />
      <Comment data={data} />
      <CommentForm data={data} />
    </PageLayout>
  );
};

export default CommentReportDetail;
