import styled from "@emotion/styled";
import { css } from "@emotion/react";
export const TabBtnWrapper = styled.ul`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 24px;
    margin-bottom: 33px;
    border-bottom: 1px solid ${theme.color.gray_30};
  `}
`;

export const TabBtn = styled.button`
  ${({ theme }) => css`
    padding: 8px 10px;
    font: ${theme.font.medium_16};
    color: ${theme.color.gray_50};

    &[aria-selected="true"] {
      border-bottom: 2px solid ${theme.color.black};
      padding-bottom: 6px;
      color: ${theme.color.black};
    }
    &[aria-selected="false"] {
      :hover:not([disabled]) {
        color: ${theme.color.gray_50};
      }
    }
  `}
`;
