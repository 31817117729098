import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  NotFound,
  Login,
  UnrestrictLogin,
  ResetPassword,
  Expired,
  Repairshop,
  RepairshopCreate,
  RepairshopDetail,
  Truck,
  TruckDetail,
  Owner,
  Support,
  Report,
  RepairshopEdit,
  ReviewReportDetail,
  UserReportDetail,
  BulletinReportDetail,
  CommentReportDetail,
  OwnerSupportDetail,
  RepairshopSupportDetail,
  UserNoticeSupportDetail,
  RepairshopNoticeSupportDetail,
  CreateNotice,
  EditNotice,
  Statistics,
} from "pages";
import { PublicRoute, AuthRoute, ErrorBoundary } from "components";
import { PATH } from "utils";

interface RouterProps {
  children: React.ReactNode;
}

export default function CustomRouter({ children }: RouterProps) {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        {children}
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route element={<PublicRoute />}>
            <Route path={PATH.root} element={<Login />} />
            <Route path={PATH.unrestrictLogin} element={<UnrestrictLogin />} />
            <Route path={PATH.resetPassword} element={<ResetPassword />} />
            <Route path={PATH.expired} element={<Expired />} />
          </Route>
          <Route element={<AuthRoute />}>
            <Route path={PATH.statistics}>
              <Route path="" element={<Statistics />} />
            </Route>
            <Route path={PATH.repairshop}>
              <Route path="" element={<Repairshop />} />
              <Route path="create" element={<RepairshopCreate />} />
              <Route path="edit/:id" element={<RepairshopEdit />} />
              <Route path=":id" element={<RepairshopDetail />} />
            </Route>
            <Route path={PATH.truck}>
              <Route path="" element={<Truck />} />
              <Route
                path=":id"
                element={
                  <Suspense>
                    <TruckDetail />
                  </Suspense>
                }
              />
            </Route>
            <Route path={PATH.owner}>
              <Route path="" element={<Owner />} />
            </Route>
            <Route path={PATH.report}>
              <Route path="" element={<Report />} />
              <Route
                path="review/:id"
                element={
                  <Suspense>
                    <ReviewReportDetail />
                  </Suspense>
                }
              />
              <Route
                path="user/:id"
                element={
                  <Suspense>
                    <UserReportDetail />
                  </Suspense>
                }
              />
              <Route
                path="bulletin/:id"
                element={
                  <Suspense>
                    <BulletinReportDetail />
                  </Suspense>
                }
              />
              <Route
                path="comment/:id"
                element={
                  <Suspense>
                    <CommentReportDetail />
                  </Suspense>
                }
              />
            </Route>
            <Route path={PATH.support}>
              <Route path="" element={<Support />} />
              <Route
                path="owner/:id"
                element={
                  <Suspense>
                    <OwnerSupportDetail />
                  </Suspense>
                }
              />
              <Route
                path="repairshop/:id"
                element={
                  <Suspense>
                    <RepairshopSupportDetail />
                  </Suspense>
                }
              />
              <Route
                path="userNotice/:id"
                element={
                  <Suspense>
                    <UserNoticeSupportDetail />
                  </Suspense>
                }
              />
              <Route
                path="repairshopNotice/:id"
                element={
                  <Suspense>
                    <RepairshopNoticeSupportDetail />
                  </Suspense>
                }
              />
              <Route path="userNotice/create" element={<CreateNotice />} />
              <Route path="userNotice/edit/:id" element={<EditNotice />} />
              <Route
                path="repairshopNotice/create"
                element={<CreateNotice />}
              />
              <Route
                path="repairshopNotice/edit/:id"
                element={<EditNotice />}
              />
            </Route>
          </Route>
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  );
}
