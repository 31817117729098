import React, { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { map } from "lodash-es";

import {
  CheckboxBtn,
  DataSection,
  GhostBtn,
  PinModal,
  UnPinModal,
  CheckTable,
  DeleteNoticeModal,
} from "components";
import { useGetNoticeSupports } from "services";
import { useFilterParams, useCheckTableWithCondition, useModal } from "hooks";
import { keys } from "utils";
import {
  NOTICE_SUPPORT_TABLE_HEADER,
  NOTICE_SUPPORT_TYPE,
  PinIcon,
} from "assets";
import type {
  NoticeSupportClientModel,
  GetNoticeSupportsQueryModel,
} from "types";
import * as S from "./NoticeTable.styled";

const NoticeTable = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tabQuery = searchParams.get("tab")?.slice(0, -6);

  const { handleModalOpen } = useModal();

  const { filters } = useFilterParams<GetNoticeSupportsQueryModel["query"]>(
    ["page", "title", "type"],
    {
      type: tabQuery as keyof typeof NOTICE_SUPPORT_TYPE,
    },
  );

  const { data, refetch, dataUpdatedAt } = useGetNoticeSupports({
    query: filters,
  });

  const {
    isChecked,
    isAllChecked,
    checkedIds,
    handleCheck,
    handleAllCheck,
    handleAllUnCheck,
  } = useCheckTableWithCondition<NoticeSupportClientModel>(data?.notices);

  const checkedNoticeList = useMemo(
    () =>
      data?.notices
        .map((notice) => checkedIds.includes(notice.noticeId) && notice)
        .filter((notice): notice is NoticeSupportClientModel =>
          Boolean(notice),
        ) ?? [],
    [checkedIds.length],
  );

  const handleGoToCreateNotice = () => navigate(`${tabQuery}Notice/create`);

  const activeBtnList = () => {
    return (
      <S.BtnWrapper>
        <GhostBtn
          variant="ghost"
          label="고정"
          handleClickBtn={handleModalOpen(
            <PinModal
              checkedNoticeList={checkedNoticeList}
              handleAllUnCheck={handleAllUnCheck}
            />,
          )}
        />
        <GhostBtn
          variant="ghost"
          label="고정 해제"
          handleClickBtn={handleModalOpen(
            <UnPinModal
              checkedNoticeList={checkedNoticeList}
              handleAllUnCheck={handleAllUnCheck}
            />,
          )}
        />
        <GhostBtn
          variant="ghost_red"
          label="삭제"
          handleClickBtn={handleModalOpen(
            <DeleteNoticeModal
              checkedNoticeList={checkedNoticeList}
              handleAllUnCheck={handleAllUnCheck}
            />,
          )}
        />
      </S.BtnWrapper>
    );
  };

  return (
    <DataSection
      totalData={data?.pageInfo.totalData!}
      refetch={refetch}
      dataUpdatedAt={dataUpdatedAt}
      activeBtns={
        <DataSection.ActiveBtn
          variant="primary"
          label="공지 추가"
          handleClickActiveBtn={handleGoToCreateNotice}
        />
      }
    >
      <CheckTable
        css={S.checkTable}
        title="공지사항"
        toolBtns={activeBtnList()}
        columnTable={NOTICE_SUPPORT_TABLE_HEADER}
        selectedCount={checkedIds?.length}
        isAllChecked={isAllChecked()}
        handleAllCheck={handleAllCheck}
        handleAllUnCheck={handleAllUnCheck}
      >
        {map(data?.notices, (rowData) => (
          <DataSection.CheckTable.SelectRowMovePage
            key={rowData.noticeId}
            id={rowData.noticeId}
            path={`/support/${tabQuery}Notice/${rowData.noticeId}`}
          >
            <DataSection.CheckTable.CheckTd>
              <CheckboxBtn
                isChecked={isChecked(rowData.noticeId)}
                handleCheck={handleCheck(rowData.noticeId)}
              />
            </DataSection.CheckTable.CheckTd>
            {map(keys(NOTICE_SUPPORT_TABLE_HEADER), (key) => (
              <DataSection.CheckTable.Td key={key}>
                {key === "idx" && rowData.fixed === "y" ? (
                  <PinIcon />
                ) : (
                  rowData[key]
                )}
              </DataSection.CheckTable.Td>
            ))}
          </DataSection.CheckTable.SelectRowMovePage>
        ))}
      </CheckTable>
    </DataSection>
  );
};

export default NoticeTable;
