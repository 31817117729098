import React from "react";

import { ConfirmModal } from "components/common";

interface TruckVinCheckModalProps {
  activeFn: () => void;
}

const TruckVinCheckModal = React.forwardRef(
  (
    { activeFn }: TruckVinCheckModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        title="이미 등록된 차대번호"
        activeBtnName="기존 차량 확인"
        btnType="active"
        desc={
          "이미 해당 차대번호로 등록된 차량이 있습니다.\n다른 번호로 등록하시거나 기존 차량을 삭제 후 재 등록해주세요"
        }
        activeFn={activeFn}
      />
    );
  },
);

TruckVinCheckModal.displayName = "TruckVinCheckModal";

export default TruckVinCheckModal;
