import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const CalendarInput = styled.div`
  position: relative;
  width: 438px;
`;

export const Input = styled.input`
  ${({ theme }) => css`
    width: 100%;
    height: 44px;
    border: 0;
    padding: 0 44px 0 20px;
    font: ${theme.font.regular_14};
    color: ${theme.color.black};
    background-color: ${theme.color.white};

    &::placeholder {
      color: ${theme.color.gray_50};
    }
  `}
`;

export const calendarDialogBtn = css`
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 20px;
`;
