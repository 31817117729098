import { pick, omit, has, get, set, filter, join, includes } from "lodash-es";

import type { FilterCondition, StringKeyOf } from "types";
import { formatPeriodLocalToUtc } from "./formatter";

export const getFilterQuery = <T>(
  searchParams: URL["searchParams"],
  init?: T,
) => {
  return Object.keys(Object.fromEntries(searchParams)).reduce((acc, query) => {
    const value = searchParams.getAll(query);

    return {
      ...acc,
      [query]: toStringByLength(value, 1),
    };
  }, getDefaultQuery(init));
};

const getFilterDateQueryKey = (
  searchParams: URL["searchParams"],
  query: string,
) => {
  if (query !== "dateStart" && query !== "dateEnd") return;

  const dateType = searchParams.get("dateType");

  if (dateType === "pickupDate") {
    if (query === "dateStart") {
      return "pickupStart";
    } else {
      return "pickupEnd";
    }
  }

  if (dateType === "updatedDate") {
    if (query === "dateStart") {
      return "updatedStart";
    } else {
      return "updatedEnd";
    }
  }

  if (dateType === "createdDate") {
    if (query === "dateStart") {
      return "createdStart";
    } else {
      return "createdEnd";
    }
  }
};

export const getFilterParams = <T>(
  searchParams: URL["searchParams"],
  params: StringKeyOf<T>[],
  init?: Partial<T>,
) => {
  return Object.keys(Object.fromEntries(searchParams)).reduce((acc, query) => {
    const dateFilterQueryKey = getFilterDateQueryKey(searchParams, query);

    if (!includes(params, query)) return acc;

    const queryValue = searchParams.getAll(query);
    let value = queryValue.join(",");

    if (query === "dateStart" || query === "dateEnd") {
      value = formatPeriodLocalToUtc(query, value) ?? "";
    }

    const newQuery = dateFilterQueryKey ?? query;

    return {
      ...acc,
      [newQuery]: value,
    };
  }, getDefaultQuery(init) as unknown as T);
};

const getDefaultQuery = <T>(init?: T) => ({
  page: "1",
  perPage: "20",
  ...(init ?? {}),
});

const toStringByLength = (
  array: string[],
  length: number,
): string | string[] => {
  if (array.length === length) {
    return join(array);
  }
  return array;
};

export const deleteFilterQuery = <T extends Object>(
  query: T,
  target: { key: string; value: string },
) => {
  switch (target.key) {
    case "date":
      return omit(query, ["dateStart", "dateEnd"]);

    case "createdDate":
    case "updatedDate":
    case "pickupDate":
      return omit(query, ["dateStart", "dateEnd", "dateType"]);
  }

  const queryValue = get(query, target.key);
  if (typeof queryValue === "object") {
    const filteredValue = filter(queryValue, (v) => v !== target.value);
    return set(query, target.key, filteredValue);
  }

  return omit(query, [target.key, "driverId"]);
};

export const getFilterConditionKeys = (
  conditions: FilterCondition[][],
  filter: (arg: FilterCondition) => boolean,
) =>
  conditions
    .flat()
    .filter(filter)
    .map((condition) => condition.key);

export const pickQuery = <T extends object>(query: T, pickList: string[]) =>
  pick(query, pickList);

export const pickQueryWithParseDate = <T extends Object>(
  query: T,
  pickList: string[],
) => {
  const newQuery = pickQuery(query, pickList);
  const date = getDateLabel(query);

  return {
    ...newQuery,
    ...(has(query, "dateStart") &&
      (has(query, "dateType")
        ? { [`${get(query, "dateType")}`]: date }
        : { date })),
  };
};

const getDateLabel = <T extends Object>(query: T) =>
  `${get(query, "dateStart")} ~ ${get(query, "dateEnd")}`;

export const getQueryDate = (searchParams: URL["searchParams"]) => {
  return searchParams.get("dateStart") && searchParams.get("dateEnd")
    ? [
        searchParams.get("dateStart") as string,
        searchParams.get("dateEnd") as string,
      ]
    : [];
};

export const getAllQuery = (searchParams: URL["searchParams"]) => {
  return Object.keys(Object.fromEntries(searchParams)).reduce(
    (acc, query) => ({
      ...acc,
      [query]: searchParams.getAll(query),
    }),
    {} as { [key: string]: string[] },
  );
};

export const makeFilterQuery = (
  searchParams: URL["searchParams"],
  queryName: string,
  key: string,
) => {
  const query = getAllQuery(searchParams);
  const hasItem = (query[queryName] ?? []).includes(key);

  return {
    ...query,
    page: ["1"],
    [queryName]: hasItem
      ? searchParams.getAll(queryName).filter((item) => item !== key)
      : [...searchParams.getAll(queryName), key],
  };
};

export const isEligible = (query: string[], key: string): boolean => {
  const isMaxItem = query.length > 4;

  return !isMaxItem || (isMaxItem && query.includes(key));
};

export const makeRadioBtnFilterQuery = (
  searchParams: URL["searchParams"],
  queryName: string,
  key: string,
) => {
  const query = getAllQuery(searchParams);

  return {
    ...query,
    page: ["1"],
    [queryName]: [key],
  };
};

export const getFilterKeyNames = (filterCondition: FilterCondition[][]) =>
  filterCondition
    .map((item) => item.map((item) => ({ searchLabel: item.searchLabel })))
    .flat();

export const selectFilterName = (
  filterKeyValues: { searchLabel: { key: string; name: string }[] }[],
  selectLabel: string,
) =>
  filterKeyValues
    .map(
      (filterKeyValue) =>
        filterKeyValue.searchLabel.filter(
          (item) => item.key === selectLabel,
        )[0],
    )
    .filter((item) => !!item)[0].name;
