import React from "react";
import dayjs from "dayjs";

import { ArrowIcon } from "assets/icon";
import { MONTH, WEEKS } from "assets/static";
import * as S from "./Calendar.styled";
import SingleDates from "./dates/SingleDates";
import FreeDates from "./dates/FreeDates";
import useCalendar from "./hooks/useCalendar";

interface CalendarProps {
  className?: string;
  isDialogOpen?: boolean;
  hasTime?: boolean;
  type?: Readonly<"date" | "free">;
  as?: React.ElementType & string;
  selectedDate: string[];
  handleChangeDate: (date: dayjs.Dayjs[] | []) => void;
  handleDialogClose?: () => void;
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
}

const Calendar = React.forwardRef(
  (
    {
      className,
      isDialogOpen = false,
      hasTime = false,
      as = "div",
      type = "date",
      selectedDate,
      handleChangeDate,
      handleDialogClose,
      handleFocusCondition,
      handleBlurCondition,
    }: CalendarProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { calendar, datePicker } = useCalendar(
      isDialogOpen,
      hasTime,
      type,
      selectedDate,
      handleChangeDate,
      handleDialogClose,
      handleFocusCondition,
      handleBlurCondition,
    );

    return (
      <S.Root
        ref={ref}
        className={className}
        aria-modal="true"
        open={isDialogOpen}
        as={as}
      >
        <S.Header>
          <S.MonthYear
            type="button"
            aria-controls="month-list"
            aria-expanded={calendar.isOpenMonthDialog}
            aria-label="open month list"
            onClick={calendar.handleMonthDialog}
          >
            <S.MonthYearContent>
              {!calendar.isOpenMonthDialog && (
                <span>{datePicker.monthYear.value.format("M")}월</span>
              )}
              <span>{datePicker.monthYear.value.format("YYYY")}</span>
            </S.MonthYearContent>
            <S.OpenMonthWrapper>
              <ArrowIcon />
            </S.OpenMonthWrapper>
          </S.MonthYear>
          <S.MoveBtnWrapper>
            <S.MoveBtn
              type="button"
              aria-label="move previous month"
              onClick={
                calendar.isOpenMonthDialog
                  ? datePicker.handleChangePrevYear
                  : datePicker.handleChangePrevMonth
              }
            >
              <S.ArrowLeftIcon />
            </S.MoveBtn>
            <S.MoveBtn
              type="button"
              aria-label="move next month"
              onClick={
                calendar.isOpenMonthDialog
                  ? datePicker.handleChangeNextYear
                  : datePicker.handleChangeNextMonth
              }
            >
              <S.ArrowRightIcon />
            </S.MoveBtn>
          </S.MoveBtnWrapper>
        </S.Header>
        {calendar.isOpenMonthDialog ? (
          <S.MonthWrapper id="month-list">
            {Object.entries(MONTH).map(([label, value]) => (
              <li key={value}>
                <S.MonthBtn
                  type="button"
                  aria-current={
                    value === +datePicker.monthYear.currentMonth &&
                    datePicker.monthYear.year ===
                      datePicker.monthYear.currentYear
                  }
                  aria-selected={+datePicker.monthYear.month === value}
                  onClick={calendar.handleClickMonth(value)}
                >
                  {label}
                </S.MonthBtn>
              </li>
            ))}
          </S.MonthWrapper>
        ) : (
          <>
            <S.WeekRow>
              {WEEKS.map((week, i) => (
                <li key={`${week}-${i}`}>{week}</li>
              ))}
            </S.WeekRow>
            <S.DateRow
              className={className}
              data-status={type}
              onMouseLeave={calendar.handleMouseLeave}
            >
              {type === "date" && (
                <SingleDates datePicker={datePicker} calendar={calendar} />
              )}
              {type === "free" && (
                <FreeDates datePicker={datePicker} calendar={calendar} />
              )}
            </S.DateRow>
          </>
        )}
        {!calendar.isOpenMonthDialog && (
          <S.BtnWrapper>
            <S.ResetBtn onClick={calendar.handleReset}>초기화</S.ResetBtn>
            <S.ApplyBtn onClick={calendar.handleApply}>적용</S.ApplyBtn>
          </S.BtnWrapper>
        )}
      </S.Root>
    );
  },
);

Calendar.displayName = "Calendar";

export default Calendar;
