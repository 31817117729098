import { useQuery } from "@tanstack/react-query";

import { getStaticListAPI, getStaticsExcelDownloadAPI } from "api";
import type { GetStaticsExcelQueryModel, GetStaticsQueryModel } from "types";

const staticKeys = {
  all: ["reservation"],
  lists: () => [...staticKeys.all, "list"],
  list: (filters: GetStaticsQueryModel) => [...staticKeys.lists(), { filters }],
  excels: () => [...staticKeys.all, "excel"],
  excel: (filters: GetStaticsExcelQueryModel) => [
    ...staticKeys.excels(),
    { filters },
  ],
};

export const useGetStatics = (req: GetStaticsQueryModel) => {
  return useQuery({
    queryKey: staticKeys.list(req),
    queryFn: () => getStaticListAPI(req),
    select: (res) => ({
      pageInfo: res.pageInfo,
      reservations: res.reservations?.map((reservation) => ({
        repairshopId: reservation.repairshopId,
        repairshopName: reservation.repairshopName,
        total: reservation.total,
        appPlanned: reservation.inAppReserved.planned,
        appAccepted: reservation.inAppReserved.accepted,
        appCanceled: reservation.inAppReserved.canceled,
        appRepairing: reservation.inAppReserved.repairing,
        appCompleted: reservation.inAppReserved.completed,
        appTotal: reservation.inAppReserved.total,
        selfAccepted: reservation.selfReserved.accepted,
        selfCanceled: reservation.selfReserved.canceled,
        selfRepairing: reservation.selfReserved.repairing,
        selfCompleted: reservation.selfReserved.completed,
        selfTotal: reservation.selfReserved.total,
      })),
    }),
  });
};

export const useGetStaticsExcelDownload = (
  req: GetStaticsExcelQueryModel,
  options: any,
) => {
  return useQuery({
    ...options,
    queryKey: ["excel"],
    queryFn: () => getStaticsExcelDownloadAPI(req),
    enabled: false,
  });
};
