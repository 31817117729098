import { useQuery } from "@tanstack/react-query";

import { getReviewReportListAPI, getReviewReportDetailAPI } from "api/index";
import { checkDeletedUser, utcTimeToLocalTime } from "utils/index";
import type { GetReviewReportsQueryModel } from "types";

const reviewKeys = {
  all: ["review"],
  lists: () => [...reviewKeys.all, "list"],
  list: (filters: GetReviewReportsQueryModel) => [
    ...reviewKeys.lists(),
    { filters },
  ],
  details: () => [...reviewKeys.all, "detail"],
  detail: (id: string) => [...reviewKeys.details(), id],
};

export const useGetReviews = (req: GetReviewReportsQueryModel) => {
  return useQuery({
    queryKey: reviewKeys.list(req),
    queryFn: () => getReviewReportListAPI(req),
    select: (res) => ({
      pageInfo: res.pageInfo,
      reports: res.reports.map((review) => ({
        checked: review.checked ? ("확인" as const) : ("미확인" as const),
        created: utcTimeToLocalTime(review.created),
        reason: review.reason,
        repairshopId: review.repairShopId,
        repairshopName: review.repairShopName,
        detailId: review.reservationId,
        userId: review.userId,
        userNickname: checkDeletedUser(review.userNickname),
      })),
    }),
    suspense: true,
  });
};

export const useGetReviewDetail = (req: string) => {
  return useQuery({
    queryKey: reviewKeys.detail(req),
    queryFn: () => getReviewReportDetailAPI(req),
    select: (res) => ({
      content: res.content,
      created: utcTimeToLocalTime(res.created),
      planned: utcTimeToLocalTime(res.planned),
      rating: res.rating,
      reason: res.reason,
      repairshopId: res.repairShopId,
      repairshopName: res.repairShopName,
      reservationNo: res.reservationNo,
      userId: res.userId,
      userNickname: checkDeletedUser(res.userNickname),
      repairShopStatus: res.repairShopStatus,
    }),
    suspense: true,
  });
};
