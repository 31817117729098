import React from "react";

import useTab from "./hooks/useTab";
import * as S from "./Tab.styled";

interface TabProps {
  tabList: {
    [key: string]: {
      tabName: string;
      component: React.ReactNode;
      suspense?: React.ReactNode;
    };
  };
}

const Tab = ({ tabList }: TabProps) => {
  const { selectedTab, handleChangeTab } = useTab(tabList);

  return (
    <>
      <S.TabBtnWrapper role="tablist">
        {Object.entries(tabList).map(([key, { tabName }], i) => (
          <li key={i} role="none">
            <S.TabBtn
              role="tab"
              type="button"
              aria-controls={tabName}
              aria-selected={selectedTab === key}
              onClick={handleChangeTab(key)}
            >
              {tabName}
            </S.TabBtn>
          </li>
        ))}
      </S.TabBtnWrapper>
      <div id={selectedTab} role="tabpanel" aria-labelledby={selectedTab}>
        {tabList[selectedTab].component}
      </div>
    </>
  );
};

export default Tab;
