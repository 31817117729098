import React from "react";

import { QueryPagination } from "components";
import { useGetUsers } from "services";
import { useFilterParams } from "hooks";
import type { GetUserReportsQueryModel } from "types";

const UserPagination = () => {
  const { filters } = useFilterParams<GetUserReportsQueryModel["query"]>([
    "page",
    "category",
    "status",
  ]);

  const { data } = useGetUsers({
    query: filters,
  });

  return <QueryPagination pageInfo={data?.pageInfo!} />;
};

export default UserPagination;
