import { css } from "@emotion/react";

export const contentWrapper = css`
  margin-bottom: 40px;
`;

export const division = css`
  margin-bottom: 40px;
`;

export const formRadioBtn = css`
  column-gap: 40px;
`;
