import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getRepairshopSupportListAPI,
  getRepairshopSupportDetailAPI,
  createAnswerRepairshopAPI,
} from "api";
import { formatPhoneNum, utcTimeToLocalTime } from "utils";
import type {
  APIError,
  CreateSupportQueryModel,
  GetRepairshopSupportsQueryModel,
} from "types";

const repairshopSupportKeys = {
  all: ["repairshopSupport"],
  lists: () => [...repairshopSupportKeys.all, "list"],
  list: (filters: GetRepairshopSupportsQueryModel) => [
    ...repairshopSupportKeys.lists(),
    { filters },
  ],
  details: () => [...repairshopSupportKeys.all, "detail"],
  detail: (id: string) => [...repairshopSupportKeys.details(), id],
};

export const useGetRepairshopSupports = (
  req: GetRepairshopSupportsQueryModel,
) => {
  return useQuery({
    queryKey: repairshopSupportKeys.list(req),
    queryFn: () => getRepairshopSupportListAPI(req),
    select: (res) => ({
      pageInfo: res.pageInfo,
      helps: res.helps.map((help) => ({
        detailId: help.helpId,
        category: help.category,
        name: help.name,
        repairShopId: help.repairShopId,
        repairshopName: help.repairShopName,
        title: help.title,
        created: utcTimeToLocalTime(help.created),
        askStatus: help.status,
      })),
    }),
    suspense: true,
    keepPreviousData: true,
  });
};

export const useGetRepairshopSupportDetail = (req: string) => {
  return useQuery({
    queryKey: repairshopSupportKeys.detail(req),
    queryFn: () => getRepairshopSupportDetailAPI(req),
    select: (res) => ({
      imgUrls: res.imgUrls,
      answerContent: res.answerContent,
      answerCreated: utcTimeToLocalTime(res.answerCreated),
      answerTitle: res.answerTitle,
      category: res.category,
      content: res.content,
      created: utcTimeToLocalTime(res.created),
      email: res.email,
      helpId: res.helpId,
      name: res.name,
      phone: formatPhoneNum(res.phone),
      repairShopId: res.repairShopId,
      repairShopName: res.repairShopName,
      status: res.status,
      title: res.title,
      repairShopStatus: res.repairShopStatus,
    }),
    suspense: true,
  });
};

export const useCreateAnswerRepairshopSupport = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, CreateSupportQueryModel>({
    mutationFn: (req) => createAnswerRepairshopAPI(req),
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(repairshopSupportKeys.detail(req.helpId));
    },
  });
};
