export const HOOKFORM_ERR_OBJ = {
  REQUIRED: {
    type: "required",
    message: "필수 입력 정보입니다",
  },
  EMAIL_FORMAT: {
    type: "format",
    message: "이메일 형식이 올바르지 않습니다",
  },
  NOTSAME_PASSWORD_FORMAT: {
    type: "format",
    message: "비밀번호가 일치하지 않습니다",
  },
  PHONE_FORMAT: {
    type: "format",
    message: "전화번호 형식이 아닙니다",
  },
  PASSWORD_PATTERN: {
    type: "pattern",
    message: "비밀번호 조건에 맞지 않습니다",
  },
  MODEL_YEAR_PATTERN: {
    type: "pattern",
    messgae: "모델연도는 4자리 숫자입니다",
  },
  VIN_PATTERN: {
    type: "pattern",
    message: "차대번호는 17자리 입니다",
  },
  EXIST_REPAIRSHOP_EMAIL: {
    type: "warning",
    content: "이미 존재하는 이메일 주소입니다.",
  },
} as const;
