import { css, Theme } from "@emotion/react";

export const pageLayout = css`
  & > h1 {
    margin-bottom: 45px;
  }
`;

export const division = css`
  margin-bottom: 40px;
`;

export const registrationLabelContent = (theme: Theme) => css`
  margin-bottom: 41px;

  & > div:nth-of-type(1),
  & > div:nth-of-type(4) {
    margin-bottom: 14px;
  }

  & > div:nth-of-type(2),
  & > div:nth-of-type(5) {
    border-top: 1px solid ${theme.color.gray_20};
  }
`;
