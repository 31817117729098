import React from "react";

import { QueryPagination } from "components";
import { useGetBulletins } from "services";
import { useFilterParams } from "hooks";
import type { GetBulletinsQueryModel } from "types";

const BulletinPagenation = () => {
  const { filters } = useFilterParams<GetBulletinsQueryModel["query"]>([
    "page",
    "category",
    "status",
  ]);

  const { data } = useGetBulletins({
    query: filters,
  });

  return <QueryPagination pageInfo={data?.pageInfo!} />;
};

export default BulletinPagenation;
