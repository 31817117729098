import React from "react";

import { LoadingSpinner } from "components";
import * as S from "./Button.styled";

export type ButtonVariant = "primary" | "secondary" | "third" | "error";

interface ButtonProps {
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset";
  variant: ButtonVariant;
  label: string;
  icon?: React.ReactNode;
  tabIndex?: number;
  handleClickBtn?: (e: React.MouseEvent) => void;
}

const Button = ({
  className,
  isDisabled,
  isLoading = false,
  icon,
  type = "button",
  variant,
  label,
  tabIndex,
  handleClickBtn,
}: ButtonProps) => {
  return (
    <S.Button
      className={className}
      isLoading={isLoading}
      disabled={isDisabled}
      type={type}
      variant={variant}
      tabIndex={tabIndex}
      onClick={handleClickBtn}
    >
      {isLoading ? (
        <>
          <LoadingSpinner css={S.spinner} />
          <S.LoadingContents>
            {icon && icon}
            {label}
          </S.LoadingContents>
        </>
      ) : (
        <>
          {icon && icon}
          {label}
        </>
      )}
    </S.Button>
  );
};

export default Button;
