export const PATH = {
  root: "/",
  unrestrictLogin: "/unrestrictLogin",
  resetPassword: "/resetPassword",
  expired: "/expired",
  notFound: "/notFound",
  statistics: "/statistics",
  repairshop: "/repairshop",
  truck: "/truck",
  owner: "/owner",
  report: "/report",
  reviewReport: "/report?tab=review",
  userReport: "/report?tab=user",
  bulletinReport: "/report?tab=bulletin",
  commentReport: "/report?tab=comment",
  support: "/support",
  ownerSupport: "/support?tab=owner",
  repairshopSupport: "/support?tab=repairshop",
  noticeUserSupport: "/support?tab=userNotice",
  noticeRepairshopSupport: "/support?tab=repairshopNotice",
  askSupport: "/support/ask",
  eventSupport: "/support/event",
};
