import React from "react";
import { useNavigate } from "react-router-dom";

import type { Histories } from "types";
import * as S from "./History.styled";

interface HistoryProps {
  histories: Histories;
}

const History = ({ histories }: HistoryProps) => {
  const navigate = useNavigate();

  const handleClickHistory = (path: string) => () => {
    if (path) {
      navigate(path);
    }
  };

  return (
    <S.History>
      {histories.map(({ content, path }, idx: number) => (
        <S.HistoryItem key={idx}>
          <S.HistoryBtn
            type="button"
            onClick={handleClickHistory(path as string)}
          >
            {content}
          </S.HistoryBtn>
        </S.HistoryItem>
      ))}
    </S.History>
  );
};

export default History;
