import React from "react";
import { v4 as uuidv4 } from "uuid";

import { CheckboxIcon, DropdownArrowIcon, ErrIcon } from "assets/icon";
import type { DropdownOptionType } from "types";
import useDropdown from "./hooks/useDropdown";
import * as S from "./Dropdown.styled";

interface DropdownProps {
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
  optionArr: DropdownOptionType[];
  placeholder?: string;
  selectedOption: DropdownOptionType;
  type?: "default" | "address";
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  handleSelect?: (value: string) => void;
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
}

const Dropdown = ({
  className,
  disabled,
  hasError,
  optionArr,
  placeholder = "",
  selectedOption,
  type = "default",
  Icon = DropdownArrowIcon,
  handleSelect,
  handleFocusCondition,
  handleBlurCondition,
}: DropdownProps) => {
  const { dropdownRef, optionsRef, isOpen, handleOpener, handleClickOption } =
    useDropdown(handleSelect, handleFocusCondition, handleBlurCondition);
  const id = uuidv4();

  return (
    <S.Dropdown ref={dropdownRef} className={className}>
      <S.DropdownBtn
        aria-controls={id}
        aria-expanded={isOpen}
        hasError={hasError}
        disabled={disabled}
        type="button"
        onClick={handleOpener}
      >
        {selectedOption?.option ? (
          <S.SelectedValue>{selectedOption.option}</S.SelectedValue>
        ) : (
          <S.Placeholder>{placeholder}</S.Placeholder>
        )}
        <Icon />
      </S.DropdownBtn>
      <S.OptionWrapper
        ref={optionsRef}
        aria-hidden={!isOpen}
        id={id}
        isOpen={isOpen}
      >
        {optionArr.length === 0 ? (
          <S.NoOptionData>
            <ErrIcon />
            <S.NoOptionDataMsg>No history</S.NoOptionDataMsg>
          </S.NoOptionData>
        ) : (
          optionArr.map(({ key, option }, i) => (
            <S.Option key={i}>
              <S.OptionBtn
                data-selected={selectedOption?.key === key}
                status={type}
                type="button"
                onClick={handleClickOption(key)}
              >
                {option}
                <CheckboxIcon css={S.checkboxIcon} />
              </S.OptionBtn>
            </S.Option>
          ))
        )}
      </S.OptionWrapper>
    </S.Dropdown>
  );
};

export default Dropdown;
