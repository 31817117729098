import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const InputWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 488px;
    min-height: 40px;
    margin-bottom: 12px;

    & > div > input {
      margin-bottom: 8px;
    }

    & > span {
      position: absolute;
      top: 10px;
      right: 13px;
      color: ${theme.color.gray_50};
    }
  `}
`;

export const labelContent = css`
  margin-bottom: 40px;

  & > h3 {
    margin-bottom: 2px;
  }

  & > div > div {
    grid-template-columns: 160px 1fr;
  }

  dt {
    padding: 11px 0;
  }

  dd {
    padding: 10px 0;
  }

  & > div:last-of-type {
    dd {
      padding: 0;
    }
  }
`;

export const shortContent = css`
  margin-bottom: 12px;

  & > dt {
    align-items: flex-start;
    min-height: 40px;
  }

  & > dd {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const content = css`
  & > dt {
    align-items: flex-start;
    padding: 10px 0 20px 0;
  }

  & > dd {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const division = css`
  margin-bottom: 40px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const errorMsg = (theme: Theme) => css`
  padding-top: 8px;
  color: ${theme.color.red_20};
`;

export const formRadioBtn = css`
  column-gap: 20px;
`;
