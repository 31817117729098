import React from "react";

import { CommentIcon } from "assets";
import type { Comment } from "types";
import * as S from "./CommentContent.styled";

interface CommentContentProps {
  commentData: Comment[];
  commentId: string;
}

const CommentContent = ({ commentData, commentId }: CommentContentProps) => {
  return (
    <S.CommentContent>
      {commentData.map((comment, i) => {
        if (comment.mentionNickname) {
          return (
            <S.CommentWrapper key={i}>
              <CommentIcon />
              <S.Comment isReport={commentId === comment.commentId}>
                {comment.nickname}: @{comment.mentionNickname} &nbsp;
                {comment.content}
              </S.Comment>
            </S.CommentWrapper>
          );
        } else {
          return (
            <S.Comment key={i} isReport={commentId === comment.commentId}>
              {!!comment.parentId && <CommentIcon />}
              {comment.nickname}: {comment.content}
            </S.Comment>
          );
        }
      })}
    </S.CommentContent>
  );
};

export default CommentContent;
