import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useCreateNoticeSupport, useEditNoticeSupport } from "services";
import { useToast } from "hooks";
import { TOAST_MSG } from "assets";
import type { CreateNoticeQueryModel } from "types";

type FormData = { title: string; content: string };

const useNoticeForm = (data?: FormData) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const {
    formState: { errors },
    control,
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<CreateNoticeQueryModel>({
    mode: "all",
    defaultValues: {
      title: data ? data.title : "",
      content: data ? data.content : "",
    },
  });

  const { mutate: createNotice } = useCreateNoticeSupport();
  const { mutate: editNotice } = useEditNoticeSupport();

  const { addToast } = useToast();

  const handleIsEmpty = (count: number, content?: string) => {
    if (count === 0 && content === "<p><br></p>") {
      setError("content", {
        type: "required",
        message: "",
      });
    }
  };

  const handleClearError = (data: string) => {
    const regex = /(<([^>]+)>)/gi;
    const newData = data;
    const result = newData.replace(regex, (match, tag) => {
      if (tag.startsWith("<img")) {
        return tag;
      }
      return "";
    });

    if (result.length !== 0) {
      clearErrors("content");
    }
  };

  const handleChangeForm = (e: React.ChangeEvent) => {
    const { name, value, maxLength } = e.target as
      | HTMLInputElement
      | HTMLTextAreaElement;

    switch (name) {
      case "title": {
        if (value.length > maxLength) {
          setValue(name, value.slice(0, maxLength));
        }
        break;
      }
    }
  };

  const handleCreate = (data: FormData) => {
    const noticeType = pathname.includes("userNotice") ? "user" : "repairshop";

    createNotice(
      {
        type: noticeType,
        title: data.title,
        content: data.content,
      },
      {
        onSuccess: ({ data }) => {
          addToast(TOAST_MSG.SUCCESS.NOTICE_CREATE);
          navigate(`/support/${noticeType}Notice/${data.noticeId}`);
        },
      },
    );
  };

  const handleEdit = (data: FormData) => {
    const noticeType = pathname.includes("userNotice") ? "user" : "repairshop";

    editNotice(
      {
        type: noticeType,
        title: data.title,
        content: data.content,
        id: id!,
      },
      {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.NOTICE_EDIT);
          navigate(`/support/userNotice/${id}`);
        },
      },
    );
  };

  const handleError = (err: any) => console.error(err);

  const isCreatePage = pathname.includes("create");
  const handleClickSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    isCreatePage
      ? handleSubmit(handleCreate, handleError)()
      : handleSubmit(handleEdit, handleError)();
  };

  const handleNavigateToList = () => navigate(``);
  const handleClickCancel = () =>
    isCreatePage ? handleNavigateToList() : navigate(``);

  return {
    errors,
    control,
    register,
    watch,
    setValue,
    handleIsEmpty,
    handleClearError,
    handleChangeForm,
    handleClickSubmit,
    handleClickCancel,
  };
};

export default useNoticeForm;
