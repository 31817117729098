import styled from "@emotion/styled";
import { css } from "@emotion/react";
export const ImgWrapper = styled.div`
  display: flex;
  column-gap: 13px;
`;

export const ImgLink = styled.a`
  ${({ theme }) => css`
    width: fit-content;
    font: ${theme.font.medium_14};
    color: ${theme.color.blue_10};
    text-decoration: underline;
  `}
`;
