import React from "react";

import { QueryPagination } from "components";
import { useGetReviews } from "services";
import { useFilterParams } from "hooks";
import type { GetReviewReportsQueryModel } from "types";

const ReviewPagination = () => {
  const { filters } = useFilterParams<GetReviewReportsQueryModel["query"]>([
    "page",
    "category",
    "isChecked",
  ]);

  const { data } = useGetReviews({
    query: filters,
  });

  return <QueryPagination pageInfo={data?.pageInfo!} />;
};

export default ReviewPagination;
