import { useState, useCallback, useEffect } from "react";
import dayjs from "dayjs";

import useDatePicker from "./useDatePicker";
import useTimePicker from "./useTimePicker";

const useCalendar = (
  isDialogOpen: boolean,
  hasTime: boolean,
  type: Readonly<"date" | "free">,
  selectedDate: string[],
  handleChange: (date: dayjs.Dayjs[] | []) => void,
  handleDialogClose?: () => void,
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void,
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void,
) => {
  const {
    monthYear,
    handleChangeDate,
    handleChangeMonth,
    handleChangePrevMonth,
    handleChangeNextMonth,
    handleChangePrevYear,
    handleChangeNextYear,
    handleResetMonthYear,
  } = useDatePicker(selectedDate, handleChange);

  const {
    time,
    timeErr,
    getTimeAppliedDate,
    applyTime,
    handleChangeTime,
    handleBlurTime,
    handleResetTime,
    handleResetTimeErr,
  } = useTimePicker(selectedDate[0], handleChange);

  const initSelectedDate = selectedDate.map((date) =>
    dayjs(date, "YYYY/MM/DD"),
  );
  const [isOpenMonthDialog, setIsOpenMonthDialog] = useState(false);
  const [hoveredDate, setHoveredDate] = useState<dayjs.Dayjs | string>("");
  const [currentDate, setCurrentDate] = useState<dayjs.Dayjs[] | []>(
    initSelectedDate,
  );
  const isDisabledApplyBtn =
    (type === "free" && currentDate.length === 0) || !!timeErr;

  const handleMonthDialog = () => {
    setIsOpenMonthDialog(!isOpenMonthDialog);
  };

  const changeHoveredDate = useCallback(
    (date: dayjs.Dayjs | string) => {
      setHoveredDate(date);
    },
    [hoveredDate],
  );

  const handleClickMonth = (month: number) => () => {
    handleChangeMonth(month);
    setIsOpenMonthDialog(false);
  };

  const handleClickDate = (date: dayjs.Dayjs[]) => () => {
    if (type === "free") {
      date.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));
    }
    setCurrentDate(date);
  };

  const handleReset = () => {
    handleResetMonthYear();
    setCurrentDate([]);

    if (hasTime) {
      handleResetTime();
      handleResetTimeErr();
    }
  };

  const handleApply = () => {
    if (hasTime) {
      applyTime(currentDate[0], time);
      const dateTime = [getTimeAppliedDate(dayjs(currentDate[0]), time)];
      handleChangeDate(dateTime);
    } else {
      handleChangeDate(currentDate);
    }

    typeof handleDialogClose === "function" && !timeErr && handleDialogClose();
  };

  const handleMouseLeave = useCallback(() => {
    hoveredDate && changeHoveredDate("");
  }, [hoveredDate]);

  useEffect(() => {
    if (isDialogOpen) {
      if (timeErr) {
        handleResetTime();
        handleResetTimeErr();
      }

      setCurrentDate(initSelectedDate);
      handleFocusCondition && handleFocusCondition();
    } else {
      handleBlurCondition && handleBlurCondition();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    setCurrentDate(initSelectedDate);
  }, [selectedDate]);

  return {
    calendar: {
      isOpenMonthDialog,
      isDisabledApplyBtn,
      currentDate,
      hoveredDate,
      changeHoveredDate,
      handleMonthDialog,
      handleClickDate,
      handleClickMonth,
      handleMouseLeave,
      handleReset,
      handleApply,
    },
    datePicker: {
      monthYear,
      handleChangePrevYear,
      handleChangeNextYear,
      handleChangePrevMonth,
      handleChangeNextMonth,
    },
    timePicker: {
      time,
      timeErr,
      handleChangeTime,
      handleBlurTime,
    },
  };
};

export default useCalendar;
