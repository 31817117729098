import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";

import { PrivateLayout } from "components";
import { PATH } from "utils/constants";
import { isAuth } from "utils/helper/checkAuth";

const AuthRoute = () => {
  const { pathname, search } = useLocation();

  return isAuth() ? (
    <PrivateLayout>
      <Outlet />
    </PrivateLayout>
  ) : (
    <Navigate to={PATH.root} state={{ from: pathname + search }} />
  );
};

export default AuthRoute;
