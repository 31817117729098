import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getCommentReportListAPI,
  getCommentReportDetailAPI,
  deleteCommentReportDetailAPI,
  patchCommentReport,
} from "api";
import { checkDeletedUser, utcTimeToLocalTime } from "utils";
import { REPORT_ACTIVE_TYPE, REPORT_USER_ACTIVE_STATUS } from "assets";
import type {
  APIError,
  GetCommentsQueryModel,
  PatchCommentReportQueryModel,
} from "types";

export const commentKeys = {
  all: ["comment"],
  lists: () => [...commentKeys.all, "list"],
  list: (filters: GetCommentsQueryModel) => [
    ...commentKeys.lists(),
    { filters },
  ],
  details: () => [...commentKeys.all, "detail"],
  detail: (id: string) => [...commentKeys.details(), id],
};

export const useGetComments = (req: GetCommentsQueryModel) =>
  useQuery({
    queryKey: commentKeys.list(req),
    queryFn: () => getCommentReportListAPI(req),
    select: (res) => ({
      pageInfo: res.pageInfo,
      reports: res.reports.map((report) => ({
        detailId: report.reportId,
        commentId: report.commentId,
        commentUserId: report.commentUserId,
        commentUserNickname: report.commentUserNickname,
        userId: report.userId,
        userNickname: checkDeletedUser(report.userNickname),
        reportCount: report.reportCount,
        reason: report.reason,
        created: utcTimeToLocalTime(report.created),
        status: REPORT_ACTIVE_TYPE[report.status],
        checked: report.checked ? ("확인" as const) : ("미확인" as const),
      })),
    }),
    suspense: true,
  });

export const useGetCommentDetail = (req: string) =>
  useQuery({
    queryKey: commentKeys.detail(req),
    queryFn: () => getCommentReportDetailAPI(req),
    select: (res) => ({
      ...res,
      checked: utcTimeToLocalTime(res.checked),
      created: utcTimeToLocalTime(res.created),
      postCreated: utcTimeToLocalTime(res.postCreated),
      status: REPORT_ACTIVE_TYPE[res.status],
      commentUserStatus: REPORT_USER_ACTIVE_STATUS[res.commentUserStatus],
      updated: res.updated && utcTimeToLocalTime(res.updated),
      userNickname: checkDeletedUser(res.userNickname),
    }),
    suspense: true,
  });

export const useDeleteComment = () =>
  useMutation<unknown, APIError, string>({
    mutationFn: (req) => deleteCommentReportDetailAPI(req),
  });

export const usePatchComment = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PatchCommentReportQueryModel>({
    mutationFn: (req) => patchCommentReport(req),
    onSuccess: (_, req) => {
      queryClient.invalidateQueries({
        queryKey: commentKeys.detail(req.query.reportId),
      });
    },
  });
};
