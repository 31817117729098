import styled from "@emotion/styled";
export const Filter = styled.div`
  width: 100%;
  margin-bottom: 40px;
  border: ${({ theme }) => `1px solid ${theme.color.gray_20}`};
  border-bottom: 0;
  cursor: default;
`;

interface ConditionWrapperProps {
  columnCount?: number;
}

export const ConditionWrapper = styled.div<ConditionWrapperProps>`
  display: grid;
  grid-template-columns: ${({ columnCount }) => `repeat(${columnCount}, 1fr)`};
`;

export const SearchLabelBoxWrapper = styled.div`
  display: flex;
  padding: 10px 20px;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.gray_20}`};
`;
