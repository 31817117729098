import { ax } from "api/axios";
import type {
  GetOwnerExcelQueryModel,
  GetOwnerQueryModel,
  GetOwnerServerModel,
} from "types";

export const getOwnerListAPI = async (req: GetOwnerQueryModel) => {
  const { data } = await ax.get<GetOwnerServerModel>("/admin/users", {
    params: req.query,
  });

  return data;
};

export const getOwnerExcelDownloadAPI = async (
  req: GetOwnerExcelQueryModel,
) => {
  const data = await ax.get("/admin/users/excel", {
    params: req.query,
    responseType: "arraybuffer",
  });

  return data;
};
