import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ConfirmModal } from "components";
import { useModal, useToast } from "hooks";
import { useBulkNoticeSupport } from "services";
import { TOAST_MSG } from "assets";
import type { NoticeSupportClientModel } from "types";

interface DeleteNoticeModalProps {
  checkedNoticeList: NoticeSupportClientModel[];
  handleAllUnCheck: () => void;
}

const DeleteNoticeModal = ({
  checkedNoticeList,
  handleAllUnCheck,
}: DeleteNoticeModalProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { modalRef, handleModalClose } = useModal();
  const { addToast } = useToast();

  const { isLoading, mutate: deleteNoticeMutate } = useBulkNoticeSupport();
  const tabQuery = searchParams.get("tab");

  const activeFn = () => {
    deleteNoticeMutate(
      {
        body: {
          command: "delete",
          type: tabQuery?.slice(0, -6) as "repairshop" | "user",
          noticeIds: checkedNoticeList.map((notice) => notice.noticeId),
        },
      },
      {
        onSuccess: () => {
          handleAllUnCheck();
          addToast(TOAST_MSG.SUCCESS.NOTICE_DELETE);
          navigate(`/support?tab=${tabQuery}`);
        },
        onSettled: () => {
          handleModalClose();
        },
        onError: (err) => {
          addToast({
            type: "warning",
            content: err.response?.data.message,
          });
        },
      },
    );
  };

  return (
    <ConfirmModal
      ref={modalRef}
      isLoading={isLoading}
      title="공지글 삭제"
      desc="삭제된 게시글은 복구가 불가합니다. 정말 삭제하시겠습니까?"
      btnType="alert"
      activeBtnName="삭제"
      activeFn={activeFn}
    />
  );
};

export default DeleteNoticeModal;
