import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  LabelContent,
  SunEditor,
  Button,
  Division,
  ErrorMsg,
  FormInput,
} from "components";
import { HOOKFORM_ERR_OBJ } from "assets";
import useNoticeForm from "./hooks/useNoticeForm";
import * as S from "./NoticeForm.styled";

interface NoticeFormProps {
  data?: { title: string; content: string };
}

const NoticeForm = ({ data }: NoticeFormProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isRepairshopNotice = pathname.split("/")[2].includes("repairshop");
  const isCreate = pathname.split("/")[3].includes("create");

  const {
    errors,
    control,
    watch,
    register,
    handleIsEmpty,
    handleClearError,
    handleClickSubmit,
  } = useNoticeForm(data);

  const handleClickCancelBtn = () => {
    const noticeTab = pathname.split("/")[2];

    navigate(id ? `/support/${noticeTab}/${id}` : `/support?tab=${noticeTab}`);
  };

  return (
    <form onSubmit={handleClickSubmit}>
      <Division css={S.division} />
      <LabelContent css={S.labelContent} heading={!isCreate ? "상세 내용" : ""}>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content css={S.shortContent} heading="위치">
            {isRepairshopNotice ? "정비소 WEB 용" : "차주 APP 용"}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content css={S.content} required heading="제목">
            <S.InputWrapper>
              <FormInput
                maxLength={30}
                placeholder="제목을 입력하세요"
                hasError={!!errors.title}
                errorMsg={errors.title?.message}
                register={register("title", {
                  required: HOOKFORM_ERR_OBJ.REQUIRED.message,
                  validate: (value) =>
                    value.trim().length > 0 ||
                    HOOKFORM_ERR_OBJ.REQUIRED.message,
                })}
              />
              <span>{`(${watch("title")?.length ?? 0}/30)`}</span>
            </S.InputWrapper>
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content css={S.content} required heading="내용">
            <SunEditor
              name="content"
              placeholder="내용을 입력하세요"
              defaultValue=""
              control={control}
              handleIsEmpty={handleIsEmpty}
              handleClearError={handleClearError}
            />
            {!!errors?.content && (
              <ErrorMsg
                css={S.errorMsg}
                errorMsg={HOOKFORM_ERR_OBJ.REQUIRED.message}
              />
            )}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
      </LabelContent>
      <Division css={S.division} />
      <S.BtnWrapper>
        <Button
          type="submit"
          variant="primary"
          label="업로드"
          isDisabled={Object.keys(errors).length !== 0}
        />
        <Button
          variant="secondary"
          label="취소"
          handleClickBtn={handleClickCancelBtn}
        />
      </S.BtnWrapper>
    </form>
  );
};

export default NoticeForm;
