import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const CommentContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    row-gap: 4px;
    width: 100%;
    min-height: 68px;
    max-height: 300px;
    overflow-y: auto;
    font: ${theme.font.regular_14};
  `}
`;

export const CommentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Comment = styled.p<{ isReport: boolean }>`
  ${({ isReport, theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;
    height: 20px;
    color: ${isReport && theme.color.red_30};
  `}
`;
