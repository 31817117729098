import React from "react";

import {
  ANSWER_STATUS,
  PROCESSEDORNOT_STATUS,
  REPORT_CHECKED_STATUS,
  TRUCK_DETAIL_STATUS,
  REPAIRSHOP_ACTIVE_STATUS,
  REPAIRSHOP_AVAILABLE_STATUS,
  OWNER_MARKETING_STATUS,
} from "assets";
import * as S from "./Status.styled";

interface StatusProps {
  hasBg?: boolean;
  status:
    | (typeof REPORT_CHECKED_STATUS)[keyof typeof REPORT_CHECKED_STATUS]
    | (typeof PROCESSEDORNOT_STATUS)[keyof typeof PROCESSEDORNOT_STATUS]
    | keyof typeof ANSWER_STATUS
    | (typeof TRUCK_DETAIL_STATUS)[keyof typeof TRUCK_DETAIL_STATUS]
    | (typeof REPAIRSHOP_ACTIVE_STATUS)[keyof typeof REPAIRSHOP_ACTIVE_STATUS]
    | (typeof REPAIRSHOP_AVAILABLE_STATUS)[keyof typeof REPAIRSHOP_AVAILABLE_STATUS]
    | (typeof OWNER_MARKETING_STATUS)[keyof typeof OWNER_MARKETING_STATUS];
}

const Status = ({ hasBg, status }: StatusProps) => {
  switch (status) {
    case "확인":
    case "허용":
    case "답변완료":
    case "비활성화":
    case "불가":
    case "등록완료":
    case "거절":
      return <S.GrayLabel hasBg={hasBg}>{status}</S.GrayLabel>;

    case "미확인":
    case "제한":
    case "반려":
    case "차단":
      return <S.RedLabel hasBg={hasBg}>{status}</S.RedLabel>;

    case "미처리":
    case "준비중":
      return <S.OrangeLabel hasBg={hasBg}>{status}</S.OrangeLabel>;

    case "답변대기":
    case "승인대기":
    case "가능":
    case "동의":
      return <S.BlueLabel hasBg={hasBg}>{status}</S.BlueLabel>;

    case "재요청":
      return <S.GreenLabel hasBg={hasBg}>{status}</S.GreenLabel>;

    case "활성화":
      return <S.BlackLabel hasBg={hasBg}>{status}</S.BlackLabel>;

    default:
      return <div>-</div>;
  }
};

export default Status;
