import React from "react";
import { Outlet, Navigate } from "react-router-dom";

import { PublicLayout } from "components";
import { PATH } from "utils/constants";
import { isAuth } from "utils/helper/checkAuth";

const PublicRoute = () => {
  return isAuth() ? (
    <Navigate to={PATH.repairshop} />
  ) : (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};

export default PublicRoute;
