import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const NotFound = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const notFouncIcon = css`
  margin-bottom: 24px;
`;

export const Heading = styled.h2`
  margin-bottom: 16px;
  font-size: 1.8rem;
  font-weight: 500;
`;

export const Content = styled.p`
  margin-bottom: 24px;
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.color.text_sub00};
  line-height: 1.5;
  text-align: center;
`;

export const GoBackBtn = styled.button`
  width: 100px;
  height: 36px;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 3px;
  font-size: 1.4rem;
  font-weight: 500;
`;
