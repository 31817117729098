import React from "react";

import { QueryPagination } from "components";
import { useGetRepairshopSupports } from "services";
import { useFilterParams } from "hooks";
import type { GetRepairshopSupportsQueryModel } from "types";

const RepairshopSupportPagination = () => {
  const { filters } = useFilterParams<GetRepairshopSupportsQueryModel["query"]>(
    ["category", "status", "page"],
  );

  const { data } = useGetRepairshopSupports({
    query: filters,
  });

  return <QueryPagination pageInfo={data?.pageInfo!} />;
};

export default RepairshopSupportPagination;
