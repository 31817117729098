import React, { Suspense } from "react";

import {
  ListPageLayout,
  Filter,
  RepairshopTable,
  RepairshopTableSkeleton,
  RepairshopPagination,
  PaginationSkeleton,
} from "components";
import { useGetTruckBrand } from "services";
import { makeTruckBrands } from "utils";
import { REPAIRSHOP_FILTER_CONDITIONS } from "assets";

const Repairshop = () => {
  const { data: truckBrand } = useGetTruckBrand();

  if (truckBrand) {
    REPAIRSHOP_FILTER_CONDITIONS[0][0].filterList = makeTruckBrands(truckBrand);
  }

  return (
    <ListPageLayout heading="정비소 관리">
      <Filter filterConditions={REPAIRSHOP_FILTER_CONDITIONS} />
      <Suspense fallback={<RepairshopTableSkeleton />}>
        <RepairshopTable />
      </Suspense>
      <Suspense fallback={<PaginationSkeleton />}>
        <RepairshopPagination />
      </Suspense>
    </ListPageLayout>
  );
};

export default Repairshop;
