import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Form = styled.form`
  & > div:nth-of-type(2) > h3 {
    margin-bottom: 2px;
  }
`;

export const division = css`
  margin-bottom: 40px;
`;

export const labelContent = css`
  margin-bottom: 18px;
`;

export const content = css`
  align-items: flex-start;
  grid-template-columns: 160px 1fr;
  margin-bottom: 12px;

  & > dt {
    height: 60px;
    padding: 10px 0;
  }

  & > dd {
    padding: 10px 0 10px 0;

    & > div {
      min-width: 488px;
    }
  }
`;

export const settingContent = (theme: Theme) => css`
  grid-template-columns: 160px 1fr;
  margin-bottom: 12px;

  & > dt {
    min-height: 40px;
    padding: 10px 0;
  }

  & > dd {
    padding: 10px 0;

    & > div {
      width: fit-content;
      margin-right: 8px;

      & > span {
        color: ${theme.color.gray_70};
      }
    }
  }
`;

export const inputWrapper = css`
  & > div {
    flex-flow: column;
    align-items: unset;
  }
`;

export const AddressBtn = styled.button<{
  hasValue: boolean;
  hasError: boolean;
}>`
  ${({ theme, hasValue, hasError }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 488px;
    height: 40px;
    margin-bottom: 8px;
    border: 1px solid ${hasError ? theme.color.red_30 : theme.color.gray_30};
    padding: 10px 13px;
    font: ${theme.font.regular_14};
    color: ${hasValue ? theme.color.black : theme.color.gray_80};
  `}
`;

export const dropdown = css`
  width: 488px;
  margin-bottom: 8px;
`;

export const BrandWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const BrandItem = styled.li`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    max-width: fit-content;
    height: 40px;
    border: 1px solid ${theme.color.gray_30};
    border-radius: 2px;
    padding: 10px 53px 10px 13px;
    font: ${theme.font.regular_14};
    color: ${theme.color.black};
  `}
`;

export const DeleteBtn = styled.button`
  position: absolute;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const formRadioBtn = css`
  column-gap: 12px;
`;
