import React from "react";
import { map } from "lodash-es";

import { Status, DataSection } from "components";
import { useGetBulletins } from "services";
import { BULLETIN_REPORT_TABLE_HEADER } from "assets";
import { useFilterParams } from "hooks";
import { keys } from "utils/helper/table";
import type { GetBulletinsQueryModel } from "types";
import * as S from "./BulletinReportTable.styled";

const BulletinReportTable = () => {
  const { filters } = useFilterParams<GetBulletinsQueryModel["query"]>([
    "page",
    "category",
    "status",
  ]);

  const { data, refetch, dataUpdatedAt } = useGetBulletins({
    query: filters,
  });

  return (
    <DataSection
      totalData={data?.pageInfo.totalData!}
      refetch={refetch}
      dataUpdatedAt={dataUpdatedAt}
    >
      <S.BulletinTable
        title="게시글 신고"
        columnTable={BULLETIN_REPORT_TABLE_HEADER}
      >
        {map(data?.reports, (rowData) => (
          <DataSection.Table.SelectRowMovePage
            key={rowData.detailId}
            id={rowData.detailId}
            path={`/report/bulletin/${rowData.detailId}`}
          >
            {map(keys(BULLETIN_REPORT_TABLE_HEADER), (key) => (
              <DataSection.Table.Td key={key}>
                {key === "status" ? (
                  <Status status={rowData[key]} />
                ) : (
                  rowData[key]
                )}
              </DataSection.Table.Td>
            ))}
          </DataSection.Table.SelectRowMovePage>
        ))}
      </S.BulletinTable>
    </DataSection>
  );
};

export default BulletinReportTable;
