import { PATH } from "utils/constants";

export const REPAIRSHOP = [{ content: "정비소 관리" }] as const;

export const REPAIRSHOP_DETAIL = [
  { content: "정비소 관리", path: PATH.repairshop },
  { content: "정비소 상세" },
] as const;

export const REPAIRSHOP_CREATE = [
  { content: "정비소 관리", path: PATH.repairshop },
  { content: "정비소 추가" },
] as const;

export const REPAIRSHOP_EDIT = [
  { content: "정비소 관리", path: PATH.repairshop },
  { content: "정비소 수정" },
] as const;

export const TRUCK = [{ content: "차량 관리" }] as const;

export const TRUCK_DETAIL = [
  { content: "차량관리", path: PATH.truck },
  { content: "차량상세" },
] as const;

export const REPORT = [{ content: "신고 관리" }] as const;

export const REPORT_REVIEW = [
  { content: "신고 관리", path: PATH.report },
  { content: "리뷰 신고" },
] as const;

export const REPORT_USER = [
  { content: "신고 관리", path: PATH.report },
  { content: "유저 신고" },
] as const;

export const REPORT_BULLETIN = [
  { content: "신고 관리", path: PATH.report },
  { content: "게시글 신고" },
] as const;

export const REPORT_COMMENT = [
  { content: "신고 관리", path: PATH.report },
  { content: "댓글 신고" },
] as const;

export const REPORT_REVIEW_DETAIL = [
  { content: "신고관리", path: PATH.report },
  { content: "리뷰 신고", path: PATH.reviewReport },
  { content: "신고 상세" },
] as const;

export const REPORT_USER_DETAIL = [
  { content: "신고관리", path: PATH.report },
  { content: "유저 신고", path: PATH.userReport },
  { content: "신고 상세" },
] as const;

export const REPORT_BULLETIN_DETAIL = [
  { content: "신고관리", path: PATH.report },
  { content: "게시물 신고", path: PATH.bulletinReport },
  { content: "신고 상세" },
] as const;

export const REPORT_COMMENT_DETAIL = [
  { content: "신고관리", path: PATH.report },
  { content: "댓글 신고", path: PATH.commentReport },
  { content: "신고 상세" },
] as const;

export const SUPPORT = [{ content: "고객 지원" }] as const;

export const OWNER_SUPPORT = [
  { content: "고객 지원", path: PATH.ownerSupport },
  { content: "차주 1:1 문의" },
] as const;

export const REPAIRSHOP_SUPPORT = [
  { content: "고객 지원", path: PATH.support },
  { content: "정비소 1:1 문의" },
] as const;

export const ASK_SUPPORT = [
  { content: "고객 지원", path: PATH.support },
  { content: "자주묻는 질문" },
] as const;

export const NOTICE_SUPPORT = [
  { content: "고객 지원", path: PATH.support },
  { content: "공지사항" },
] as const;

export const OWNER_SUPPORT_DETAIL = [
  { content: "고객지원", path: PATH.support },
  { content: "차주 1:1문의", path: PATH.ownerSupport },
  { content: "문의 상세" },
] as const;

export const REPAIRSHOP_SUPPORT_DETAIL = [
  { content: "고객지원", path: PATH.support },
  { content: "정비소 1:1문의", path: PATH.repairshopSupport },
  { content: "문의 상세" },
] as const;

export const USER_NOTICE_SUPPORT_DETAIL = [
  { content: "고객지원", path: PATH.support },
  { content: "차주 APP 공지사항", path: PATH.noticeUserSupport },
  { content: "공지 상세" },
] as const;

export const REPAIRSHOP_NOTICE_SUPPORT_DETAIL = [
  { content: "고객지원", path: PATH.support },
  { content: "정비소 WEB 공지사항", path: PATH.noticeRepairshopSupport },
  { content: "공지 상세" },
] as const;

export const USER_NOTICE_SUPPORT_CREATE = [
  { content: "고객지원", path: PATH.support },
  { content: "차주 APP 공지사항", path: PATH.noticeUserSupport },
  { content: "공지 추가" },
] as const;

export const REPAIRSHOP_NOTICE_SUPPORT_CREATE = [
  { content: "고객지원", path: PATH.support },
  { content: "정비소 WEB 공지사항", path: PATH.noticeRepairshopSupport },
  { content: "공지 추가" },
] as const;

export const USER_NOTICE_SUPPORT_EDIT = [
  { content: "고객지원", path: PATH.support },
  { content: "차주 APP 공지사항", path: PATH.noticeUserSupport },
  { content: "공지 수정" },
] as const;

export const REPAIRSHOP_NOTICE_SUPPORT_EDIT = [
  { content: "고객지원", path: PATH.support },
  { content: "정비소 WEB 공지사항", path: PATH.noticeRepairshopSupport },
  { content: "공지 수정" },
] as const;
