import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const registrationLabelContent = (theme: Theme) => css`
  margin-bottom: 40px;

  & > div:nth-of-type(1),
  & > div:nth-of-type(4) {
    margin-bottom: 13px;
  }

  & > div:nth-of-type(2),
  & > div:nth-of-type(5) {
    border-top: 1px solid ${theme.color.gray_20};
  }
`;

export const labelContent = css`
  margin-bottom: 40px;
`;

export const contentAddr = css`
  & > dd {
    padding: 4px 20px;
  }
`;

export const Address = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Request = styled.span`
  ${({ theme }) => css`
    display: flex;
    column-gap: 4px;

    & > span {
      color: ${theme.color.gray_70};
    }
  `}
`;
