export const REPAIRSHOP_TABLE_HEADER = {
  repairshopName: "정비소명",
  brands: "담당 브랜드",
  memberCount: "맴버 수",
  reservationCount: "정비 수",
  reviewCount: "리뷰 수",
  repairshopStatus: "상태",
  available: "인앱 예약",
} as const;

export const TRUCK_TABLE_HEADER = {
  status: "처리 상태",
  userName: "차주명",
  userPhone: "연락처",
  brandTitle: "차량브랜드",
  regNum: "차량번호",
  vin: "차대번호",
  mileage: "주행거리",
  created: "요청일시",
  updated: "등록 완료 일시",
} as const;

export const OWNER_TABLE_HEADER = {
  name: "차주명",
  phone: "연락처",
  gender: "성별",
  nickname: "닉네임",
  birthdate: "생년월일",
  marketing: "마케팅 수신",
  created: "계정 생성 일시",
} as const;

export const OWNER_SUPPORT_TABLE_HEADER = {
  askStatus: "처리상태",
  category: "문의 유형",
  userName: "차주(닉네임)",
  title: "문의 내용",
  created: "등록 일시",
} as const;

export const REPAIRSHOP_SUPPORT_TABLE_HEADER = {
  askStatus: "처리상태",
  category: "문의 유형",
  repairshopName: "정비소명",
  name: "작성자명",
  title: "제목",
  created: "등록 일시",
} as const;

export const NOTICE_SUPPORT_TABLE_HEADER = {
  idx: "순서",
  title: "제목",
  created: "등록 일시",
} as const;

export const REVIEW_REPORT_TABLE_HEADER = {
  checked: "확인여부",
  userNickname: "리뷰 작성자",
  repairshopName: "신고자 (정비소명)",
  reason: "신고 사유",
  created: "신고 일시",
} as const;

export const USER_REPORT_TABLE_HEADER = {
  status: "처리 여부",
  targetNickname: "피신고자",
  userNickname: "신고자",
  reason: "신고 사유",
  created: "신고 일시",
} as const;

export const BULLETIN_REPORT_TABLE_HEADER = {
  status: "처리 여부",
  postUserNickname: "게시글 작성자",
  userNickname: "신고자",
  reason: "신고 사유",
  created: "신고 일시",
} as const;

export const COMMENT_REPORT_TABLE_HEADER = {
  status: "처리 여부",
  commentUserNickname: "댓글 작성자",
  userNickname: "신고자",
  reason: "신고 사유",
  created: "신고 일시",
} as const;

export const STATICS_TABLE_HEADER = {
  repairshopName: "정비소명",
  appPlanned: "예약요청",
  appAccepted: "예약완료",
  appRepairing: "정비중",
  appCompleted: "예약완료",
  appCanceled: "예약취소",
  appTotal: "총계",
  selfAccepted: "예약완료",
  selfRepairing: "정비중",
  selfCompleted: "예약완료",
  selfCanceled: "정비취소",
  selfTotal: "총계",
} as const;
