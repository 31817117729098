import React from "react";

import {
  ListPageLayout,
  Filter,
  OwnerPagination,
  OwnerTable,
} from "components";
import { OWNER_FILTER_CONDITIONS } from "assets";

const Owner = () => {
  return (
    <ListPageLayout heading="차주관리">
      <Filter filterConditions={OWNER_FILTER_CONDITIONS} />
      <OwnerTable />
      <OwnerPagination />
    </ListPageLayout>
  );
};

export default Owner;
