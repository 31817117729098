import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Header = styled.header`
  ${({ theme }) => css`
    position: sticky;
    top: ${theme.size.HEADER_HEIGHT};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    background-color: ${theme.color.white};
    z-index: ${theme.zIndex.STICKY};

    & > div:first-of-type {
      padding: 6px 0;
      margin-bottom: 13px;
    }
  `}
`;

export const LeftContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;

    & > h2 {
      font: ${theme.font.medium_18};
      margin-right: 12px;
      color: ${theme.color.black};
    }
  `}
`;

export const Refetch = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    height: 20px;
    font: ${theme.font.regular_13};

    & > span {
      color: ${theme.color.gray_60};
    }

    & > time {
      margin: 0 2px 0 4px;
      color: ${theme.color.gray_80};
    }
  `}
`;

export const RefetchBtn = styled.button<{ rotationAngle: number }>`
  ${({ theme, rotationAngle }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${theme.color.gray_10};

    & > svg {
      transition: transform 0.3s ease-in-out;
      transform: rotate(${rotationAngle}deg);

      path {
        fill: ${theme.color.blue_10};
      }
    }
  `}
`;

export const ActiveWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const TableWrapper = styled.div`
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;
