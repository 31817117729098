import styled from "@emotion/styled";
import { css } from "@emotion/react";
export const PasswordHint = styled.div<{
  isValidCheck: boolean;
  isDefault: boolean;
}>`
  ${({ theme, isValidCheck, isDefault }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;
    heigth: 18px;
    font: ${theme.font.regular_13};
    color: ${!isDefault
      ? theme.color.gray_60
      : !isValidCheck
      ? theme.color.red_30
      : theme.color.green_20};

    & > svg {
      width: 16px;
      height: 16px;

      & > path {
        fill: ${!isDefault
          ? theme.color.gray_40
          : !isValidCheck
          ? theme.color.red_30
          : theme.color.green_20};
      }
    }
  `}
`;
