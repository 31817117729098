export const USER_REPORT_ACTIVE_TYPE = {
  null: "미처리",
  sustained: "허용",
  blocked: "제한",
} as const;

export const USER_REPORT_ACTIVE_STATUS = {
  active: "허용",
  blocked: "제한",
} as const;

export const REPORT_ACTIVE_TYPE = {
  null: "미처리",
  sustained: "허용",
  blocked: "차단",
} as const;

export const REPORT_USER_ACTIVE_STATUS = {
  active: "허용",
  blocked: "차단",
} as const;

export const REPORT_CATEGORY = {
  "광고/사기": "광고/사기",
  "음란성/선정성": "음란성/선정성",
  "욕설/인신공격": "욕설/인신공격",
  개인정보노출: "개인정보노출",
  기타: "기타",
} as const;

export const RECEIVE_ADVERTISING_EMAIL = {
  y: "이메일",
  n: "-",
} as const;

export const ANSWER_STATUS = {
  답변대기: "답변대기",
  답변완료: "답변완료",
} as const;

export const REPORT_CHECKED_STATUS = {
  true: "확인",
  false: "미확인",
} as const;

export const PROCESSEDORNOT_STATUS = {
  미처리: "미처리",
  허용: "허용",
  제한: "제한",
  차단: "차단",
} as const;
