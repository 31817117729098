import { useQuery } from "@tanstack/react-query";

import { getTruckBrandAPI } from "api/index";

const commonKeys = {
  all: ["common"],
  truckBrand: ["truckBrand"],
};

export const useGetTruckBrand = () => {
  return useQuery({
    queryKey: commonKeys.truckBrand,
    queryFn: () => getTruckBrandAPI(),
    staleTime: Infinity,
    cacheTime: Infinity,
    suspense: false,
  });
};
