import React from "react";

import { DataSectionSkeleton } from "components";
import { TRUCK_TABLE_GRID_COLUMNS, TRUCK_TABLE_HEADER } from "assets";

const TruckTableSkeleton = () => {
  return (
    <DataSectionSkeleton>
      <DataSectionSkeleton.Table
        columnTable={TRUCK_TABLE_HEADER}
        gridTemplateColumns={TRUCK_TABLE_GRID_COLUMNS}
      />
    </DataSectionSkeleton>
  );
};

export default TruckTableSkeleton;
