import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getBulletinReportListAPI,
  getBulletinReportDetailAPI,
  deleteBulletinReportDetailAPI,
  patchBulletinReport,
} from "api";
import { checkDeletedUser, utcTimeToLocalTime } from "utils";
import { REPORT_ACTIVE_TYPE, REPORT_USER_ACTIVE_STATUS } from "assets";
import type {
  APIError,
  GetBulletinsQueryModel,
  PatchBulletinReportQueryModel,
} from "types";

export const bulletinKeys = {
  all: ["bulletin"],
  lists: () => [...bulletinKeys.all, "list"],
  list: (filters: GetBulletinsQueryModel) => [
    ...bulletinKeys.lists(),
    { filters },
  ],
  details: () => [...bulletinKeys.all, "detail"],
  detail: (id: string) => [...bulletinKeys.details(), id],
};

export const useGetBulletins = (req: GetBulletinsQueryModel) =>
  useQuery({
    queryKey: bulletinKeys.list(req),
    queryFn: () => getBulletinReportListAPI(req),
    select: (res) => ({
      pageInfo: res.pageInfo,
      reports: res.reports.map((report) => ({
        detailId: report.reportId,
        postId: report.postId,
        postUserId: report.postUserId,
        postUserNickname: report.postUserNickname,
        userId: report.userId,
        userNickname: checkDeletedUser(report.userNickname),
        reportCount: report.reportCount,
        reason: report.reason,
        created: utcTimeToLocalTime(report.created),
        status: REPORT_ACTIVE_TYPE[report.status],
        checked: report.checked ? ("확인" as const) : ("미확인" as const),
      })),
    }),
    suspense: true,
  });

export const useGetBulletinDetail = (req: string) =>
  useQuery({
    queryKey: bulletinKeys.detail(req),
    queryFn: () => getBulletinReportDetailAPI(req),
    select: (res) => ({
      ...res,
      checked: utcTimeToLocalTime(res.checked),
      created: utcTimeToLocalTime(res.created),
      postCreated: utcTimeToLocalTime(res.postCreated),
      status: REPORT_ACTIVE_TYPE[res.status],
      postUserStatus: REPORT_USER_ACTIVE_STATUS[res.postUserStatus],
      updated: res.updated && utcTimeToLocalTime(res.updated),
      userNickname: checkDeletedUser(res.userNickname),
    }),
    suspense: true,
  });

export const useDeleteBulletin = () =>
  useMutation<unknown, APIError, string>({
    mutationFn: (req) => deleteBulletinReportDetailAPI(req),
  });

export const usePatchBulletinReport = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PatchBulletinReportQueryModel>({
    mutationFn: (req) => patchBulletinReport(req),
    onSuccess: (_, req) => {
      queryClient.invalidateQueries({
        queryKey: bulletinKeys.detail(req.query.reportId),
      });
    },
  });
};
