import { createPortal } from "react-dom";

interface ModalPortalProps {
  children: any;
}

const ModalPortal = ({ children }: ModalPortalProps) => {
  return createPortal(
    children,
    document.getElementById("modal-root") as HTMLElement,
  );
};

export default ModalPortal;
