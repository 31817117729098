import { css } from "@emotion/react";

export const labelContent = css`
  margin-bottom: 41px;
`;

export const content = css`
  min-height: 88px;
  align-items: flex-start;
`;
