import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const pageLayout = css`
  & > h1 {
    margin-bottom: 12px;
  }
`;

export const truckOwnerInfo = css`
  margin-bottom: 48px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  column-gap: 21px;
`;
