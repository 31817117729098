import React, { Suspense } from "react";

import {
  NoticePagination,
  NoticeTable,
  NoticeTableSkeleton,
  PaginationSkeleton,
} from "components";

const UserNoticeSupport = () => {
  return (
    <>
      <Suspense fallback={<NoticeTableSkeleton />}>
        <NoticeTable />
      </Suspense>
      <Suspense fallback={<PaginationSkeleton />}>
        <NoticePagination />
      </Suspense>
    </>
  );
};

export default UserNoticeSupport;
