import React from "react";
import { useParams } from "react-router-dom";

import { PageLayout, Report, UserForm } from "components";
import { useGetUserDetail } from "services";
import { USER_REPORT_DETAIL, USER_REPORT_DETAIL_PAGE_LAYOUT } from "assets";
import * as S from "./UserReportDetail.styled";

const UserReportDetail = () => {
  const { id } = useParams();

  const { data } = useGetUserDetail(id!);

  if (!data) return null;

  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={USER_REPORT_DETAIL_PAGE_LAYOUT}
    >
      <Report data={data} contents={USER_REPORT_DETAIL} />
      <UserForm data={data} />
    </PageLayout>
  );
};

export default UserReportDetail;
