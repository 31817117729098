import { calcTableWidth } from "utils/helper/table";

export const REPAIRSHOP_TABLE_GRID_COLUMNS = calcTableWidth([
  "234px",
  "320px",
  "90px",
  "90px",
  "90px",
  "100px",
  "100px",
]);

export const TRUCK_TABLE_GRID_COLUMNS = calcTableWidth([
  "100px",
  "100px",
  "124px",
  "100px",
  "144px",
  "234px",
  "124px",
  "134px",
  "134px",
]);

export const OWNER_TABLE_GRID_COLUMNS = calcTableWidth([
  "100px",
  "124px",
  "90px",
  "144px",
  "134px",
  "100px",
  "134px",
]);

export const OWNER_SUPPORT_TABLE_GRID_COLUMNS = calcTableWidth([
  "100px",
  "100px",
  "144px",
  "500px",
  "134px",
]);

export const REPAIRSHOP_SUPPORT_TABLE_GRID_COLUMNS = calcTableWidth([
  "100px",
  "100px",
  "234px",
  "100px",
  "500px",
  "134px",
]);

export const NOTICE_SUPPORT_TABLE_GRID_COLUMNS = calcTableWidth([
  "40px",
  "60px",
  "320px",
  "134px",
]);

export const REVIEW_REPORT_TABLE_GRID_COLUMNS = calcTableWidth([
  "100px",
  "144px",
  "234px",
  "320px",
  "134px",
]);

export const USER_REPORT_TABLE_GRID_COLUMNS = calcTableWidth([
  "100px",
  "144px",
  "144px",
  "320px",
  "134px",
]);

export const BULLETIN_REPORT_TABLE_GRID_COLUMNS = calcTableWidth([
  "100px",
  "144px",
  "144px",
  "320px",
  "134px",
]);

export const COMMENT_REPORT_TABLE_GRID_COLUMNS = calcTableWidth([
  "100px",
  "144px",
  "144px",
  "320px",
  "134px",
]);

export const STATICS_TABLE_GRID_COLUMNS = calcTableWidth([
  "246px",
  "110px",
  "90px",
  "90px",
  "90px",
  "90px",
  "99px",
  "110px",
  "90px",
  "90px",
  "90px",
  "99px",
]);
