import React, { Suspense } from "react";

import {
  ListPageLayout,
  Filter,
  TruckPagination,
  TruckTable,
  TruckTableSkeleton,
  PaginationSkeleton,
} from "components";
import { useGetTruckBrand } from "services";
import { makeTruckBrands } from "utils";
import { TRUCK_FILTER_CONDITIONS } from "assets";

const Truck = () => {
  const { data: truckBrand } = useGetTruckBrand();

  if (truckBrand) {
    TRUCK_FILTER_CONDITIONS[0][0].filterList = makeTruckBrands(truckBrand);
  }

  return (
    <ListPageLayout heading="차량 관리">
      <Filter filterConditions={TRUCK_FILTER_CONDITIONS} />
      <Suspense fallback={<TruckTableSkeleton />}>
        <TruckTable />
      </Suspense>
      <Suspense fallback={<PaginationSkeleton />}>
        <TruckPagination />
      </Suspense>
    </ListPageLayout>
  );
};

export default Truck;
