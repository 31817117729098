import React from "react";

import { LabelContent, WriteAnswer, Division } from "components";
import { useCreateAnswerOwnerSupport } from "services";
import type { GetOwnerSupportDetailClientModel } from "types";
import * as S from "./OwnerSupportForm.styled";

interface OwnerSupporFormProps {
  data: GetOwnerSupportDetailClientModel;
}

const OwnerSupportForm = ({ data }: OwnerSupporFormProps) => {
  const { mutate: registAnswerOwnerSupportMutate } =
    useCreateAnswerOwnerSupport();

  return (
    <>
      <Division css={S.division} />
      {data.status === "답변완료" ? (
        <LabelContent heading="답글 상세" hasBg>
          <LabelContent.ContentWrapper subItemCount={1}>
            <LabelContent.Content heading="제목">
              {data.answerTitle}
            </LabelContent.Content>
          </LabelContent.ContentWrapper>
          <LabelContent.ContentWrapper subItemCount={1}>
            <LabelContent.Content css={S.answerContent} heading="내용">
              {data.answerContent}
            </LabelContent.Content>
          </LabelContent.ContentWrapper>
        </LabelContent>
      ) : (
        <WriteAnswer
          helpId={data.helpId}
          submitFn={registAnswerOwnerSupportMutate}
        />
      )}
    </>
  );
};

export default OwnerSupportForm;
