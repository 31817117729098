import React from "react";
import { v4 as uuidv4 } from "uuid";
import type { UseFormRegisterReturn } from "react-hook-form";

import * as S from "./FormRadioBtn.styled";

interface RadioBtnProps {
  className?: string;
  disabled?: boolean;
  radioList: readonly { key: string; label: string }[];
  register: UseFormRegisterReturn<string>;
}

const FormRadioBtn = ({
  className,
  disabled,
  radioList,
  register,
}: RadioBtnProps) => {
  const uuid = uuidv4();

  return (
    <S.Wrapper className={className}>
      {radioList.map((item) => (
        <S.RadioBtnWrapper key={item.key}>
          <S.RadioBtn
            type="radio"
            id={item.key + uuid}
            disabled={disabled}
            value={item.key}
            {...register}
          />
          <S.Radio className="label" htmlFor={item.key + uuid} />
          <S.Label htmlFor={item.key + uuid} disabled={disabled}>
            {item.label}
          </S.Label>
        </S.RadioBtnWrapper>
      ))}
    </S.Wrapper>
  );
};

export default FormRadioBtn;
