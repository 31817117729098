import React from "react";

import { ConfirmModal } from "components/common";

interface LoginModalProps {
  submitFn: () => void;
}

const LoginModal = React.forwardRef(
  (
    { submitFn }: LoginModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        title="로그인 제한됨"
        activeBtnName="제한 해제"
        btnType="active"
        desc={
          "비밀번호 오류 제한 수를 초과하여 로그인이 제한되었습니다.\n비밀번호를 재설정하여 로그인 제한을 해제하시겠습니까?"
        }
        activeFn={submitFn}
      />
    );
  },
);

export default LoginModal;
