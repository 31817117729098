import React from "react";

import { ErrorMsg } from "components";
import * as S from "./ErrorContent.styled";

interface ErrorContentProps {
  className?: string;
  children: React.ReactNode;
  errorMsg?: string;
}

const ErrorContent = ({ className, children, errorMsg }: ErrorContentProps) => {
  return (
    <S.ErrorContent className={className}>
      <S.Wrapper>{children}</S.Wrapper>
      {errorMsg && <ErrorMsg css={S.errorMsg} errorMsg={errorMsg} />}
    </S.ErrorContent>
  );
};

export default ErrorContent;
