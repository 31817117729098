/* eslint-disable no-useless-escape */
import axios from "axios";
import { ax } from "api/axios";

import { getPresignedAPI, uploadBase64ImageToS3API } from "./commonApi";
import type {
  GetOwnerSupportServerModel,
  GetOwnerSupportsQueryModel,
  GetOwnerSupportDetailClientModel,
  GetRepairshopSupportsQueryModel,
  GetRepairshopSupportServerModel,
  GetRepairshopSupportDetailServerModel,
  GetNoticeSupportsQueryModel,
  GetNoticeSupportServerModel,
  GetNoticeSupportDetailServerModel,
  CreateSupportQueryModel,
  CreateNoticeQueryModel,
  EditNoticeQueryModel,
  PostNoticeBulkQueryModel,
} from "types";

export const getOwnerSupportListAPI = async (
  req: GetOwnerSupportsQueryModel,
) => {
  const { data } = await ax.get<GetOwnerSupportServerModel>(
    "/admin/helps/users",
    { params: req.query },
  );
  return data;
};

export const getOwnerSupportDetailAPI = async (
  id: string,
): Promise<GetOwnerSupportDetailClientModel> => {
  const { data } = await ax.get(`/admin/helps/users/${id}`);

  const imgUrls: string[] = [];
  if (data.attach.length !== 0) {
    const res = await axios.all(
      data.attach.map((item: string) => getPresignedAPI(item)),
    );

    res.forEach((item: any) => imgUrls.push(item));
  }

  return { ...data, imgUrls };
};

export const createAnswerOwnerAPI = (req: CreateSupportQueryModel) =>
  ax.post(`/admin/helps/users/${req.helpId}`, {
    answerTitle: req.body.answerTitle,
    answerContent: req.body.answerContent,
  });

export const getRepairshopSupportListAPI = async (
  req: GetRepairshopSupportsQueryModel,
) => {
  const { data } = await ax.get<GetRepairshopSupportServerModel>(
    "/admin/helps/repairshops",
    { params: req.query },
  );
  return data;
};

export const getRepairshopSupportDetailAPI = async (
  id: string,
): Promise<GetRepairshopSupportDetailServerModel> => {
  const { data } = await ax.get(`/admin/helps/repairshops/${id}`);

  const imgUrls: string[] = [];
  if (data.attach.length !== 0) {
    const res = await axios.all(
      data.attach.map((item: string) => getPresignedAPI(item)),
    );

    res.forEach((item: any) => imgUrls.push(item));
  }

  return { ...data, imgUrls };
};

export const createAnswerRepairshopAPI = (req: CreateSupportQueryModel) =>
  ax.post(`/admin/helps/repairshops/${req.helpId}`, {
    answerTitle: req.body.answerTitle,
    answerContent: req.body.answerContent,
  });

export const getNoticeSupportListAPI = async (
  req: GetNoticeSupportsQueryModel,
) => {
  const { data } = await ax.get<GetNoticeSupportServerModel>("/admin/notices", {
    params: req.query,
  });

  return data;
};

export const bulkNoticeAPI = (req: PostNoticeBulkQueryModel) =>
  ax.post("/admin/notices/bulk", req.body);

export const getNoticeSupportDetailAPI = async (noticeId: string) => {
  const { data } = await ax.get<GetNoticeSupportDetailServerModel>(
    `/admin/notices/${noticeId}`,
  );

  return data;
};

export const createNoticeSupportAPI = async (req: CreateNoticeQueryModel) => {
  const res = await uploadBase64ImageToS3API(req.content);

  return await ax.post("/admin/notices", {
    type: req.type,
    title: req.title,
    content: res ? res.content : "",
    images: res ? res.fileNames : [],
  });
};

export const editNoticeSupportAPI = async (req: EditNoticeQueryModel) => {
  const res = await uploadBase64ImageToS3API(req.content);

  return await ax.put(`/admin/notices/${req.id}`, {
    type: req.type,
    title: req.title,
    content: res ? res.content : "",
    images: res ? res.fileNames : [],
  });
};

export const deleteNoticeSupportAPI = (noticeId: string) =>
  ax.delete(`/admin/notices/${noticeId}`);
