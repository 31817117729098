import React from "react";
import { v4 as uuidv4 } from "uuid";

import {
  CheckboxIcon,
  CloseBtnIcon,
  DropdownArrowIcon,
  ErrIcon,
} from "assets/icon";
import type { BrandDropdownOption } from "types";
import useBrandDropdown from "./hooks/useBrandDropdown";
import * as S from "./BrandDropdown.styled";

interface BrandDropdownProps {
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
  options: BrandDropdownOption[];
  placeholder?: string;
  selectedOptions: BrandDropdownOption[];
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  handleSelect?: (value: BrandDropdownOption) => void;
  handleDeleteBrand: (option: BrandDropdownOption) => () => void;
  handleFocusCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  handleBlurCondition?: (e?: React.FocusEvent<HTMLInputElement>) => void;
}

const BrandDropdown = ({
  className,
  disabled,
  hasError,
  options,
  placeholder = "",
  selectedOptions,
  handleSelect,
  handleDeleteBrand,
  handleFocusCondition,
  handleBlurCondition,
}: BrandDropdownProps) => {
  const { dropdownRef, optionsRef, isOpen, handleOpener, handleClickOption } =
    useBrandDropdown(handleSelect, handleFocusCondition, handleBlurCondition);
  const id = uuidv4();

  return (
    <S.Wrapper>
      <S.Dropdown ref={dropdownRef} className={className}>
        <S.DropdownBtn
          aria-controls={id}
          aria-expanded={isOpen}
          hasError={hasError}
          disabled={disabled}
          type="button"
          onClick={handleOpener}
        >
          <S.Placeholder>{placeholder}</S.Placeholder>
          <DropdownArrowIcon />
        </S.DropdownBtn>
        <S.OptionWrapper
          ref={optionsRef}
          aria-hidden={!isOpen}
          id={id}
          isOpen={isOpen}
        >
          {options.length === 0 ? (
            <S.NoOptionData>
              <ErrIcon />
              <S.NoOptionDataMsg>No history</S.NoOptionDataMsg>
            </S.NoOptionData>
          ) : (
            options.map((brand, i) => (
              <S.Option key={i}>
                <S.OptionBtn
                  data-selected={selectedOptions.some(
                    (selectedOption) =>
                      selectedOption.brandId === brand.brandId,
                  )}
                  type="button"
                  status="default"
                  onClick={handleClickOption(brand)}
                >
                  {brand.brandName}
                  <CheckboxIcon css={S.checkboxIcon} />
                </S.OptionBtn>
              </S.Option>
            ))
          )}
        </S.OptionWrapper>
      </S.Dropdown>
      <S.BrandWrapper>
        {selectedOptions.map(({ brandId, brandName }) => {
          return (
            <S.BrandItem key={brandId}>
              {brandName}
              <S.DeleteBtn
                type="button"
                onClick={handleDeleteBrand({ brandId, brandName })}
              >
                <CloseBtnIcon />
              </S.DeleteBtn>
            </S.BrandItem>
          );
        })}
      </S.BrandWrapper>
    </S.Wrapper>
  );
};

export default BrandDropdown;
