import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Button,
  ReparishopDeleteModal,
  ReparishopNotDeleteModal,
} from "components";
import { useModal, useToast } from "hooks";
import { useDeleteRepairshop } from "services";
import { TOAST_MSG } from "assets";
import * as S from "./RepairshopDetailBtns.styled";

const RepairshopDetailBtns = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const { addToast } = useToast();

  const { mutate: deleteRepairshopMutate } = useDeleteRepairshop();

  const handleClickEditBtn = () => {
    navigate(`/repairshop/edit/${id}`);
  };

  const handleClickDeleteBtn = () => {
    const deleteFn = () => {
      handleModalClose();

      deleteRepairshopMutate(id!, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.REPAIRSHOP_DELETE);
          navigate("/repairshop");
        },
        onError: (error) => {
          if (error.response?.data.code === "ONGOING_RESERVATION_EXISTS") {
            const closeFn = () => {
              handleModalClose();
            };

            handleModalOpen(
              <ReparishopNotDeleteModal
                ref={modalRef}
                handleDelete={closeFn}
              />,
            )();
          } else {
            addToast(TOAST_MSG.WARNING.FAIL);
          }
        },
      });
    };

    handleModalOpen(
      <ReparishopDeleteModal ref={modalRef} handleDelete={deleteFn} />,
    )();
  };

  return (
    <S.ButtonWrapper>
      <Button
        variant="primary"
        label="수정"
        handleClickBtn={handleClickEditBtn}
      />
      <Button
        variant="error"
        label="탈퇴"
        handleClickBtn={handleClickDeleteBtn}
      />
    </S.ButtonWrapper>
  );
};

export default RepairshopDetailBtns;
