import { PATH } from "utils/constants";
import {
  CSIcon,
  StatisticsIcon,
  RepairshopIcon,
  DriverManagementIcon,
  TruckManagementIcon,
  ReportIcon,
} from "assets/icon/index";

export const NAV_LIST = [
  { path: PATH.statistics, Icon: StatisticsIcon, content: "지표관리" },
  { path: PATH.repairshop, Icon: RepairshopIcon, content: "정비소 관리" },
  { path: PATH.truck, Icon: TruckManagementIcon, content: "차량 관리" },
  { path: PATH.owner, Icon: DriverManagementIcon, content: "차주 관리" },
  {
    path: PATH.report,
    Icon: ReportIcon,
    content: "신고 관리",
  },
  {
    path: PATH.support,
    Icon: CSIcon,
    content: "고객지원",
  },
] as const;
