import { css, Theme } from "@emotion/react";

export const labelContent = (theme: Theme) =>
  css`
    & + & {
      margin-bottom: 41px;
    }

    & > div:nth-of-type(1),
    & > div:nth-of-type(2) {
      margin-bottom: 14px;
    }

    & > div:nth-of-type(2),
    & > div:nth-of-type(3) {
      border-top: 1px solid ${theme.color.gray_20};
    }

    & > div:last-of-type {
      margin-bottom: 40px;
    }
  `;

export const division = css`
  margin-bottom: 40px;
`;

export const content = css`
  min-height: 88px;
  align-items: flex-start;
`;
