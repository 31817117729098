import React from "react";

import * as S from "./Support.styled";

import {
  Tab,
  OwnerSupport,
  RepairshopSupport,
  UserNoticeSupport,
  RepairshopNoticeSupport,
  ListPageLayout,
} from "components";

const Support = () => {
  const SUPPORT_TABS = {
    owner: {
      tabName: "차주 1:1 문의",
      component: <OwnerSupport />,
    },
    repairshop: {
      tabName: "정비소 1:1 문의",
      component: <RepairshopSupport />,
    },
    userNotice: { tabName: "차주 APP 공지", component: <UserNoticeSupport /> },
    repairshopNotice: {
      tabName: "정비소 WEB 공지",
      component: <RepairshopNoticeSupport />,
    },
  } as const;

  return (
    <ListPageLayout css={S.heading} heading="고객지원">
      <Tab tabList={SUPPORT_TABS} />
    </ListPageLayout>
  );
};
export default Support;
