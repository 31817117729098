import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { NotFoundIcon } from "assets";
import * as S from "./NotFount.styled";

interface NotFoundProps {
  resetErrorState?: () => void;
}

const NotFound = ({ resetErrorState }: NotFoundProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoHome = () => {
    navigate("/");
  };

  useEffect(() => {
    return () => {
      resetErrorState && resetErrorState();
    };
  }, [location.pathname]);

  return (
    <S.NotFound>
      <NotFoundIcon css={S.notFouncIcon} />
      <S.Heading>페이지를 찾을 수 없습니다.</S.Heading>
      <S.Content>
        입력한 주소가 잘못되었거나, 사용이 일시 중단되어 <br /> 요청한 페이지를
        찾을 수 없습니다. <br /> 서비스 이용에 불편을 드려 죄송합니다.
      </S.Content>
      <S.GoBackBtn type="button" onClick={handleGoHome}>
        홈으로 이동
      </S.GoBackBtn>
    </S.NotFound>
  );
};

export default NotFound;
