import React from "react";

import {
  FieldTableForm,
  FormInput,
  Dropdown,
  Button,
  FormRadioBtn,
  Textarea,
} from "components";
import { onlyNumber, onlyNumberUpperEnglish } from "utils";
import { HOOKFORM_ERR_OBJ, TOAST_MSG } from "assets";
import type { TruckDetailClientModel } from "types";
import useTruckDetailForm from "./hooks/useTruckDetailForm";
import * as S from "./TruckDetailTable.styled";

interface TruckDetailTableProps {
  data: TruckDetailClientModel;
}

const TruckDetailTable = ({ data }: TruckDetailTableProps) => {
  const {
    handleSelectBrand,
    truckDropdownOption,
    isVinCheck,
    setIsVinCheck,
    handleVinCheck,
    isFormStatusReject,
    addToast,
    register,
    watch,
    setValue,
    errors,
    clearErrors,
    handleSubmit,
  } = useTruckDetailForm({ data });

  return (
    <form onSubmit={handleSubmit}>
      <FieldTableForm css={S.fieldTableForm} caption="차량 정보">
        <FieldTableForm.Row title="차주명" disabled>
          {data.userName}
        </FieldTableForm.Row>
        <FieldTableForm.Row title="차량번호" hasErr={!!errors.regNum}>
          <FormInput
            css={S.input}
            placeholder="차량번호를 입력하세요"
            maxLength={10}
            register={register("regNum", {
              required: true,
              pattern: /^([가-힣]{2})?[0-9]{1,3}[가-힣]{1}[0-9]{4}$/,
              onBlur: () =>
                !!errors.regNum &&
                addToast(TOAST_MSG.WARNING.TRUCK_NUMBER_INCORRECT),
            })}
          />
        </FieldTableForm.Row>
        <FieldTableForm.Row title="차량브랜드" hasErr={!!errors.brand}>
          {truckDropdownOption && (
            <Dropdown
              css={S.dropdown}
              placeholder="브랜드를 선택하세요"
              optionArr={truckDropdownOption}
              selectedOption={watch("brand")}
              handleSelect={handleSelectBrand}
            />
          )}
        </FieldTableForm.Row>
        {(watch("confirm") === "approve" || watch("confirm") === null) && (
          <>
            <FieldTableForm.Row title="차명" hasErr={!!errors.modelName}>
              <FormInput
                css={S.input}
                placeholder="차명을 입력하세요"
                maxLength={50}
                register={register("modelName", {
                  required: true,
                })}
              />
            </FieldTableForm.Row>
            <FieldTableForm.Row title="모델연도" hasErr={!!errors.modelYear}>
              <FormInput
                css={S.input}
                placeholder="연도를 입력하세요 예)2002"
                maxLength={4}
                register={register("modelYear", {
                  required: true,
                  validate: (data) => data.length === 4,
                  pattern: /^[0-9]{4}$/,
                  min: 1901,
                  max: 2155,
                  onChange: (e) => {
                    setValue("modelYear", onlyNumber(e.target.value));
                  },
                  onBlur: (e) => {
                    const value = e.target.value;
                    if (value && (value < 1901 || value > 2155))
                      addToast(TOAST_MSG.WARNING.TRUCK_MODEL_YEAR_INVALID);
                  },
                })}
              />
            </FieldTableForm.Row>
            <FieldTableForm.Row
              css={S.vinWrapper}
              title="차대번호"
              hasErr={!!errors.vin}
            >
              <>
                <FormInput
                  css={S.vinInput}
                  placeholder="차대번호를 입력하세요"
                  maxLength={17}
                  register={register("vin", {
                    required: true,
                    validate: (data) => data.length === 17,
                    onChange: (e) => {
                      setValue("vin", onlyNumberUpperEnglish(e.target.value));
                      setIsVinCheck(false);
                    },
                    onBlur: (e) => {
                      if (e.target.value.length < 17)
                        addToast(TOAST_MSG.WARNING.TRUCK_IDENTIFICATION_EMPTY);
                    },
                  })}
                />
                <Button
                  css={(theme) => S.vinButton(theme, isVinCheck)}
                  variant="third"
                  type="button"
                  label={!isVinCheck ? "중복 확인" : "확인완료"}
                  tabIndex={isVinCheck ? -1 : 0}
                  isDisabled={
                    errors.vin && Object.keys(errors.vin).length !== 0
                  }
                  handleClickBtn={handleVinCheck}
                />
              </>
            </FieldTableForm.Row>
            <FieldTableForm.Row title="소유자 주소" hasErr={!!errors.addr}>
              <FormInput
                css={S.input}
                placeholder="자동차 등록지에 기재된 주소지를 입력하세요"
                maxLength={250}
                register={register("addr", { required: true })}
              />
            </FieldTableForm.Row>
          </>
        )}
        <FieldTableForm.Row title="주행거리" disabled>
          {data.mileage}
        </FieldTableForm.Row>
        <FieldTableForm.Row
          css={S.row}
          title="처리"
          hasErr={
            !!errors.confirm || (isFormStatusReject && !!errors.failedReason)
          }
        >
          <FormRadioBtn
            css={S.formRadioBtn}
            radioList={[
              { key: "approve", label: "승인" },
              { key: "reject", label: "반려" },
            ]}
            register={register("confirm", {
              required: true,
              onChange: () => {
                if (watch("confirm") === "approve") {
                  clearErrors("failedReason");
                } else {
                  clearErrors("modelName");
                  clearErrors("modelYear");
                  clearErrors("vin");
                  clearErrors("addr");
                }
              },
            })}
          />
          {isFormStatusReject && (
            <Textarea
              css={S.textarea}
              placeholder="반려사유를 작성해주세요"
              disabled={data.status === "반려"}
              textValue={watch("failedReason")}
              maxLength={200}
              register={register("failedReason", {
                required: true,
                validate: (value) =>
                  value.trim().length > 0 || HOOKFORM_ERR_OBJ.REQUIRED.message,
              })}
            />
          )}
        </FieldTableForm.Row>
      </FieldTableForm>
      <Button
        css={S.button}
        type="submit"
        variant="primary"
        label="완료"
        isDisabled={!!Object.keys(errors).length}
      />
    </form>
  );
};

export default TruckDetailTable;
