import React from "react";
import { map } from "lodash-es";

import { Status, DataSection } from "components";
import { useGetRepairshopSupports } from "services";
import { REPAIRSHOP_SUPPORT_TABLE_HEADER } from "assets";
import { useFilterParams } from "hooks";
import { keys } from "utils/helper/table";
import type { GetRepairshopSupportsQueryModel } from "types";
import * as S from "./RepairshopSupportTable.styled";

const RepairshopSupportTable = () => {
  const { filters } = useFilterParams<GetRepairshopSupportsQueryModel["query"]>(
    ["category", "status", "page"],
  );

  const { data, refetch, dataUpdatedAt } = useGetRepairshopSupports({
    query: filters,
  });

  return (
    <DataSection
      totalData={data?.pageInfo.totalData!}
      refetch={refetch}
      dataUpdatedAt={dataUpdatedAt}
    >
      <S.RepairshopTable
        title="정비소 1:1 문의"
        columnTable={REPAIRSHOP_SUPPORT_TABLE_HEADER}
      >
        {map(data?.helps, (rowData) => (
          <DataSection.Table.SelectRowMovePage
            key={rowData.detailId}
            id={rowData.detailId}
            path={`/support/repairshop/${rowData.detailId}`}
          >
            {map(keys(REPAIRSHOP_SUPPORT_TABLE_HEADER), (key) => (
              <DataSection.Table.Td key={key}>
                {key === "askStatus" ? (
                  <Status status={rowData[key]} />
                ) : (
                  rowData[key]
                )}
              </DataSection.Table.Td>
            ))}
          </DataSection.Table.SelectRowMovePage>
        ))}
      </S.RepairshopTable>
    </DataSection>
  );
};

export default RepairshopSupportTable;
