import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  FieldTableForm,
  FormRadioBtn,
  GhostBtn,
  TruckDeleteModal,
  TruckNotDeleteModal,
} from "components";
import { useModal, useToast } from "hooks";
import { useDeleteTruck } from "services";
import { TOAST_MSG } from "assets";
import type { TruckDetailClientModel } from "types";
import * as S from "./TruckDetailCompleteTable.styled";

interface TruckDetaiCompleteTablePropsProps {
  data: TruckDetailClientModel;
}

const TruckDetaiCompleteTableProps = ({
  data,
}: TruckDetaiCompleteTablePropsProps) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { register, reset } = useForm();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const { addToast } = useToast();

  const { mutate: deleteTruck } = useDeleteTruck();

  const isRegistered = data.status === "등록완료";

  const handleClickDeleteBtn = () => {
    const deleteFn = () => {
      handleModalClose();

      deleteTruck(id!, {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.TRUCK_DELETE);
          navigate("/truck");
        },
        onError: (error) => {
          if (error.response?.data.code === "ONGOING_RESERVATION_EXISTS") {
            const closeFn = () => {
              handleModalClose();
            };

            handleModalOpen(
              <TruckNotDeleteModal ref={modalRef} handleDelete={closeFn} />,
            )();
          } else {
            addToast(TOAST_MSG.WARNING.FAIL);
          }
        },
      });
    };

    handleModalOpen(
      <TruckDeleteModal ref={modalRef} handleDelete={deleteFn} />,
    )();
  };

  useEffect(() => {
    if (!data) return;

    reset({
      confirm:
        data.status === "반려"
          ? "reject"
          : data.status === "등록완료"
          ? "approve"
          : null,
    });
  }, [data]);

  return (
    <div>
      <FieldTableForm css={S.fieldTableForm} caption="차량 정보">
        <FieldTableForm.Row title="차주명" disabled>
          {data.userName}
        </FieldTableForm.Row>
        <FieldTableForm.Row title="차량번호">{data.regNum}</FieldTableForm.Row>
        <FieldTableForm.Row title="차량브랜드">
          {data.brandTitle}
        </FieldTableForm.Row>
        {isRegistered && (
          <>
            <FieldTableForm.Row title="차명">
              {data.modelName}
            </FieldTableForm.Row>
            <FieldTableForm.Row title="모델연도">
              {data.modelYear}
            </FieldTableForm.Row>
            <FieldTableForm.Row title="차대번호">{data.vin}</FieldTableForm.Row>
            <FieldTableForm.Row title="소유자 주소">
              {data.addr}
            </FieldTableForm.Row>
          </>
        )}
        <FieldTableForm.Row title="주행거리" disabled>
          {data.mileage}
        </FieldTableForm.Row>
        {isRegistered && (
          <FieldTableForm.Row css={S.formMargin} title="등록일시">
            {data.updated}
          </FieldTableForm.Row>
        )}
        <FieldTableForm.Row
          css={(theme) => S.radioContent(theme, isRegistered)}
          title="처리"
        >
          <FormRadioBtn
            css={S.formRadioBtn}
            disabled={data.status === "반려" || data.status === "등록완료"}
            radioList={[
              { key: "approve", label: "승인" },
              { key: "reject", label: "반려" },
            ]}
            register={register("confirm")}
          />
          {data.status === "반려" && (
            <S.Textarea>{data.failedReason}</S.Textarea>
          )}
        </FieldTableForm.Row>
      </FieldTableForm>
      {isRegistered && (
        <GhostBtn
          css={S.deleteBtn}
          variant="ghost"
          label="해당 차량을 삭제하시겠습니까?"
          handleClickBtn={handleClickDeleteBtn}
        />
      )}
    </div>
  );
};

export default TruckDetaiCompleteTableProps;
