import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const labelContent = (theme: Theme) => css`
  & + & {
    margin-bottom: 41px;
  }

  & > div:nth-of-type(1),
  & > div:nth-of-type(3) {
    margin-bottom: 14px;
  }

  & > div:nth-of-type(2),
  & > div:nth-of-type(4) {
    border-top: 1px solid ${theme.color.gray_20};
  }

  & > div:last-of-type {
    margin-bottom: 40px;
  }
`;

export const division = css`
  margin-bottom: 40px;
`;

export const content = css`
  min-height: 88px;
  align-items: flex-start;
`;

export const LinkButton = styled.button`
  ${({ theme }) => css`
    font: ${theme.font.regular_14};
    color: ${theme.color.blue_10};
    text-decoration-line: underline;
  `}
`;
