import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { REPAIRSHOP_TABLE_GRID_COLUMNS } from "assets";
import { DataSection } from "components";

export const RepairshopTable = styled(DataSection.Table)`
  ${({ theme }) => css`
    height: calc(
      100vh - ${theme.size.TABLE_WRAPPER_EXCEPT_FILTER} -
        ${theme.size.TABLE_WRAPPER_THREE_FILTER}
    );

    th {
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(5) {
        text-align: end;
      }
    }
    tr {
      grid-template-columns: ${REPAIRSHOP_TABLE_GRID_COLUMNS};
      td {
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          text-align: end;
        }
      }
    }
  `}
`;
