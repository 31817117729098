import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 528px;
  padding-top: 100px;
`;

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 528px;
    border: 1px solid ${theme.color.gray_30};
    padding: 64px;
    background-color: ${theme.color.white};
  `}
`;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const submitBtn = (theme: Theme) => css`
  height: 52px;
  margin-top: 16px;
  font: ${theme.font.medium_16};

  &: disabled {
    margin-top: 40px;
  }
`;

export const loginLogo = css`
  width: 228px;
  margin-bottom: 40px;
`;
