import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getNoticeSupportListAPI,
  getNoticeSupportDetailAPI,
  createNoticeSupportAPI,
  editNoticeSupportAPI,
  deleteNoticeSupportAPI,
  bulkNoticeAPI,
} from "api";
import { makeTableRowNumber, utcTimeToLocalTime } from "utils";
import { NOTICE_SUPPORT_TYPE } from "assets";
import type {
  DeleteNoticeSupportQueryModel,
  GetNoticeSupportsQueryModel,
  CreateNoticeQueryModel,
  EditNoticeQueryModel,
  PostNoticeBulkQueryModel,
  APIError,
  SuccessNoticeClientModel,
} from "types";

const noticeSupportKeys = {
  all: ["noticeSupport"],
  lists: () => [...noticeSupportKeys.all, "list"],
  list: (filters: GetNoticeSupportsQueryModel) => [
    ...noticeSupportKeys.lists(),
    { filters },
  ],
  details: () => [...noticeSupportKeys.all, "detail"],
  detail: (id: string) => [...noticeSupportKeys.details(), id],
};

export const useGetNoticeSupports = (req: GetNoticeSupportsQueryModel) => {
  return useQuery({
    queryKey: noticeSupportKeys.list(req),
    queryFn: () => getNoticeSupportListAPI(req),
    select: (res) => ({
      pageInfo: res.pageInfo,
      notices: res.notices.map((notice, i) => ({
        noticeId: notice.noticeId,
        type: NOTICE_SUPPORT_TYPE[notice.type],
        idx: makeTableRowNumber(
          res.pageInfo.totalData,
          res.pageInfo.startRow,
          i,
        ),
        fixed: notice.fixed,
        title: notice.title,
        created: utcTimeToLocalTime(notice.created) as string,
      })),
    }),
    suspense: true,
    keepPreviousData: true,
  });
};

export const useBulkNoticeSupport = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PostNoticeBulkQueryModel>({
    mutationFn: (req) => bulkNoticeAPI(req),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: noticeSupportKeys.lists(),
      });
    },
  });
};

export const useGetNoticeSupportDetail = (req: string) => {
  return useQuery({
    queryKey: noticeSupportKeys.detail(req),
    queryFn: () => getNoticeSupportDetailAPI(req),
    select: (res) => ({
      noticeId: res.noticeId,
      type: NOTICE_SUPPORT_TYPE[res.type] + "용",
      title: res.title,
      content: res.content,
      created: utcTimeToLocalTime(res.created),
      updated: res.updated ? utcTimeToLocalTime(res.updated) : "-",
    }),
    suspense: true,
  });
};

export const useCreateNoticeSupport = () => {
  return useMutation<
    SuccessNoticeClientModel,
    APIError,
    CreateNoticeQueryModel
  >({
    mutationFn: (req: CreateNoticeQueryModel) => createNoticeSupportAPI(req),
  });
};

export const useEditNoticeSupport = () => {
  const queryClient = useQueryClient();

  return useMutation<SuccessNoticeClientModel, APIError, EditNoticeQueryModel>({
    mutationFn: (req) => editNoticeSupportAPI(req),
    onSuccess: (_, req) => {
      queryClient.invalidateQueries(noticeSupportKeys.detail(req.id));
    },
  });
};

export const useDeleteNoticeSupport = () => {
  return useMutation<unknown, APIError, DeleteNoticeSupportQueryModel>({
    mutationFn: (req) => deleteNoticeSupportAPI(req),
  });
};
