import React from "react";

import {
  PageLayout,
  NoticeSupportDetailBtns,
  NoticeSupportDetailTable,
} from "components";
import { USER_NOTICE_SUPPORT_DETAIL_PAGE_LAYOUT } from "assets";
import * as S from "./UserNoticeSupportDetail.styled";

const UserNoticeSupportDetail = () => {
  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={USER_NOTICE_SUPPORT_DETAIL_PAGE_LAYOUT}
    >
      <NoticeSupportDetailTable />
      <NoticeSupportDetailBtns />
    </PageLayout>
  );
};

export default UserNoticeSupportDetail;
