import React from "react";

import { AccountInput, ErrorMsg, Button } from "components";
import { EMAIL_REG } from "utils";
import useUnrestrictLoginForm from "./hooks/useUnrestrictLoginForm";
import * as S from "./UnrestrictLogin.styled";

const UnrestrictLogin = () => {
  const { disabledBtn, setDisabledBtn, register, watch, errors, handleSubmit } =
    useUnrestrictLoginForm();

  return (
    <S.Root>
      <S.Form onSubmit={handleSubmit}>
        <S.Title>로그인 제한 해제</S.Title>
        <S.P>
          로그인 제한 해제를 원하시는 계정의 이메일 주소를 입력하시고 <br />
          인증 링크에서 비밀번호를 재설정하세요.
        </S.P>
        <S.InputWrapper isError={!!errors.email}>
          <AccountInput
            value={watch("email")}
            label="이메일 주소"
            id="email"
            maxLength={250}
            isError={!!errors.email}
            register={register("email", {
              required: "이메일 주소를 입력해주세요",
              pattern: {
                value: EMAIL_REG,
                message: "올바른 형식의 이메일주소를 입력하세요",
              },
              onChange: () => setDisabledBtn(false),
            })}
          />
          {errors?.email?.message && (
            <ErrorMsg errorMsg={errors?.email?.message} />
          )}
        </S.InputWrapper>
        <Button
          css={S.submitBtn}
          variant="primary"
          type="submit"
          isDisabled={Object.keys(errors).length !== 0 || disabledBtn}
          label="인증 링크 전송"
        />
      </S.Form>
    </S.Root>
  );
};

export default UnrestrictLogin;
