import React from "react";

import {
  Filter,
  ListPageLayout,
  StaticsPagination,
  StatisticsTable,
} from "components";
import { STATISTICS_FILTER_CONDITIONS } from "assets";

const Statistics = () => {
  return (
    <ListPageLayout heading="지표 관리">
      <Filter filterConditions={STATISTICS_FILTER_CONDITIONS} />
      <StatisticsTable />
      <StaticsPagination />
    </ListPageLayout>
  );
};

export default Statistics;
