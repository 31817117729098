export const WEEKS = ["일", "월", "화", "수", "목", "금", "토"];

export const MONTH = {
  "1월": 1,
  "2월": 2,
  "3월": 3,
  "4월": 4,
  "5월": 5,
  "6월": 6,
  "7월": 7,
  "8월": 8,
  "9월": 9,
  "10월": 10,
  "11월": 11,
  "12월": 12,
};
