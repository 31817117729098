import { css, Theme } from "@emotion/react";
import styled from "@emotion/styled";

export const ErrorContent = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: 4px;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const errorMsg = (theme: Theme) => css`
  color: ${theme.color.red_20};
`;
