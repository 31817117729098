import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const AnswerInput = styled.input`
  ${({ theme }) => css`
    width: 100%;
    border: 0;
    font: ${theme.font.regular_14};

    :disabled {
      cursor: not-allowed;
    }

    ::placeholder {
      color: ${theme.color.gray_50};
    }
  `}
`;
