import React from "react";

import { EmptyStarIcon, StarIcon } from "assets/icon/index";

interface StarAverageProps {
  totalScore: number;
  score: number;
}

const StarAverage = ({ totalScore, score }: StarAverageProps) => {
  const tempArr = new Array(totalScore).fill(null);

  const starArr = tempArr.map((_, i) => {
    if (score > i) {
      return "star";
    } else {
      return null;
    }
  });

  return (
    <>
      {starArr.map((star, i) => {
        if (star === "star") {
          return <StarIcon key={i} />;
        } else {
          return <EmptyStarIcon key={i} />;
        }
      })}
    </>
  );
};

export default StarAverage;
