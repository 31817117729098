import React from "react";
import { useParams } from "react-router-dom";

import { PageLayout, Bulletin, BulletinForm, Report } from "components";
import { useGetBulletinDetail } from "services";
import {
  BULLETIN_REPORT_DETAIL,
  BULLETIN_REPORT_DETAIL_PAGE_LAYOUT,
} from "assets";
import * as S from "./BulletinReportDetail.styled";

const BulletinReportDetail = () => {
  const { id } = useParams();

  const { data } = useGetBulletinDetail(id!);

  if (!data) return null;

  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={BULLETIN_REPORT_DETAIL_PAGE_LAYOUT}
    >
      <Report data={data} contents={BULLETIN_REPORT_DETAIL} />
      <Bulletin data={data} />
      <BulletinForm data={data} />
    </PageLayout>
  );
};

export default BulletinReportDetail;
