export const size = {
  HEADER_HEIGHT: "54px",
  FOOTER_HEIGHT: "60px",
  HEADER_FOOTER_MIN_WIDTH: "1210px",
  NAV_FOLD_WIDTH: "68px",
  NAV_UNFOLD_WIDTH: "230px",
  DETAIL_WIDTH: "1110px",
  LAYOUT_PADDING: "50px",
  TABLE_CELL_HEIGHT: "40px",
  TABLE_HEADER_HEIGHT: "53px",
  SECTION_MIN_WIDTH: "1296px",
  SECTION_MAX_WIDTH: "1660px",
  NAV_WIDTH: "64px",
  MAIN_WIDTH_PADDING: "40px",
  TABLE_WRAPPER_EXCEPT_FILTER: "320px",
  TABLE_WRAPPER_ONE_FILTER: "90px",
  TABLE_WRAPPER_TWO_FILTER: "132px",
  TABLE_WRAPPER_THREE_FILTER: "188px",
  TABLE_WRAPPER_TAB: "74px",
  TABLE_FILTER_RESULT: "52px",
};

const zIndex = {
  TABLE_SELECT_ROW: 5,
  MAIN: 10,
  CHECKBOX: 10,
  TABLE_ROW_BTN: 10,
  STICKY: 50,
  CALENDAR: 51,
  DROPDOWN: 51,
  NAV: 100,
  HEADER_FOOTER: 1000,
  MODAL: 10000,
  TOAST: 10000,
};

export const color = {
  white: "#FFFFFF",
  white10_10: "rgba(255, 255, 255, 0.1)",
  black: "#191F28",
  err: "#E50000",
  header: "#F7F8F9",
  border: "#D6D6D6",
  point: "#006CFF",
  gnb_sub: "#939393",
  disabled_box: "#F4F4F4",
  text01: "#2B2B2B",
  text02: "#0A0A0A",
  text_sub00: "#696969",
  text_sub01: "#888888",
  text_sub02: "#B7B7B7",
  toast_msg: "#636E80",
  gray_B7: "#EDF0F4",
  gray_10: "#F7F8F9",
  gray_20: "#E7EAED",
  gray_30: "#D9DEE2",
  gray_40: "#C1C9CF",
  gray_50: "#A2ABB0",
  gray_60: "#87929A",
  gray_70: "#6F7A83",
  gray_80: "#4A5761",
  blue_sub: "#F3F8FF",
  blue_10: "#3389FF",
  blue_10_10: "#EBF3FF",
  blue_10_20: "rgba(51, 137, 255, 0.2)",
  blue_10_30: "rgba(51, 137, 255, 0.3)",
  blue_10_40: "rgba(51, 137, 255, 0.4)",
  blue_20: "#2E78DE",
  blue_30: "#1A5EBB",
  red_10: "#FFDDDD",
  red_10_10: "rgba(255, 106, 106, 0.1)",
  red_20: "#FF6A6A",
  red_30: "#F34F4F",
  green_10: "#E6F9F2",
  green_20: "#02C57E",
  orange_10: "#FEF0DC",
  orange_20: "#F59C15",
  nav_icon_unactive: "#A2ABB0",
  nav_icon_active: "#FFFFFF",
};

export const font = {
  regular_11: "400 1.1rem/1.45454 'NotoSans KR'",
  regular_12: "400 1.2rem/1.33333 'NotoSans KR'",
  regular_13: "400 1.3rem/1.38461 'NotoSans KR'",
  regular_14: "400 1.4rem/1.42857 'NotoSans KR'",
  regular_15: "400 1.5rem/1.46666 'NotoSans KR'",
  medium_13: "500 1.3rem/1.38461 'NotoSans KR'",
  medium_14: "500 1.4rem/1.42857 'NotoSans KR'",
  medium_15: "500 1.5rem/1.46666 'NotoSans KR'",
  medium_16: "500 1.6rem/1.5 'NotoSans KR'",
  medium_18: "500 1.8rem/1.55555 'NotoSans KR'",
  bold_15: "700 1.5rem/1.46666 'NotoSans KR'",
  bold_18: "700 1.8rem/1.55555 'NotoSans KR'",
  bold_24: "700 2.4rem/1.5 'NotoSans KR'",
};

export const boxShadow = {
  shadow_regular: "0px 0px 16px 0 rgba(25, 31, 40, 0.08)",
  shadow_medium: "0px 0px 16px 0 rgba(25, 31, 40, 0.12)",
  shadow_bold: "0px 0px 16px 0 rgba(25, 31, 40, 0.2)",
};

export const theme = { size, zIndex, color, font, boxShadow };
