import React, { Suspense } from "react";

import { Filter, PaginationSkeleton } from "components";
import { REPORT_BULLETIN_FILTER_CONDITIONS } from "assets";
import {
  BulletinReportTable,
  BulletinReportTableSkeleton,
  BulletinPagination,
} from "./containers";

const BulletinReport = () => {
  return (
    <>
      <Filter filterConditions={REPORT_BULLETIN_FILTER_CONDITIONS} />
      <Suspense fallback={<BulletinReportTableSkeleton />}>
        <BulletinReportTable />
      </Suspense>
      <Suspense fallback={<PaginationSkeleton />}>
        <BulletinPagination />
      </Suspense>
    </>
  );
};

export default BulletinReport;
