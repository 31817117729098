import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { LabelContent, ImgPreviewLinks, Division } from "components";
import { useToast } from "hooks";
import { TOAST_MSG } from "assets";
import type { GetRepairshopSupportDetailItem } from "types";
import * as S from "./RepairshopSupportDetailTable.styled";

interface RepairshopSupportDetailTableProps {
  data: GetRepairshopSupportDetailItem;
}

const RepairshopSupportDetailTable = ({
  data,
}: RepairshopSupportDetailTableProps) => {
  const navigate = useNavigate();
  const { addToast } = useToast();

  const handleClickLink = () => {
    if (data.repairShopStatus === "active") {
      navigate(`/repairshop/${data.repairShopId}`);
    } else {
      addToast(TOAST_MSG.WARNING.DELETED_REPAIRSHOP);
    }
  };

  return (
    <>
      <Division css={S.division} />
      <LabelContent css={S.labelContent} heading="문의글 상세" hasBg>
        <LabelContent.ContentWrapper subItemCount={2}>
          <LabelContent.Content heading="문의 유형">
            {data.category}
          </LabelContent.Content>
          <LabelContent.Content heading="등록 일시">
            {data.created}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={2}>
          <LabelContent.Content heading="정비소명">
            <S.LinkButton onClick={handleClickLink}>
              {data.repairShopName}
            </S.LinkButton>
          </LabelContent.Content>
          <LabelContent.Content heading="작성자명">
            {data.name}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={2}>
          <LabelContent.Content heading="이메일 주소">
            {data.email}
          </LabelContent.Content>
          <LabelContent.Content heading="연락처">
            {data.phone}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content heading="제목">
            {data.title}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content css={S.content} heading="문의내용">
            {data.content}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        {!!data.imgUrls.length && (
          <LabelContent.ContentWrapper subItemCount={1}>
            <LabelContent.Content heading="첨부 이미지">
              <ImgPreviewLinks imgUrls={data.imgUrls} />
            </LabelContent.Content>
          </LabelContent.ContentWrapper>
        )}
      </LabelContent>
    </>
  );
};

export default RepairshopSupportDetailTable;
