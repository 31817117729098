import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { TruckVinCheckModal } from "components";
import { useModal, useToast } from "hooks";
import {
  useApprovalTruck,
  useDenyTruck,
  useGetCheckVin,
  useGetTruckBrand,
} from "services";
import { HOOKFORM_ERR_OBJ, TOAST_MSG } from "assets";
import {
  TruckDetailClientModel,
  TruckDetailForm,
  TruckVinServerModel,
} from "types";

interface TruckDetailTableProps {
  data: TruckDetailClientModel;
}

const useTruckDetailForm = ({ data }: TruckDetailTableProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isVinCheck, setIsVinCheck] = useState(false);

  const { addToast } = useToast();
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  const { mutate: approvalTruckMutate } = useApprovalTruck();
  const { mutate: denyTruckMutate } = useDenyTruck();
  const { data: truckBrands } = useGetTruckBrand();
  const truckDropdownOption = truckBrands?.map((brand) => ({
    key: brand.brandId,
    option: brand.title,
  }));

  const {
    watch,
    register,
    reset,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    trigger,
    handleSubmit,
  } = useForm<TruckDetailForm>({
    mode: "all",
    defaultValues: {
      userName: "",
      regNum: "",
      brand: { key: "", option: "" },
      modelName: "",
      modelYear: "",
      vin: "",
      addr: "",
      mileage: "",
      status: "",
      confirm: null,
      failedReason: "",
    },
  });

  const { refetch } = useGetCheckVin(watch("vin"), {
    onSuccess: (res: TruckVinServerModel) => {
      if (!!res.truckId) {
        const handleModalActiveFn = () => {
          navigate(`/truck/${res.truckId}`);
          handleModalClose();
        };

        handleModalOpen(
          <TruckVinCheckModal ref={modalRef} activeFn={handleModalActiveFn} />,
        )();
        setIsVinCheck(false);
      } else {
        setIsVinCheck(true);
        addToast(TOAST_MSG.SUCCESS.TRUCK_IDENTIFICATION_CHECK_OK);
      }
    },
  });

  const handleVinCheck = () => {
    const vinRegex = /^[A-HJ-NPR-Z0-9]{17}$/;

    if (!watch("vin")) {
      trigger("vin");
      addToast(TOAST_MSG.WARNING.TRUCK_IDENTIFICATION_CHECK);
      return;
    }

    if (vinRegex.test(watch("vin"))) {
      refetch();
      clearErrors("vin");
    } else {
      addToast(TOAST_MSG.WARNING.TRUCK_IDENTIFICATION_CHECK_REGEX);
      setError("vin", { message: "올바른 형식의 차대번호를 입력해주세요" });
    }
  };

  const handleSelectBrand = (key: string) => {
    setValue("brand", truckDropdownOption?.find((brand) => brand.key === key)!);
    clearErrors("brand");
  };

  const checkValidateBrand = () => {
    if (watch("brand").key === "") setError("brand", HOOKFORM_ERR_OBJ.REQUIRED);
  };

  const handleClickSubmit = (data: TruckDetailForm) => {
    checkValidateBrand();

    if (!isVinCheck && data.confirm !== "reject") {
      addToast(TOAST_MSG.WARNING.TRUCK_IDENTIFICATION_CHECK);
      return;
    }
    if (data.confirm === "approve") {
      approvalTruckMutate(
        {
          truckId: id!,
          body: {
            addr: data.addr,
            approvalStatus: data.confirm as "approval" | "deny",
            brandId: data.brand.key,
            modelName: data.modelName,
            modelYear: data.modelYear,
            regNum: data.regNum,
            truckId: id!,
            vin: data.vin,
          },
        },
        {
          onSuccess: () => {
            addToast(TOAST_MSG.SUCCESS.TRUCK_APPROVAL);
            navigate("/truck");
          },
          onError: (error) => {
            addToast({
              type: "warning",
              content: error.response?.data.message,
            });
          },
        },
      );
    } else {
      denyTruckMutate(
        {
          truckId: id!,
          body: { failedReason: data.failedReason },
        },
        {
          onSuccess: () => {
            addToast(TOAST_MSG.SUCCESS.TRUCK_REJECT);
            navigate("/truck");
          },
          onError: (error) => {
            addToast({
              type: "warning",
              content: error.response?.data.message,
            });
          },
        },
      );
    }
  };

  const handleSubmiError = () => {
    checkValidateBrand();
  };
  const isFormStatusReject = watch("confirm") === "reject";

  useEffect(() => {
    if (!data) return;

    reset({
      regNum: data.regNum,
      brand: { key: data.brandId, option: data.brandTitle },
      modelName: "",
      modelYear: "",
      vin: "",
      addr: "",
      confirm:
        data.status === "반려" || data.status === "재요청"
          ? "reject"
          : data.status === "등록완료"
          ? "approve"
          : null,
      failedReason: data.failedReason ?? "",
    });
  }, [data]);

  return {
    handleSelectBrand,
    truckDropdownOption,
    isVinCheck,
    setIsVinCheck,
    handleVinCheck,
    isFormStatusReject,
    addToast,
    register,
    watch,
    setValue,
    errors,
    clearErrors,
    handleSubmit: handleSubmit(handleClickSubmit, handleSubmiError),
  };
};

export default useTruckDetailForm;
