import React from "react";

import { ConfirmModal } from "components/common";

interface NoticeSupportDeleteModalProps {
  handleDelete: () => void;
}

const NoticeSupportDeleteModal = React.forwardRef(
  (
    { handleDelete }: NoticeSupportDeleteModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        title="공지 삭제"
        activeBtnName="삭제"
        btnType="alert"
        desc="삭제된 내역은 복구가 불가합니다. 정말 삭제하시겠습니까?"
        activeFn={handleDelete}
      />
    );
  },
);

NoticeSupportDeleteModal.displayName = "NoticeSupportDeleteModal";

export default NoticeSupportDeleteModal;
