import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const pageLayout = css`
  & > h2 {
    margin-bottom: 40px;
  }
`;

export const division = css`
  margin-bottom: 40px;
`;

export const labelContent = css`
  margin-bottom: 40px;
`;

export const content = css`
  align-items: unset;

  & > dt {
    height: 20px;
    padding: 15px 0 0 0;
  }

  & > dd > div {
    width: 488px;
  }
`;

export const inputWrapper = css`
  & > div {
    flex-flow: column;
    align-items: unset;
  }
`;

export const AddressBtn = styled.button<{
  hasValue: boolean;
  hasError: boolean;
}>`
  ${({ theme, hasValue, hasError }) => css`
    width: 488px;
    height: 40px;
    margin-bottom: 8px;
    border: 1px solid ${hasError ? theme.color.red_30 : theme.color.gray_30};
    padding: 10px 13px;
    font: ${theme.font.regular_14};
    color: ${hasValue ? theme.color.black : theme.color.gray_70};
    text-align: left;
  `}
`;

export const dropdown = css`
  width: 488px;
  margin-bottom: 8px;
`;

export const BrandWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const BrandItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  max-width: fit-content;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 2px;
  padding: 10px 53px 10px 13px;
  font-size: 1.4rem;
`;

export const DeleteBtn = styled.button`
  position: absolute;
  right: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`;

export const BtnWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`;
