import React, { useContext, createContext } from "react";

import * as S from "./LabelContent.styled";

interface Props {
  children: React.ReactNode;
  className?: string;
}

interface LabelContentProps extends Props {
  hasBg?: boolean;
  heading?: string;
}

interface ContentWrapper extends Props {
  hasErr?: boolean;
  subItemCount?: number;
}

export interface ContentProps extends Props {
  heading?: string;
  required?: boolean;
}

const Context = createContext({} as any);

const LabelContent = ({
  className,
  children,
  hasBg,
  heading,
}: LabelContentProps) => {
  return (
    <Context.Provider value={{ hasBg }}>
      <S.LabelContent className={className}>
        {heading && <S.Heading>{heading}</S.Heading>}
        {children}
      </S.LabelContent>
    </Context.Provider>
  );
};

LabelContent.AdditionalInfo = function AdditionalInfo({
  className,
  children,
}: Props) {
  return <S.AdditionInfo className={className}>{children}</S.AdditionInfo>;
};

LabelContent.ContentWrapper = function ContentWrapper({
  children,
  className,
  subItemCount,
  hasErr,
}: ContentWrapper) {
  const { hasBg } = useContext(Context);

  return (
    <S.ContentWrapper
      className={className}
      hasBg={hasBg}
      hasErr={hasErr}
      subItemCount={subItemCount}
    >
      {children}
    </S.ContentWrapper>
  );
};

LabelContent.Content = function Content({
  children,
  className,
  heading,
  required,
}: ContentProps) {
  const { hasBg } = useContext(Context);

  return (
    <S.Content className={className} hasBg={hasBg}>
      {heading && (
        <S.SubHeading hasBg={hasBg}>
          {heading}
          {required && <S.Required>*</S.Required>}
        </S.SubHeading>
      )}
      <S.SubContent hasBg={hasBg}>{children}</S.SubContent>
    </S.Content>
  );
};

export default LabelContent;
