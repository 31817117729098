import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ko";

import type { Brand } from "types";

export const utcTimeToLocalTime = (utcTime: string) => {
  return dayjs(utcTime).format("YYYY.MM.DD HH:mm");
};

export const utcTimeToLocalTimeFilter = (utcTime: string) => {
  return dayjs(utcTime).format("YYYY-MM-DDT15:00:00.000Z");
};

export const numberComma = (number: number) => {
  // 숫자에 천단위마다 콤마 찍기
  return number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

export const formatPhoneNum = (phoneNum: string) => {
  const regex = /[^0-9]/g;
  const newPhoneNum = phoneNum
    ?.replace(regex, "")
    ?.replace(
      /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
      "$1-$2-$3",
    )
    ?.replace("--", "-");

  return newPhoneNum;
};

export const phoneNumHyphen = (phoneNum: string) => {
  const firstStar = phoneNum.indexOf("*");
  const lastStar = phoneNum.lastIndexOf("*") + 1;

  return (
    phoneNum.slice(0, firstStar) +
    "-" +
    phoneNum.slice(firstStar, lastStar) +
    "-" +
    phoneNum.slice(lastStar)
  );
};

export const truckNum = (truckNum: string) => {
  const regex = /([ㄱ-ㅎ가-힣]+)(\d+)$/g;
  const newtruckNum = truckNum?.replace(
    regex,
    (match, truckNum1, truckNum2) => `${truckNum1} ${truckNum2}`,
  );

  return newtruckNum;
};

export const phoneNumSubtractMinus = (phoneNum: string) => {
  return (phoneNum + "").replace(/[^0-9]/g, "");
};

export const formatPeriodLocalToUtc = (type: string, localDate: string) => {
  const date = dayjs(localDate, "YYYY/MM/DD");

  switch (type) {
    case "dateStart":
      return date.toISOString();

    case "dateEnd":
      return date.toISOString();
  }
};

export const isNumber = (string: string) => {
  const regExp = /[^0-9]/g; // 숫자가 아닌 문자열을 선택하는 정규식

  return regExp.test(string);
};

export const onlyNumber = (string: string) => {
  if (!string) return "";

  const regExp = /[^0-9]/g; // 숫자가 아닌 문자열을 선택하는 정규식
  // 원래 문자열에서 숫자가 아닌 모든 문자열을 빈 문자로 변경
  return string.replace(regExp, "");
};

export const onlyNumberUpperEnglish = (string: string) => {
  if (!string) return "";

  // 영어와 숫자만 선택하는 정규식
  const regExp = /[^a-zA-Z0-9]/g;

  return string.replace(regExp, "").toUpperCase(); // 영어와 숫자만 대문자로 변경
};

type DateArgs = Dayjs | string | number | Date;

export const formatDate = (
  date: DateArgs,
  template: string = "YYYY/MM/DD, HH:mm",
) => dayjs(date).format(template);

export const formatBirth = (date: string) => {
  const year = date.substr(0, 2);
  const month = date.substr(2, 2);
  const day = date.substr(4, 2);
  return parseInt(year, 10) > 30
    ? `19${year}/${month}/${day}`
    : `20${year}/${month}/${day}`;
};

export const numericOnlyWithColons = (v: string) => {
  // eslint-disable-next-line
  const regex = /[^0-9\:]/g;

  return v.replaceAll(regex, "");
};

export const makeTruckBrands = (
  brands: { brandId: string; title: string }[],
) => {
  return brands.map(({ brandId, title }) => ({
    key: brandId,
    label: title,
  }));
};

export const makeTruckBrandString = (brands: Brand[]) => {
  return brands.reduce(
    (acc, { title }, i) =>
      i === brands.length - 1 ? acc + title : acc + `${title}/`,
    "",
  );
};

export const checkDeletedUser = (user: string | null) =>
  user ?? "탈퇴한 사용자";
