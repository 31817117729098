import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.div`
  display: flex;
`;

export const RadioBtnWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 8px;
    font: ${theme.font.regular_14};
    color: ${theme.color.black};
  `}
`;

export const RadioBtn = styled.input`
  ${({ theme }) => css`
    &[type="radio"] {
      display: none;
    }

    &[type="radio"]:checked + .label {
      background: ${theme.color.blue_10} no-repeat center;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Ccircle cx='9' cy='9' r='8.5' fill='white' stroke='%23D9DEE2' /%3E%3Ccircle cx='9' cy='9' r='5' fill='%233389FF' /%3E%3C/svg%3E%0A");
    }

    &[type="radio"]:disabled + .label {
      background-color: ${theme.color.gray_10};

      :hover {
        border: 1px solid ${theme.color.gray_30};
        cursor: not-allowed;
      }
    }

    &[type="radio"]:checked:disabled + .label {
      background-color: ${theme.color.gray_10};
      background: no-repeat center;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9' cy='9' r='8.5' fill='%23F7F8F9' stroke='%23D9DEE2'/%3E%3Ccircle cx='9' cy='9' r='5' fill='%23C1C9CF'/%3E%3C/svg%3E%0A");

      :hover {
        border: 1px solid ${theme.color.gray_10};
        cursor: not-allowed;
      }
    }
  `}
`;

export const Radio = styled.label`
  ${({ theme }) => css`
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid ${theme.color.gray_30};
    border-radius: 50%;

    :hover {
      border: 1px solid ${theme.color.blue_10};
      cursor: pointer;
    }
  `}
`;

export const Label = styled.label<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    :hover {
      cursor: ${disabled ? "not-allowed" : "pointer"};
    }
  `}
`;
