import React from "react";

import { CheckIcon } from "assets";
import * as S from "./PasswordHint.styled";

interface PasswordHintProps {
  passwordHint: string;
  isValidCheck: boolean;
  isDefault: boolean;
}

const PasswordHint = ({
  passwordHint,
  isDefault,
  isValidCheck,
}: PasswordHintProps) => {
  return (
    <S.PasswordHint isDefault={isDefault} isValidCheck={isValidCheck}>
      <CheckIcon />
      {passwordHint}
    </S.PasswordHint>
  );
};

export default PasswordHint;
