import React from "react";

import * as S from "./ErrorMsg.styled";

interface ErrorMsgProps {
  className?: string;
  errorMsg: string;
}

const ErrorMsg = ({ className, errorMsg }: ErrorMsgProps) => {
  return <S.ErrorMsg className={className}>{errorMsg}</S.ErrorMsg>;
};

export default ErrorMsg;
