import React from "react";

import { QueryPagination } from "components";
import { useGetOwnerSupports } from "services";
import { useFilterParams } from "hooks";
import type { GetOwnerSupportsQueryModel } from "types";

const OwnerSupportPagination = () => {
  const { filters } = useFilterParams<GetOwnerSupportsQueryModel["query"]>([
    "page",
    "category",
    "status",
  ]);

  const { data } = useGetOwnerSupports({
    query: filters,
  });

  return <QueryPagination pageInfo={data?.pageInfo!} />;
};

export default OwnerSupportPagination;
