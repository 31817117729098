import React from "react";

import { PageLayout, RepairshopForm } from "components";
import { useGetTruckBrand } from "services";
import { REPAIRSHOP_CREATE_PAGE_LAYOUT } from "assets";
import { useCreateRepairshopForm } from "./hooks/useCreateRepairshopForm";
import * as S from "./RepairshopCreate.styled";

const RepairshopCreate = () => {
  const { data: truckBrands } = useGetTruckBrand();

  const { register, watch, errors, setValue, clearErrors, handleSubmit } =
    useCreateRepairshopForm();

  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={REPAIRSHOP_CREATE_PAGE_LAYOUT}
    >
      <RepairshopForm
        truckBrands={truckBrands!}
        register={register}
        watch={watch}
        errors={errors}
        setValue={setValue}
        clearErrors={clearErrors}
        handleSubmit={handleSubmit}
      />
    </PageLayout>
  );
};

export default RepairshopCreate;
