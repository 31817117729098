import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const LabelContent = styled.div`
  position: relative;
`;

export const Heading = styled.h3`
  ${({ theme }) => css`
    margin-bottom: 12px;
    font: ${theme.font.medium_13};
    color: ${theme.color.gray_60};
  `}
`;

export const AdditionInfo = styled.span`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    font: ${theme.font.regular_13};
    color: ${theme.color.gray_60};
  `}
`;

interface ContentWrapperProps {
  hasBg: boolean;
  hasErr?: boolean;
  subItemCount?: number;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  ${({ theme, hasBg, hasErr, subItemCount }) => css`
    display: grid;
    grid-template-columns: ${`repeat(${subItemCount}, 1fr)`};
    border-left: ${hasBg && `1px solid ${theme.color.gray_20}`};
    border-right: ${hasBg && `1px solid ${theme.color.gray_20}`};

    & > div {
      border-bottom: ${hasErr
        ? `1px solid ${theme.color.red_20}`
        : hasBg && `1px solid ${theme.color.gray_20}`};
    }

    :first-of-type {
      border-top: ${hasBg && `1px solid ${theme.color.gray_20}`};
    }

    & > div > dt {
      color: ${hasErr && theme.color.red_20};
      background-color: ${hasErr
        ? theme.color.red_10
        : hasBg && theme.color.gray_10};
    }
  `}
`;

interface ContentProps {
  hasBg: boolean;
}

export const Content = styled.div<ContentProps>`
  ${({ theme, hasBg }) => css`
    display: grid;
    grid-template-columns: 210px 1fr;
    align-items: center;
    border-top: ${hasBg && "0"};

    &[data-isfocus="true"] {
      border-bottom: 1px solid ${theme.color.blue_10};

      > dt {
        color: ${theme.color.blue_10};
        background-color: ${theme.color.blue_10_10};
      }
    }
  `}
`;

interface SubHeadingProps {
  hasBg: boolean;
}

export const SubHeading = styled.dt<SubHeadingProps>`
  ${({ theme, hasBg }) => css`
    display: flex;
    align-items: center;
    min-height: 48px;
    height: 100%;
    padding-left: 20px;
    font: ${hasBg ? theme.font.medium_14 : theme.font.regular_14};
    color: ${hasBg ? theme.color.gray_60 : theme.color.black};
    background-color: ${hasBg ? theme.color.gray_10 : "inherits"};
  `}
`;

export const Required = styled.div`
  ${({ theme }) => css`
    margin-left: 4px;
    color: ${theme.color.red_20};
  `}
`;

interface SubContentProps {
  hasBg: boolean;
}

export const SubContent = styled.dd<SubContentProps>`
  ${({ theme, hasBg }) => css`
    display: flex;
    align-items: center;
    padding: 14px 20px;
    font: ${theme.font.regular_14};
    color: ${hasBg ? theme.color.black : theme.color.gray_80};

    & > span {
      font: ${theme.font.regular_14};
    }
  `}
`;

export const SkeletonWrapper = styled.div`
  width: 50%;
  max-width: 100px;
  height: 100%;
`;
