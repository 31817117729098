import React from "react";
import Skeleton from "react-loading-skeleton";

import { LabelContent } from "components";
import type { ReadonlyOverlappingArray } from "types";
import * as S from "./LabelContent.styled";

type InfoPiece = { key: string; heading: string };

interface LabelContentSkeletonProps {
  hasBg?: boolean;
  heading?: string;
  info: ReadonlyOverlappingArray<InfoPiece>;
  className?: string;
}

const LabelContentSkeleton = ({
  hasBg,
  heading,
  info,
  className,
}: LabelContentSkeletonProps) => {
  return (
    <LabelContent hasBg={hasBg} className={className}>
      {heading && <S.Heading>{heading}</S.Heading>}
      {info.map((item, i) => (
        <LabelContent.ContentWrapper subItemCount={item.length} key={i}>
          {item.map(({ key, heading }) => (
            <LabelContent.Content key={key} heading={heading}>
              <S.SkeletonWrapper>
                {key === "profile" ? (
                  <Skeleton circle width="56px" height="56px" />
                ) : (
                  <Skeleton />
                )}
              </S.SkeletonWrapper>
            </LabelContent.Content>
          ))}
        </LabelContent.ContentWrapper>
      ))}
    </LabelContent>
  );
};

export default LabelContentSkeleton;
