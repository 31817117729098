import React from "react";

import * as S from "./ListPageLayout.styled";

interface ListPageLayoutProps {
  className?: string;
  heading: string;
  children: React.ReactNode;
}

const ListPageLayout = ({
  heading,
  children,
  className,
}: ListPageLayoutProps) => {
  return (
    <S.Section>
      <S.Heading className={className}>{heading}</S.Heading>
      {children}
    </S.Section>
  );
};

export default ListPageLayout;
