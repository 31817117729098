import React from "react";

import type { FilterCondition } from "types";
import { useSearchLabel } from "./hooks";
import * as S from "./Filter.styled";
import FilterSearchLabel from "./searchLabel/FilterSearchLabel";
import FilterItem from "./item/FilterItem";

interface FilterProps {
  className?: string;
  filterConditions: FilterCondition[][];
}

const Filter = ({ className, filterConditions }: FilterProps) => {
  const {
    isEmptyLabel,
    filterKeyNames,
    searchLabel,
    handleDeleteSearchLabel,
    handleClickReset,
  } = useSearchLabel(filterConditions);

  return (
    <S.Filter className={className}>
      {filterConditions.map((conditions, i) => (
        <S.ConditionWrapper key={i} columnCount={conditions.length}>
          {conditions.map((condition, j) => (
            <FilterItem key={j} condition={condition} />
          ))}
        </S.ConditionWrapper>
      ))}
      {!isEmptyLabel && (
        <FilterSearchLabel
          filterKeyNames={filterKeyNames}
          searchLabel={searchLabel}
          handleClickReset={handleClickReset}
          handleDeleteSearchLabel={handleDeleteSearchLabel}
        />
      )}
    </S.Filter>
  );
};

export default Filter;
