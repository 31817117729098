import React from "react";

import { Status } from "components";
import { TRUCK_DETAIL_STATUS } from "assets";
import * as S from "./TruckOwnerInfo.styled";

interface TruckOwnerInfoProps {
  className?: string;
  ownerName: string;
  status: (typeof TRUCK_DETAIL_STATUS)[keyof typeof TRUCK_DETAIL_STATUS];
}

const TruckOwnerInfo = ({
  className,
  ownerName,
  status,
}: TruckOwnerInfoProps) => {
  return (
    <S.Wrapper className={className}>
      <S.OwnerName>차주명</S.OwnerName>
      <S.FlexWrapper>
        <S.Name>{ownerName}</S.Name>
        <Status hasBg status={status} />
      </S.FlexWrapper>
    </S.Wrapper>
  );
};

export default TruckOwnerInfo;
