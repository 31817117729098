import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Condition = styled.div`
  display: grid;
  grid-template-columns: 210px 1fr;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.gray_20}`};

  &&[data-isconditionfocus="true"] {
    border-bottom: ${({ theme }) => `1px solid ${theme.color.blue_10}`};
  }

  &&[data-isconditionerror="true"] {
    border-bottom: ${({ theme }) => `1px solid ${theme.color.red_20}`};
  }
`;

export const ConditionName = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: ${({ theme }) => theme.color.gray_60};
  background-color: ${({ theme }) => theme.color.gray_10};
  font: ${({ theme }) => theme.font.medium_14};

  &[data-isconditionfocus="true"] {
    color: ${({ theme }) => theme.color.blue_10};
    background-color: rgba(51, 137, 255, 0.1);
  }

  &[data-isconditionerror="true"] {
    color: ${({ theme }) => theme.color.red_20};
    background-color: rgba(255, 221, 221, 1);
  }
`;

export const ConditionChildrenWapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 7px 0;
`;

export const CalendarWrapper = styled(ConditionChildrenWapper)`
  padding: 0;
`;

export const calendar = css`
  width: 100%;

  & > input {
    padding-left: 20px;
  }
`;

export const FilterListWapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 15px;
  max-width: fit-content;
  padding: 7px 0 7px 20px;
`;

export const FilterListItem = styled.li`
  font: ${({ theme }) => theme.font.regular_14};

  label {
    border-radius: 2px;
    padding: 6px 12px;
    color: ${({ theme }) => theme.color.gray_50};

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    :hover {
      background-color: ${({ theme }) => theme.color.gray_10};
      cursor: pointer;
    }
  }

  input {
    display: none;
  }

  input:checked + label {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.blue_10};
  }
`;

export const FilterAllBtn = styled(FilterListItem)`
  color: ${({ theme }) => theme.color.gray_50};

  :hover {
    background-color: ${({ theme }) => theme.color.gray_10};
    border-radius: 2px;
  }

  input:checked + label {
    color: ${({ theme }) => theme.color.black};
    background-color: ${({ theme }) => theme.color.gray_10};
  }
`;

export const SearchLabelBoxWrapper = styled.div`
  display: flex;
  padding: 10px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray_20};
`;
