import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import * as S from "./AnswerInput.styled";

interface InputProps {
  className?: string;
  register: UseFormRegisterReturn;
  value: string;
  maxLength: number;
}

const AnswerInput = ({ className, value, register, maxLength }: InputProps) => {
  return (
    <S.AnswerInput
      className={className}
      placeholder="제목을 입력하세요."
      value={value}
      maxLength={maxLength}
      {...register}
    />
  );
};

AnswerInput.displayName = "AnswerInput";

export default AnswerInput;
