import { ax } from "api/axios";
import { makeCryptoFunction } from "utils/helper/crypto";
import type {
  UnrestrickLoginQueryModel,
  LoginQueryModel,
  ResetPasswordQueryModel,
} from "types";

export const loginAPI = (req: LoginQueryModel) =>
  ax.patch("/admin/auth/login", {
    email: req.email,
    password: makeCryptoFunction(req.password),
  });

export const logoutAPI = () => ax.patch("/admin/auth/logout");

export const unrestricLoginAPI = (req: UnrestrickLoginQueryModel) =>
  ax.get("/admin/auth/password/reset", { params: req });

export const resetPasswordAPI = (req: ResetPasswordQueryModel) =>
  ax.post("/admin/auth/password/reset", {
    id: req.id,
    auth: req.auth,
    email: req.email,
    password: makeCryptoFunction(req.password),
  });
