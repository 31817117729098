import React from "react";

import { GhostBtn } from "components";
import { useModal } from "hooks";
import * as S from "./ConfirmModal.styled";

interface ConfirmModalProps {
  className?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  btnType?: "active" | "alert";
  title: string;
  desc: string;
  activeBtnName: string;
  closeBtnName?: string;
  activeFn?: () => void;
  closeFn?: () => void;
}

const ConfirmModal = React.forwardRef(
  (
    {
      className,
      children,
      isLoading,
      btnType = "active",
      title,
      desc,
      activeBtnName,
      closeBtnName = "취소",
      activeFn,
      closeFn,
    }: ConfirmModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const { handleModalClose } = useModal();

    return (
      <S.Modal className={className} ref={ref}>
        <S.ConfirmHeader>
          <S.Title>{title}</S.Title>
          <S.Desc>{desc}</S.Desc>
        </S.ConfirmHeader>
        {children && children}
        <S.ConfirmFooter>
          <GhostBtn
            variant="alert"
            label={closeBtnName}
            handleClickBtn={closeFn ?? handleModalClose}
          />
          <GhostBtn
            isLoading={isLoading}
            variant={btnType === "active" ? "alert_blue" : "alert_red"}
            label={activeBtnName}
            handleClickBtn={activeFn}
          />
        </S.ConfirmFooter>
      </S.Modal>
    );
  },
);

ConfirmModal.displayName = "ConfirmModal";

export default ConfirmModal;
