import React from "react";
import { useSearchParams } from "react-router-dom";

import { QueryPagination } from "components";
import { useGetNoticeSupports } from "services";
import { useFilterParams } from "hooks";
import type { GetNoticeSupportsQueryModel } from "types";

const NoticePagination = () => {
  const [searchParams] = useSearchParams();

  const tabQuery = searchParams.get("tab")?.slice(0, -6) as
    | "repairshop"
    | "user";

  const { filters } = useFilterParams<GetNoticeSupportsQueryModel["query"]>([
    "page",
    "type",
    "title",
  ]);

  const { data } = useGetNoticeSupports({
    query: { ...filters, type: tabQuery },
  });

  return <QueryPagination pageInfo={data?.pageInfo!} />;
};

export default NoticePagination;
