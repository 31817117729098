import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";

import { getOwnerExcelDownloadAPI, getOwnerListAPI } from "api";
import { formatBirth, formatPhoneNum, utcTimeToLocalTime } from "utils";
import { OWNER_MARKETING_STATUS } from "assets";
import type { GetOwnerExcelQueryModel, GetOwnerQueryModel } from "types";

const ownerKeys = {
  all: ["reservation"],
  lists: () => [...ownerKeys.all, "list"],
  list: (filters: GetOwnerQueryModel) => [...ownerKeys.lists(), { filters }],
  excels: () => [...ownerKeys.all, "excel"],
  excel: (filters: GetOwnerExcelQueryModel) => [
    ...ownerKeys.excels(),
    { filters },
  ],
};

export const useGetOwner = (req: GetOwnerQueryModel) => {
  return useQuery({
    queryKey: ownerKeys.list(req),
    queryFn: () => getOwnerListAPI(req),
    select: (res) => ({
      pageInfo: res.pageInfo,
      users: res.users?.map((user) => ({
        ...user,
        phone: formatPhoneNum(user.phone),
        gender: +user.gender % 2 ? "남성" : "여성",
        birthdate: formatBirth(user.birthdate),
        marketing: OWNER_MARKETING_STATUS[user.marketing],
        created: utcTimeToLocalTime(user.created),
      })),
    }),
  });
};

export const useGetOwnerExcelDownload = (
  req: GetOwnerExcelQueryModel,
  options: UseQueryOptions<any, unknown, any, QueryKey>,
) => {
  return useQuery({
    queryKey: ["excel"],
    queryFn: () => getOwnerExcelDownloadAPI(req),
    onSuccess: options.onSuccess,
    enabled: false,
  });
};
