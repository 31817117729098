import type { KeyOf } from "types";

export const calcTableWidth = (arr: (string[] | string)[]) => {
  const tableColumnArr = arr.map((designColumnWidth: string | string[]) =>
    typeof designColumnWidth === "string"
      ? designColumnWidth
      : `minmax(${designColumnWidth[0]}, ${designColumnWidth[1]})`,
  );

  return tableColumnArr.join(" ");
};

export const keys = <T extends object>(object: T): KeyOf<T>[] =>
  Object.keys(object) as KeyOf<T>[];

export const makeTableRowNumber = (
  totalData: number,
  startRow: number,
  rowIndex: number,
) => {
  return totalData - startRow - rowIndex;
};
