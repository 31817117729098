import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import * as S from "./Textarea.styled";

interface TextareaProps {
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  maxLength: number;
  textValue: string;
  errorId?: string;
  hasError?: boolean;
  register: UseFormRegisterReturn<string>;
}

const Textarea = ({
  className,
  maxLength,
  placeholder,
  disabled,
  textValue,
  errorId,
  hasError = false,
  register,
}: TextareaProps) => {
  return (
    <S.TextareaWrapper className={className}>
      <S.TextareaContent
        className="autoTextarea"
        rows={1}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        aria-invalid={hasError}
        aria-errormessage={errorId}
        {...register}
      />
      <S.Length>{`(${textValue.length ?? 0}/${maxLength})`}</S.Length>
    </S.TextareaWrapper>
  );
};

export default Textarea;
