import styled from "@emotion/styled";
import { css } from "@emotion/react";
export const PageLayout = styled.section`
  ${({ theme }) => css`
    min-width: ${theme.size.SECTION_MIN_WIDTH};
    max-width: ${theme.size.SECTION_MAX_WIDTH};
  `}
`;

export const Heading = styled.h1`
  ${({ theme }) => css`
    margin-bottom: 12px;
    font: ${theme.font.bold_24};
    color: ${theme.color.black};
  `}
`;

export const SubHeading = styled.h2`
  ${({ theme }) => css`
    font: ${theme.font.regular_14};
    color: ${theme.color.gray_60};
  `}
`;
