import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const ButtonWrapper = styled.div`
  display: flex;
  column-gap: 12px;
`;

export const link = (theme: Theme) =>
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: 0;
    border-radius: 2px;
    padding: 0 18px;
    font: ${theme.font.medium_14};
    color: ${theme.color.white};
    text-decoration: none;
    background-color: ${theme.color.blue_10};

    &:not(:disabled):hover {
      background-color: ${theme.color.blue_20};
    }

    &:disabled {
      opacity: 0.6;
    }
  `;
