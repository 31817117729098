import React from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";

import { Button } from "components";
import { useModal, useToast } from "hooks";
import { TOAST_MSG } from "assets";
import { useDeleteNoticeSupport } from "services";
import * as S from "./NoticeSupportDetailBtns.styled";
import NoticeSupportDeleteModal from "../NoticeSupportDeleteModal";

const NoticeSupportDetailBtns = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { modalRef, handleModalOpen, handleModalClose } = useModal();
  const { addToast } = useToast();

  const { mutate: deleteNoticeSupportMutate } = useDeleteNoticeSupport();

  const handleClickDeleteBtn = () => {
    const deleteFn = () => {
      handleModalClose();

      deleteNoticeSupportMutate(id!, {
        onSuccess: () => {
          const type = pathname.split("/")[2];

          addToast(TOAST_MSG.SUCCESS.NOTICE_DELETE);
          navigate(`/support?tab=${type}`);
        },
        onError: () => {
          addToast(TOAST_MSG.WARNING.FAIL);
        },
      });
    };
    handleModalOpen(
      <NoticeSupportDeleteModal ref={modalRef} handleDelete={deleteFn} />,
    )();
  };

  return (
    <>
      <S.ButtonWrapper>
        <Link
          css={(theme) => S.link(theme)}
          to={
            pathname.includes("userNotice")
              ? `/support/userNotice/edit/${id}`
              : `/support/repairshopNotice/edit/${id}`
          }
        >
          수정
        </Link>
        <Button
          label="삭제"
          variant="error"
          handleClickBtn={handleClickDeleteBtn}
        />
      </S.ButtonWrapper>
    </>
  );
};

export default NoticeSupportDetailBtns;
