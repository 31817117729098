import React from "react";

import { DataSectionSkeleton } from "components";
import {
  OWNER_SUPPORT_TABLE_GRID_COLUMNS,
  OWNER_SUPPORT_TABLE_HEADER,
} from "assets";

const OwnerSupportTableSkeleton = () => {
  return (
    <DataSectionSkeleton>
      <DataSectionSkeleton.Table
        columnTable={OWNER_SUPPORT_TABLE_HEADER}
        gridTemplateColumns={OWNER_SUPPORT_TABLE_GRID_COLUMNS}
      />
    </DataSectionSkeleton>
  );
};

export default OwnerSupportTableSkeleton;
