import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.form`
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 90px;
`;

export const Input = styled.input`
  ${({ theme }) => css`
    width: 100%;
    height: 34px;
    border: 0;
    padding-left: 20px;
    font: ${theme.font.regular_14};

    :focus {
      border: 0;
    }
    ::placeholder {
      color: ${theme.color.gray_50};
    }
  `}
`;

export const ApplyBtn = styled.button`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 28px;
    border-radius: 4px;
    font: ${theme.font.medium_13};
    color: ${theme.color.blue_10};
    background-color: ${theme.color.gray_10};
  `}
`;
