import React from "react";
import { map } from "lodash-es";

import { DataSection, Status } from "components";
import { useGetTrucks } from "services";
import { useFilterParams } from "hooks";
import { keys } from "utils";
import { TRUCK_TABLE_HEADER } from "assets";
import type { GetTrucksQueryModel } from "types";
import * as S from "./TruckTable.styled";

const TruckTable = () => {
  const { filters } = useFilterParams<GetTrucksQueryModel["query"]>([
    "page",
    "status",
    "brandId",
    "regNum",
  ]);

  const { data, refetch, dataUpdatedAt } = useGetTrucks({
    query: filters,
  });

  return (
    <DataSection
      totalData={data?.pageInfo.totalData!}
      refetch={refetch}
      dataUpdatedAt={dataUpdatedAt}
    >
      <S.TruckTable title="차량 리스트" columnTable={TRUCK_TABLE_HEADER}>
        {map(data?.trucks, (rowData) => (
          <DataSection.Table.SelectRowMovePage
            key={rowData.detailId}
            id={rowData.detailId}
            path={`/truck/${rowData.detailId}`}
          >
            {map(keys(TRUCK_TABLE_HEADER), (key) => (
              <DataSection.Table.Td key={key}>
                {key === "status" ? (
                  <Status status={rowData[key]} />
                ) : (
                  rowData[key]
                )}
              </DataSection.Table.Td>
            ))}
          </DataSection.Table.SelectRowMovePage>
        ))}
      </S.TruckTable>
    </DataSection>
  );
};

export default TruckTable;
