import React from "react";

import { ConfirmModal } from "components/common";
import * as S from "./RepairshopNotDeleteModal.styled";

interface ReparishopNotDeleteModalProps {
  handleDelete: () => void;
}

const ReparishopNotDeleteModal = React.forwardRef(
  (
    { handleDelete }: ReparishopNotDeleteModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        css={S.deleteBtn}
        title="회원(정비소) 탈퇴 불가"
        activeBtnName="확인"
        btnType="active"
        desc={"진행중인 정비건이 있어 탈퇴가 불가합니다."}
        activeFn={handleDelete}
      />
    );
  },
);

ReparishopNotDeleteModal.displayName = "ReparishopNotDeleteModal";

export default ReparishopNotDeleteModal;
