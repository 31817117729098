import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { LoginModal } from "components";
import { useLogin } from "services";
import { useModal } from "hooks";

interface LoginForm {
  email: string;
  password: string;
}

const useLoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  const { mutate: loginMutate } = useLogin();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    watch,
    clearErrors,
  } = useForm({
    mode: "all",
    defaultValues: { email: "", password: "", loginError: "" },
  });

  const handleLoginSuccess = (data: LoginForm) => {
    loginMutate(data, {
      onSuccess: () => navigate(location.state?.from ?? "/statistics"),
      onError: (error) => {
        if (!error.response) return;

        const { code: errorCode, message: errorMsg } = error.response?.data;

        if (errorCode === "LOGIN_FAILED") {
          if (errorMsg.includes("&")) {
            const failCount = errorMsg.slice(-1);

            setError("loginError", {
              message: `비밀번호가 일치하지 않습니다. 오류 제한 횟수(${failCount}/5)`,
            });
          } else {
            setError("loginError", {
              message: "이메일 또는 비밀번호가 일치하지 않습니다.",
            });
          }
        }

        if (errorCode === "EXCEED_MAXIMUM_LOGIN_ATTEMPTS") {
          const handleSubmit = () => {
            navigate("/unrestrictLogin");
            handleModalClose();
          };
          handleModalOpen(
            <LoginModal ref={modalRef} submitFn={handleSubmit} />,
          )();
        }
      },
    });
  };

  return {
    register,
    watch,
    errors,
    clearErrors,
    handleSubmit: handleSubmit(handleLoginSuccess),
  };
};

export default useLoginForm;
