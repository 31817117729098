import React from "react";
import { useParams } from "react-router-dom";

import {
  PageLayout,
  RepairshopSupportDetailTable,
  RepairshopSupportForm,
} from "components";
import { useGetRepairshopSupportDetail } from "services";
import { REPAIRSHOP_SUPPORT_DETAIL_PAGE_LAYOUT } from "assets";
import * as S from "./RepairshopSupportDetail.styled";

const RepairshopSupportDetail = () => {
  const { id } = useParams();

  const { data } = useGetRepairshopSupportDetail(id!);

  if (!data) return null;

  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={REPAIRSHOP_SUPPORT_DETAIL_PAGE_LAYOUT}
    >
      <RepairshopSupportDetailTable data={data} />
      <RepairshopSupportForm data={data} />
    </PageLayout>
  );
};

export default RepairshopSupportDetail;
