export const TOAST_MSG = {
  SUCCESS: {
    LOGIN_VERIFICATION_LINK: {
      type: "success",
      content: "인증 링크가 전송되었습니다",
    },
    PASSWORD_RESET_SUCCESS: {
      type: "success",
      content: "비밀번호 재설정이 완료되었습니다\n로그인 페이지로 돌아갑니다",
    },
    PASSWORD_RESET_AFTER: {
      type: "success",
      content: "30일 후 비밀번호 재설정을 다시 요청합니다",
    },
    REPAIRSHOP_CREATE: {
      type: "success",
      content: "정비소가 등록되었습니다",
    },
    REPAIRSHOP_EDIT: {
      type: "success",
      content: "정비소 정보가 수정되었습니다",
    },
    REPAIRSHOP_DELETE: {
      type: "success",
      content: "정비소가 탈퇴되었습니다",
    },
    REPAIRSHOP_DISABLE: {
      type: "success",
      content: "정비소가 비활성화 되었습니다",
    },
    REPAIRSHOP_ABLE: {
      type: "success",
      content: "정비소가 활성화 되었습니다",
    },
    REPAIRSHOP_INAPP: {
      type: "success",
      content: "정비소 예약방법이 '인앱'으로 변경되었습니다",
    },
    REPAIRSHOP_PHONE: {
      type: "success",
      content: "정비소 예약방법이 '전화만 가능'으로 변경되었습니다",
    },
    TRUCK_IDENTIFICATION_CHECK_OK: {
      type: "success",
      content: "차대번호 중복확인을 완료했습니다",
    },
    TRUCK_APPROVAL: {
      type: "success",
      content: "차량 등록을 승인했습니다",
    },
    TRUCK_REJECT: {
      type: "success",
      content: "차량 등록을 반려했습니다",
    },
    TRUCK_DELETE: {
      type: "success",
      content: "차량 삭제가 완료되었습니다.",
    },
    USER_DISABLE: {
      type: "success",
      content: "유저의 커뮤니티 활동이 제한되었습니다.",
    },
    USER_ABLE: {
      type: "success",
      content: "유저의 커뮤니티 활동이 허용되었습니다",
    },
    USER_DISABLE_CHANGE: {
      type: "success",
      content: "유저의 커뮤니티 활동 제한 기간이\n변경되었습니다",
    },
    BULLETIN_DISABLE: {
      type: "success",
      content: "게시글 노출을 차단했습니다",
    },
    BULLETIN_ABLE: {
      type: "success",
      content: "게시글 노출을 허용했습니다",
    },
    COMMENT_DISABLE: {
      type: "success",
      content: "댓글 노출을 차단했습니다",
    },
    COMMENT_ABLE: {
      type: "success",
      content: "댓글 노출을 허용했습니다",
    },
    SUPPORT_ANSWER: {
      type: "success",
      content: "답글이 등록되었습니다",
    },
    NOTICE_CREATE: {
      type: "success",
      content: "공지사항이 등록되었습니다",
    },
    NOTICE_EDIT: {
      type: "success",
      content: "공지사항이 수정되었습니다",
    },
    NOTICE_DELETE: {
      type: "success",
      content: "공지사항이 삭제되었습니다",
    },
    NOTICE_FIXED: {
      type: "success",
      content: "공지가 상단 고정되었습니다",
    },
    NOTICE_UNFIXED: {
      type: "success",
      content: "공지 고정이 해제되었습니다",
    },
    NOTICE_FIX_DELETE: {
      type: "success",
      content: "공지글이 삭제되었습니다",
    },
  },
  WARNING: {
    FAIL: {
      type: "warning",
      content: "서버오류가 발생하였습니다.\n잠시 후 다시 시도해주세요 :)",
    },
    DELETED_REPAIRSHOP: {
      type: "warning",
      content: "탈퇴한 정비소입니다.",
    },
    UNRESTRICTED_LOGIN: {
      type: "warning",
      content:
        "등록되지 않은 이메일 주소입니다.\n입력하신 정보를 다시 확인해주세요:)",
    },
    TRUCK_NUMBER_INCORRECT: {
      type: "warning",
      content:
        "올바른 형식의 차량번호를 입력하세요\n(예. 000가 0000/ 경기00나 0000)",
    },
    TRUCK_IDENTIFICATION_EMPTY: {
      type: "warning",
      content: "17자의 차대번호를 입력하세요",
    },
    TRUCK_IDENTIFICATION_CHECK: {
      type: "warning",
      content: "차대번호 중복확인을 완료해주세요",
    },
    TRUCK_IDENTIFICATION_CHECK_REGEX: {
      type: "warning",
      content: "올바른 형식의 차대번호를 입력해주세요",
    },
    TRUCK_YEAR_INVALID: {
      type: "warning",
      content: "유효하지 않은 연도입니다",
    },
    NOTICE_MAXIMUM_FIXED: {
      type: "warning",
      content: "공지 고정은 최대 5개까지 가능합니다",
    },
    NOTICE_EMPTY: {
      type: "warning",
      content: "내용을 입력하세요",
    },
    TRUCK_MODEL_YEAR_INVALID: {
      type: "warning",
      content: "유효하지 않은 연도입니다",
    },
  },
  ALERT: {
    REPAIRSHOP_INAPP_STOP_ALERT: {
      type: "alert",
      content:
        "인앱 예약을 허용하지 않고\n해당 정비소의 앱 내 예약을 금지합니다",
    },
    REPAIRSHOP_INAPP_ABLE_ALERT: {
      type: "alert",
      content: "인앱 예약을 허용하고\n해당 정비소의 앱 내 예약을 허용합니다",
    },
    REPAIRSHOP_ACTIVE_ALERT: {
      type: "alert",
      content: "정비소를 활성화하고\n앱 리스트에 노출합니다",
    },
    REPAIRSHOP_INACTIVE_ALERT: {
      type: "alert",
      content: "정비소를 비활성화하고\n앱 리스트에 노출하지 않습니다",
    },
    USER_STOP_RESET: {
      type: "alert",
      content:
        "활동 제한 기간이 만료되지 않은 유저를\n제한할 경우 유저의 활동 제한 기간이\n처리일 기준 7일로 재설정됩니다",
    },
    BULLETIN_EXPOSURE_ALERT: {
      type: "alert",
      content:
        "차단 시 해당 게시글과 관련 댓글 모두\n커뮤니티에 노출되지 않으며,\n차단된 게시글은 다시 노출할 수 없습니다",
    },
    COMMENT_EXPOSURE_ALERT: {
      type: "alert",
      content:
        "차단 시 해당 댓글이 게시글에 노출되지 않으며, 차단된 댓글은 다시 노출할 수\n없습니다",
    },
  },
} as const;
