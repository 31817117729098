import React from "react";

import { PageLayout, RepairshopForm } from "components";
import { REPAIRSHOP_EDIT_PAGE_LAYOUT } from "assets";
import { useEditRepairshopForm } from "./hooks/useEditRepairshopForm";
import * as S from "./RepairshopEdit.styled";

const RepairshopEdit = () => {
  const {
    request,
    truckBrands,
    register,
    watch,
    errors,
    setValue,
    clearErrors,
    handleSubmit,
  } = useEditRepairshopForm();

  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={REPAIRSHOP_EDIT_PAGE_LAYOUT}
    >
      <RepairshopForm
        request={request}
        truckBrands={truckBrands!}
        register={register}
        watch={watch}
        errors={errors}
        setValue={setValue}
        clearErrors={clearErrors}
        handleSubmit={handleSubmit}
      />
    </PageLayout>
  );
};

export default RepairshopEdit;
