import React from "react";

import { LabelContent, StarAverage } from "components";
import type { GetReviewReportDetailClientModel } from "types";
import * as S from "./Review.styled";

interface ReviewProps {
  data: GetReviewReportDetailClientModel;
}

const Review = ({ data }: ReviewProps) => {
  return (
    <LabelContent hasBg heading="리뷰 상세">
      <LabelContent.ContentWrapper subItemCount={2}>
        <LabelContent.Content heading="작성자">
          {data.userNickname}
        </LabelContent.Content>
        <LabelContent.Content heading="평점">
          <StarAverage totalScore={5} score={data.rating} />
        </LabelContent.Content>
      </LabelContent.ContentWrapper>
      <LabelContent.ContentWrapper subItemCount={1}>
        <LabelContent.Content css={S.content} heading="리뷰 내용">
          {data.content}
        </LabelContent.Content>
      </LabelContent.ContentWrapper>
      <LabelContent.ContentWrapper subItemCount={2}>
        <LabelContent.Content heading="정비 예약번호">
          {data.reservationNo}
        </LabelContent.Content>
        <LabelContent.Content heading="정비 일시">
          {data.planned}
        </LabelContent.Content>
      </LabelContent.ContentWrapper>
    </LabelContent>
  );
};

export default Review;
