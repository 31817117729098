import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 528px;
  padding-top: 100px;
`;

export const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 528px;
    border: 1px solid ${theme.color.gray_30};
    padding: 64px;
    background-color: ${theme.color.white};
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    margin-bottom: 12px;
    font: ${theme.font.bold_24};
  `}
`;

export const P = styled.p`
  ${({ theme }) => css`
    margin-bottom: 32px;
    font: ${theme.font.regular_13};
    color: ${theme.color.gray_60};
  `}
`;

export const InputWrapper = styled.div<{ isError: boolean }>`
  ${({ isError }) => `
  position: relative;
  margin-bottom: ${isError ? "40px" : "16px"};
  `}
`;

export const submitBtn = (theme: Theme) => css`
  height: 52px;
  width: 400px;
  padding: 15px 0;
  font: ${theme.font.medium_16};
`;
