import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const AnswerTextarea = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
`;

export const Text = styled.textarea`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border: 0;
    font: ${theme.font.regular_14};
    resize: none;

    :disabled {
      cursor: not-allowed;
    }

    ::placeholder {
      color: ${theme.color.gray_50};
    }
  `}
`;

export const Length = styled.span`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    font: ${theme.font.regular_14};
    color: ${theme.color.gray_50};
  `};
`;
