import React from "react";
import { useParams } from "react-router-dom";

import { LabelContent } from "components";
import { useGetRepairshopDetail } from "services";
import {
  REPARISHOP_DETAIL_REGISTRATION_INFO,
  REPARISHOP_DETAIL_SETTING,
} from "assets";
import { makeTruckBrandString } from "utils";
import * as S from "./RepairshopDataTable.styled";

const RepairshopDataTable = () => {
  const { id } = useParams();

  const { data, isSuccess } = useGetRepairshopDetail(id!);

  if (!isSuccess || !data) return null;

  return (
    <>
      <LabelContent css={S.registrationLabelContent} heading="등록정보" hasBg>
        {REPARISHOP_DETAIL_REGISTRATION_INFO.map((row, i) => (
          <LabelContent.ContentWrapper key={i} subItemCount={row.length}>
            {row.map(({ key, heading }) => (
              <LabelContent.Content
                css={S.contentAddr}
                key={key}
                heading={heading}
              >
                {key === "addr" ? (
                  <S.Address>
                    <div>{data?.addr}</div>
                    <div>{data?.addrDetail}</div>
                  </S.Address>
                ) : key === "brand" ? (
                  makeTruckBrandString(data.brands)
                ) : (
                  data[key]
                )}
              </LabelContent.Content>
            ))}
          </LabelContent.ContentWrapper>
        ))}
      </LabelContent>
      <LabelContent css={S.labelContent} heading="설정" hasBg>
        {REPARISHOP_DETAIL_SETTING.map((row, i) => (
          <LabelContent.ContentWrapper key={i} subItemCount={row.length}>
            {row.map(({ key, heading }) => (
              <LabelContent.Content
                key={key}
                heading={heading}
                data-haserr={true}
              >
                {key === "available" ? (
                  <S.Request>
                    {data[key]}
                    <span>(트닥 요청 건수 : {data.requests}건)</span>
                  </S.Request>
                ) : (
                  data[key]
                )}
              </LabelContent.Content>
            ))}
          </LabelContent.ContentWrapper>
        ))}
      </LabelContent>
    </>
  );
};

export default RepairshopDataTable;
