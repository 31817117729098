import { ax } from "api/axios";
import type {
  GetStaticsExcelQueryModel,
  GetStaticsQueryModel,
  GetStaticsServerModel,
} from "types";

export const getStaticListAPI = async (req: GetStaticsQueryModel) => {
  const { data } = await ax.get<GetStaticsServerModel>(
    "/admin/reservations/stats",
    { params: req.query },
  );

  return data;
};

export const getStaticsExcelDownloadAPI = async (
  req: GetStaticsExcelQueryModel,
) => {
  const data = await ax.get("/admin/reservations/stats/excel", {
    params: req.query,
    responseType: "arraybuffer",
  });

  return data;
};
