import React from "react";
import type { UseFormRegisterReturn } from "react-hook-form";

import { ErrorMsg } from "components";
import * as S from "./FormInput.styled";

interface FormInputProps {
  className?: string;
  placeholder: string;
  maxLength?: number;
  disabled?: boolean;
  register: UseFormRegisterReturn<string>;
  hasError?: boolean;
  errorMsg?: string;
}

const FormInput = ({
  className,
  placeholder,
  maxLength,
  disabled,
  register,
  hasError,
  errorMsg,
}: FormInputProps) => {
  return (
    <S.FormInput className={className}>
      <S.Input
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        hasError={hasError}
        {...register}
      />
      {errorMsg && <ErrorMsg css={S.errorMsg} errorMsg={errorMsg} />}
    </S.FormInput>
  );
};

export default FormInput;
