import React from "react";

import { DataSectionSkeleton } from "components";
import { REPAIRSHOP_TABLE_GRID_COLUMNS, REPAIRSHOP_TABLE_HEADER } from "assets";

const RepairshopTableSkeleton = () => {
  return (
    <DataSectionSkeleton>
      <DataSectionSkeleton.Table
        columnTable={REPAIRSHOP_TABLE_HEADER}
        gridTemplateColumns={REPAIRSHOP_TABLE_GRID_COLUMNS}
      />
    </DataSectionSkeleton>
  );
};

export default RepairshopTableSkeleton;
