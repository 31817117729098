export const APPROVAL_STATUS = {
  approval: "승인",
  deny: "반려",
} as const;

export const REPAIRSHOP_RADIO_STATUS = {
  active: "활성화",
  inactive: "비활성화",
} as const;

export const AVAILABLE_STATUS = {
  y: "인앱",
  n: "전화만가능",
} as const;

export const ASK_TYPE = {
  repairshop: "정비소FAQ",
  user: "차주FAQ",
} as const;

export const ASK_REPAIRSHOP_CATEGORY = {
  서비스이용: "서비스이용",
  기타: "기타",
} as const;

export const ASK_USER_CATEGORY = {
  서비스이용: "서비스이용",
  증빙서류제출: "증빙서류제출",
  기타: "기타",
} as const;

export const REPORT_COMFIRM = {
  active: "허용",
  blocked: "정지",
} as const;

export const BULLETIN_EXPOSURE = {
  active: "허용",
  blocked: "정지",
} as const;

export const COMMENT_EXPOSURE = {
  active: "허용",
  blocked: "차단",
} as const;

export const NOTICE_TYPE = {
  repairshop: "정비소 WEB",
  user: "차주 APP",
} as const;

export const RADIO_EXPOSURE = [
  { key: "sustained", label: "허용" },
  { key: "blocked", label: "차단" },
] as const;
