import styled from "@emotion/styled";
import { css } from "@emotion/react";
export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 400px;
    height: 70px;
    padding: 12px 20px;
    background-color: ${theme.color.gray_10};
  `}
`;

export const OwnerName = styled.span`
  ${({ theme }) => css`
    display: block;
    margin-bottom: 4px;
    font: ${theme.font.regular_13};
    color: ${theme.color.gray_50};
  `}
`;

export const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Name = styled.span`
  ${({ theme }) => css`
    font: ${theme.font.regular_15};
  `}
`;
