import emotionReset from "emotion-reset";
import { css } from "@emotion/react";

export const globalStyle = css`
  ${emotionReset}
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html,
  body {
    height: 100%;
  }
  body {
    background-color: "#F4F4F4";
  }
  html {
    font-size: 62.5%; // 10px
  }

  #root {
    width: 100%;
    height: 100%;
    font-family: "Spoqa Han Sans Neo", "sans-serif";
  }
  body,
  input,
  textarea,
  button {
    font-family: "Spoqa Han Sans Neo", "sans-serif";
  }
  button {
    /* outline: none; */
    border: none;
    cursor: pointer;
    background-color: inherit;
  }
  input,
  button,
  textarea {
    font-size: 62.5%; // 10px
  }
  input,
  button,
  textarea {
    font-family: "Spoqa Han Sans Neo", "sans-serif";
  }

  input {
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  .a11y {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
`;
