import React from "react";
import { NavLink } from "react-router-dom";

import { NAV_LIST } from "assets/static/navLink";
import { theme } from "style/theme";
import * as S from "./Navbar.styled";

const Navbar = () => {
  return (
    <S.Navbar>
      {NAV_LIST.map(({ path, Icon, content }, i) => (
        <NavLink css={S.navLink(theme, content)} key={i} to={path}>
          <Icon />
        </NavLink>
      ))}
    </S.Navbar>
  );
};

export default Navbar;
