export const REPAIRSHOP_AVAILABLE_STATUS = {
  y: "가능",
  n: "불가",
} as const;

export const REPAIRSHOP_ACTIVE_STATUS = {
  active: "활성화",
  inactive: "비활성화",
  preparing: "준비중",
} as const;
