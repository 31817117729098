import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getUserReportListAPI,
  getUserReportDetailAPI,
  userActivityBlockedAPI,
} from "api";
import { checkDeletedUser, numberComma, utcTimeToLocalTime } from "utils";
import { USER_REPORT_ACTIVE_STATUS, USER_REPORT_ACTIVE_TYPE } from "assets";
import type {
  GetUserReportsQueryModel,
  PatchUserReportQueryModel,
  APIError,
} from "types";

const userKeys = {
  all: ["user"],
  reports: () => [...userKeys.all, "list"],
  report: (filters: GetUserReportsQueryModel) => [
    ...userKeys.reports(),
    { filters },
  ],
  details: () => [...userKeys.all, "detail"],
  detail: (id: string) => [...userKeys.details(), id],
};

export const useGetUsers = (req: GetUserReportsQueryModel) => {
  return useQuery({
    queryKey: userKeys.report(req),
    queryFn: () => getUserReportListAPI(req),
    select: (res) => ({
      pageInfo: res.pageInfo,
      reports: res.reports.map((report) => ({
        checked: report.checked ? ("확인" as const) : ("미확인" as const),
        created: utcTimeToLocalTime(report.created),
        reason: report.reason,
        reportCount: report.reportCount,
        status: USER_REPORT_ACTIVE_TYPE[report.status],
        detailId: report.reportId,
        targetId: report.targetId,
        targetNickname: report.targetNickname ?? "-",
        userId: report.userId,
        userNickname: checkDeletedUser(report.userNickname),
      })),
    }),
    suspense: true,
  });
};

export const useGetUserDetail = (req: string) => {
  return useQuery({
    queryKey: userKeys.detail(req),
    queryFn: () => getUserReportDetailAPI(req),
    select: (res) => ({
      blockedEnd: res.blockedEnd && utcTimeToLocalTime(res.blockedEnd),
      blockedStart: res.blockedStart && utcTimeToLocalTime(res.blockedStart),
      checked: res.checked ? utcTimeToLocalTime(res.checked) : "-",
      created: utcTimeToLocalTime(res.created),
      updated: res.updated && utcTimeToLocalTime(res.updated),
      reason: res.reason,
      reportCount: `${numberComma(+res.reportCount)} 건`,
      reportId: res.reportId,
      status: USER_REPORT_ACTIVE_TYPE[res.status],
      targetId: res.targetId,
      targetNickname: res.targetNickname ?? "-",
      targetStatus: USER_REPORT_ACTIVE_STATUS[res.targetStatus],
      userId: res.userId,
      userNickname: checkDeletedUser(res.userNickname),
    }),
    suspense: true,
  });
};

export const useBlockUser = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, APIError, PatchUserReportQueryModel>({
    mutationFn: (req) => userActivityBlockedAPI(req),
    onSuccess: (_, { query }) => {
      queryClient.invalidateQueries(userKeys.detail(query.reportId));
    },
  });
};
