import React from "react";

import { DataSectionSkeleton } from "components";
import {
  REVIEW_REPORT_TABLE_GRID_COLUMNS,
  REVIEW_REPORT_TABLE_HEADER,
} from "assets";

const ReviewReportTableSkeleton = () => {
  return (
    <DataSectionSkeleton>
      <DataSectionSkeleton.Table
        columnTable={REVIEW_REPORT_TABLE_HEADER}
        gridTemplateColumns={REVIEW_REPORT_TABLE_GRID_COLUMNS}
      />
    </DataSectionSkeleton>
  );
};

export default ReviewReportTableSkeleton;
