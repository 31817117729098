import React from "react";

import { QueryPagination } from "components";
import { useFilterParams } from "hooks";
import { useGetOwner } from "services";
import type { GetOwnerQueryModel } from "types";

const OwnerPagination = () => {
  const { filters } = useFilterParams<GetOwnerQueryModel["query"]>([
    "page",
    "marketing",
    "nickname",
    "name",
    "phone",
    "dateEnd",
    "dateStart",
  ]);

  const { data } = useGetOwner({
    query: {
      ...filters,
      createdStart: filters.dateStart,
      createdEnd: filters.dateEnd,
    },
  });

  return <QueryPagination pageInfo={data?.pageInfo!} />;
};

export default OwnerPagination;
