import React from "react";
import { map } from "lodash-es";
import type { AxiosResponse } from "axios";

import { DataSection } from "components";
import { useFilterParams } from "hooks";
import { useGetStatics, useGetStaticsExcelDownload } from "services";
import { downloadExcelFile, keys } from "utils";
import { STATICS_TABLE_HEADER } from "assets";
import type { GetStaticsQueryModel } from "types";
import * as S from "./StatisticsTable.styled";

const StatisticsTable = () => {
  const { filters } = useFilterParams<GetStaticsQueryModel["query"]>([
    "page",
    "repairshopName",
    "dateStart",
    "dateEnd",
  ]);

  const { data, refetch, dataUpdatedAt } = useGetStatics({ query: filters });
  const { refetch: excelDownload } = useGetStaticsExcelDownload(
    {
      query: filters,
    },
    {
      onSuccess: (res: AxiosResponse<any, any>) => {
        downloadExcelFile(res);
      },
    },
  );

  const handleDownload = () => {
    excelDownload();
  };

  return (
    <DataSection
      totalData={data?.pageInfo.totalData!}
      refetch={refetch}
      dataUpdatedAt={dataUpdatedAt}
      activeBtns={
        <DataSection.ExcelDownloadBtn handleDownload={handleDownload} />
      }
    >
      <S.Table title="정비소 리스트">
        <S.Thead>
          <S.FirstHeadTr>
            <th rowSpan={2}>정비소명</th>
            <th colSpan={6}>인앱예약</th>
            <th colSpan={5}>자체예약</th>
          </S.FirstHeadTr>
          <S.SecondHeadTr>
            <th>예약요청</th>
            <th>예약완료</th>
            <th>정비중</th>
            <th>정비완료</th>
            <th>예약취소</th>
            <th>총계</th>
            <th>예약완료</th>
            <th>정비중</th>
            <th>정비완료</th>
            <th>예약취소</th>
            <th>총계</th>
          </S.SecondHeadTr>
        </S.Thead>
        <S.Tbody>
          {data?.pageInfo.totalData ? (
            <>
              {map(data?.reservations, (rowData) => (
                <S.BodyTr key={rowData.repairshopId}>
                  {map(keys(STATICS_TABLE_HEADER), (key) => (
                    <S.Td key={key}>{rowData[key]}</S.Td>
                  ))}
                </S.BodyTr>
              ))}
            </>
          ) : (
            <DataSection.Table.NoData />
          )}
        </S.Tbody>
      </S.Table>
    </DataSection>
  );
};

export default StatisticsTable;
