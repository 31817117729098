import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isNumber } from "lodash-es";
import type {
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import type { Address } from "react-daum-postcode";

import {
  Button,
  DaumPostcodeModal,
  BrandDropdown,
  FormInput,
  FormRadioBtn,
  LabelContent,
  ErrorContent,
  Division,
} from "components";
import { useToast } from "hooks";
import { EMAIL_REG, formatPhoneNum } from "utils";
import { HOOKFORM_ERR_OBJ, SearchIcon, TOAST_MSG } from "assets";
import type { Brand, BrandDropdownOption, RepairshopFormType } from "types";
import * as S from "./RepairshopForm.styled";

interface RepairshopFormProps {
  request?: number;
  truckBrands: Brand[];
  register: UseFormRegister<RepairshopFormType>;
  watch: UseFormWatch<RepairshopFormType>;
  errors: FieldErrors<RepairshopFormType>;
  setValue: UseFormSetValue<RepairshopFormType>;
  clearErrors: UseFormClearErrors<RepairshopFormType>;
  handleSubmit: () => void;
}

const RepairshopForm = ({
  request,
  truckBrands,
  register,
  watch,
  errors,
  setValue,
  clearErrors,
  handleSubmit,
}: RepairshopFormProps) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { addToast } = useToast();

  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);

  const truckDropdownOption = truckBrands?.map(({ brandId, title }) => ({
    brandId,
    brandName: title,
  }));

  const handleClickAddressBtn = () =>
    setIsAddressModalOpen(!isAddressModalOpen);

  const handleCompletedAddressSearch = (data: Address) => {
    const { userSelectedType, jibunAddress, roadAddress, zonecode } = data;

    const geocoder = new window.kakao.maps.services.Geocoder();

    const getLatLon = (address: string) => {
      geocoder.addressSearch(address, function (res: any, status: string) {
        if (status === window.kakao.maps.services.Status.OK) {
          setValue("longitude", res[0].x, { shouldValidate: true });
          setValue("latitude", res[0].y, { shouldValidate: true });
          setValue("districtCode", res[0].address.b_code.substr(0, 2), {
            shouldValidate: true,
          });
        }
      });
    };

    if (userSelectedType === "R") {
      // 도로명 주소 선택
      setValue("addr", roadAddress, { shouldValidate: true });
      setValue("zipcode", zonecode, { shouldValidate: true });
      getLatLon(roadAddress);
    } else {
      // 지번 주소 선택
      setValue("addr", jibunAddress, { shouldValidate: true });
      setValue("zipcode", zonecode, { shouldValidate: true });
      getLatLon(jibunAddress);
    }

    clearErrors("addr");
  };

  const handleSelectBrand = ({ brandId, brandName }: BrandDropdownOption) => {
    if (watch("brands").find((brand) => brand.brandId === brandId)) return;

    setValue("brands", [...watch("brands"), { brandId, brandName }]);
    clearErrors("brands");
  };

  const handleDeleteBrand = (option: BrandDropdownOption) => () =>
    setValue(
      "brands",
      watch("brands").filter(({ brandId }) => brandId !== option.brandId),
    );

  const handleClickCancelBtn = () =>
    navigate(id ? `/repairshop/${id}` : "/repairshop");

  return (
    <S.Form onSubmit={handleSubmit}>
      <Division css={S.division} />
      <LabelContent css={S.labelContent} heading="등록정보">
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content css={S.content} heading="정비소명" required>
            <FormInput
              placeholder="정비소 이름을 입력하세요"
              maxLength={50}
              hasError={!!errors.name}
              errorMsg={errors.name?.message}
              register={register("name", {
                required: HOOKFORM_ERR_OBJ.REQUIRED.message,
              })}
            />
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content
            css={S.content}
            heading="ID(이메일주소)"
            required
          >
            <FormInput
              placeholder="이메일 주소를 입력하세요"
              maxLength={250}
              hasError={!!errors.email || !!errors.emailError}
              errorMsg={errors.email?.message || errors.emailError?.message}
              register={register("email", {
                required: HOOKFORM_ERR_OBJ.REQUIRED.message,
                pattern: {
                  value: EMAIL_REG,
                  message: HOOKFORM_ERR_OBJ.EMAIL_FORMAT.message,
                },
                onChange: () => clearErrors("emailError"),
              })}
            />
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content css={S.content} heading="대표 연락처" required>
            <FormInput
              placeholder="연락처를 입력하세요(-없이 숫자만 입력)"
              maxLength={14}
              hasError={!!errors.phone}
              errorMsg={errors.phone?.message}
              register={register("phone", {
                required: HOOKFORM_ERR_OBJ.REQUIRED.message,
                onChange: (e) =>
                  setValue("phone", formatPhoneNum(e.target.value)),
              })}
            />
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content css={S.content} heading="주소" required>
            <ErrorContent css={S.inputWrapper} errorMsg={errors.addr?.message}>
              <S.AddressBtn
                type="button"
                hasError={!!errors.addr}
                hasValue={!!watch("addr")}
                onClick={handleClickAddressBtn}
              >
                {watch("addr") ? watch("addr") : "주소를 검색하세요"}
                <SearchIcon />
              </S.AddressBtn>
              {isAddressModalOpen && (
                <DaumPostcodeModal
                  handleModalClose={handleClickAddressBtn}
                  handleSearchPostcode={handleCompletedAddressSearch}
                />
              )}
              <FormInput
                placeholder="상세 주소를 입력하세요"
                maxLength={250}
                register={register("addrDetail")}
              />
            </ErrorContent>
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content
            css={S.content}
            heading="정비 가능 브랜드"
            required
          >
            <ErrorContent
              css={S.inputWrapper}
              errorMsg={errors.brands?.message}
            >
              {truckDropdownOption && (
                <BrandDropdown
                  css={S.dropdown}
                  placeholder="브랜드를 선택하세요"
                  options={truckDropdownOption}
                  selectedOptions={watch("brands")}
                  hasError={!!errors.brands}
                  handleSelect={handleSelectBrand}
                  handleDeleteBrand={handleDeleteBrand}
                />
              )}
            </ErrorContent>
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
      </LabelContent>
      <Division css={S.division} />
      <LabelContent css={S.labelContent} heading="설정">
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content css={S.settingContent} heading="상태">
            <FormRadioBtn
              css={S.formRadioBtn}
              radioList={[
                { key: "active", label: "활성화 (앱 내 노출)" },
                { key: "inactive", label: "비활성화" },
              ]}
              register={register("status", {
                onChange: () =>
                  addToast(
                    watch("status") === "active"
                      ? TOAST_MSG.ALERT.REPAIRSHOP_ACTIVE_ALERT
                      : TOAST_MSG.ALERT.REPAIRSHOP_INACTIVE_ALERT,
                  ),
              })}
            />
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content css={S.settingContent} heading="인앱 예약">
            <FormRadioBtn
              css={S.formRadioBtn}
              radioList={[
                { key: "y", label: "가능" },
                { key: "n", label: "불가" },
              ]}
              register={register("available", {
                onChange: () =>
                  addToast(
                    watch("available") === "y"
                      ? TOAST_MSG.ALERT.REPAIRSHOP_INAPP_ABLE_ALERT
                      : TOAST_MSG.ALERT.REPAIRSHOP_INAPP_STOP_ALERT,
                  ),
              })}
            />
            {!!isNumber(request) && <span>(트닥 요청 건수 : {request}건)</span>}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
      </LabelContent>
      <Division css={S.division} />
      <S.BtnWrapper>
        <Button
          type="submit"
          variant="primary"
          label={id ? "저장" : "생성"}
          isDisabled={!!Object.keys(errors).length}
        />
        <Button
          variant="secondary"
          label="취소"
          handleClickBtn={handleClickCancelBtn}
        />
      </S.BtnWrapper>
    </S.Form>
  );
};

export default RepairshopForm;
