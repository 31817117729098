import React from "react";
import { useLocation } from "react-router-dom";

import { PageLayout, NoticeForm } from "components";
import {
  REPAIRSHOP_NOTICE_SUPPORT_CREATE_PAGE_LAYOUT,
  USER_NOTICE_SUPPORT_CREATE_PAGE_LAYOUT,
} from "assets";
import * as S from "./CreateNotice.styled";

const CreateNotice = () => {
  const { pathname } = useLocation();
  const isRepairshopNotice = pathname.split("/")[2].includes("repairshop");

  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={
        isRepairshopNotice
          ? REPAIRSHOP_NOTICE_SUPPORT_CREATE_PAGE_LAYOUT
          : USER_NOTICE_SUPPORT_CREATE_PAGE_LAYOUT
      }
    >
      <NoticeForm />
    </PageLayout>
  );
};

export default CreateNotice;
