import styled from "@emotion/styled";
import { css } from "@emotion/react";
export const Caption = styled.caption`
  ${({ theme }) => css`
    padding: 11px 20px;
    border: 1px solid ${theme.color.gray_20};
    font: ${theme.font.medium_14};
    color: ${theme.color.gray_60};
    text-align: left;
  `}
`;

export const Tr = styled.tr<{ hasErr?: boolean; disabled?: boolean }>`
  ${({ theme, hasErr, disabled }) => css`
    display: grid;
    grid-template-columns: 160px 1fr;
    border: 1px solid ${theme.color.gray_20};
    border-top: 0;
    border-bottom: ${hasErr && `1px solid ${theme.color.red_20}`};

    & > th {
      display: flex;
      align-items: center;
      padding: 13px 20px;
      font: ${theme.font.medium_13};
      color: ${hasErr ? `${theme.color.red_20}` : `${theme.color.gray_60}`};
      text-align: left;
      background-color: ${disabled
        ? `${theme.color.gray_30}`
        : hasErr
        ? `${theme.color.red_10_10}`
        : `${theme.color.gray_10}`};
    }

    & > td {
      padding: 13px 20px;
      font: ${theme.font.regular_13};
      color: ${disabled ? `${theme.color.gray_70}` : `${theme.color.black}`};
      background-color: ${disabled && theme.color.gray_10};
    }

    &:focus-within {
      border-bottom: 1px solid ${theme.color.blue_10};

      & > th {
        color: ${theme.color.blue_10};
        background-color: ${theme.color.blue_10_10};
      }
    }
  `}
`;
