import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ConfirmModal } from "components";
import { useModal, useToast } from "hooks";
import { useBulkNoticeSupport } from "services";
import { TOAST_MSG } from "assets";
import type { NoticeSupportClientModel } from "types";

interface PinModalProps {
  checkedNoticeList: NoticeSupportClientModel[];
  handleAllUnCheck: () => void;
}

const PinModal = ({ checkedNoticeList, handleAllUnCheck }: PinModalProps) => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { modalRef, handleModalClose } = useModal();
  const { addToast } = useToast();

  const { isLoading, mutate: pinMutate } = useBulkNoticeSupport();
  const tabQuery = searchParams.get("tab");

  const activeFn = () => {
    pinMutate(
      {
        body: {
          command: "pin",
          type: tabQuery?.slice(0, -6) as "repairshop" | "user",
          noticeIds: checkedNoticeList.map((notice) => notice.noticeId),
        },
      },
      {
        onSuccess: () => {
          handleAllUnCheck();
          addToast(TOAST_MSG.SUCCESS.NOTICE_FIXED);
          navigate(`/support?tab=${tabQuery}`);
        },
        onSettled: () => {
          handleModalClose();
        },
        onError: (err) => {
          if (err.response?.data.code === "TOO_MANY_PINNED") {
            addToast(TOAST_MSG.WARNING.NOTICE_MAXIMUM_FIXED);
          } else {
            addToast({
              type: "warning",
              content: err.response?.data.message,
            });
          }
        },
      },
    );
  };

  return (
    <ConfirmModal
      ref={modalRef}
      isLoading={isLoading}
      title="공지 상단 고정"
      desc={
        "해당 게시글을 공지사항의 상단에 고정하시겠습니까?\n핀은 최대 5개까지 고정 가능합니다."
      }
      btnType="active"
      activeBtnName="고정"
      activeFn={activeFn}
    />
  );
};

export default PinModal;
