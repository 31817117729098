import React from "react";

import { DataSectionSkeleton } from "components";
import {
  BULLETIN_REPORT_TABLE_GRID_COLUMNS,
  BULLETIN_REPORT_TABLE_HEADER,
} from "assets";

const BulletinReportTableSkeleton = () => {
  return (
    <DataSectionSkeleton>
      <DataSectionSkeleton.Table
        columnTable={BULLETIN_REPORT_TABLE_HEADER}
        gridTemplateColumns={BULLETIN_REPORT_TABLE_GRID_COLUMNS}
      />
    </DataSectionSkeleton>
  );
};

export default BulletinReportTableSkeleton;
