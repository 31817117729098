import React, { useState } from "react";

import { AccountInput, PasswordHint, Button, ErrorMsg } from "components";
import { HOOKFORM_ERR_OBJ } from "assets";
import {
  checkPasswordLengthValidation,
  checkPasswordThreeValidation,
} from "utils";
import useResetpasswordForm from "./hooks/useResetPasswordForm";
import * as S from "./ResetPassword.styled";

const ResetPassword = () => {
  const [isLength, setIsLength] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isDefault, setIsDefault] = useState(false);

  const { register, watch, errors, setError, clearErrors, handleSubmit } =
    useResetpasswordForm();

  return (
    <S.Root>
      <S.Form onSubmit={handleSubmit}>
        <S.Title>비밀번호 재설정</S.Title>
        <S.P>
          안전한 계정관리를 위해 영문(대/소문자),숫자,특수문자(!*#& 등)를 활용한
          <br />
          8-16자의 비밀번호를 입력해주세요.
        </S.P>
        <S.InputWrapper>
          <AccountInput
            css={S.accountInput}
            value={watch("newPassword")}
            label="새 비밀번호"
            id="newPassword"
            type="password"
            maxLength={16}
            isError={!!errors.newPassword}
            register={register("newPassword", {
              required: "비밀번호를 입력하세요",
              validate: (value) =>
                !checkPasswordThreeValidation(value) ||
                "비밀번호 조건에 맞지 않습니다.",
              onBlur: (e) => !e.target.value && setIsDefault(true),
              onChange: (e) => {
                const { value } = e.target;
                const isCheckLength = !checkPasswordLengthValidation(value);
                const isCheckPassword = !checkPasswordThreeValidation(value);

                if (value) {
                  setIsDefault(true);
                } else if (!isCheckLength && isCheckPassword) {
                  setIsDefault(false);
                }
                if (
                  watch("confirmPassword") !== watch("newPassword") &&
                  !!watch("confirmPassword")
                ) {
                  setError("confirmPassword", {
                    message: HOOKFORM_ERR_OBJ.NOTSAME_PASSWORD_FORMAT.message,
                  });
                } else {
                  clearErrors("confirmPassword");
                }

                setIsLength(isCheckLength);
                setIsCheck(isCheckPassword);
              },
            })}
          />
          <S.HintBox>
            <PasswordHint
              isValidCheck={isLength}
              isDefault={isDefault}
              passwordHint="8-16자 이내"
            />
            <PasswordHint
              isValidCheck={isCheck}
              isDefault={isDefault}
              passwordHint="3가지 조합 (영문,숫자,특수문자(!*#& 등))"
            />
          </S.HintBox>
          <AccountInput
            css={S.accountInput}
            value={watch("confirmPassword")}
            label="비밀번호 확인"
            id="confirmPassword"
            type="password"
            maxLength={16}
            isError={!!errors.confirmPassword}
            register={register("confirmPassword", {
              required: "비밀번호를 입력하세요",
              validate: (value) =>
                value === watch("newPassword") ||
                HOOKFORM_ERR_OBJ.NOTSAME_PASSWORD_FORMAT.message,
            })}
          />
          {errors?.confirmPassword?.message && (
            <ErrorMsg errorMsg={errors?.confirmPassword?.message} />
          )}
        </S.InputWrapper>
        <Button
          css={S.submitBtn}
          variant="primary"
          type="submit"
          isDisabled={
            Object.keys(errors).length !== 0 ||
            watch("confirmPassword") !== watch("newPassword")
          }
          label="완료"
        />
      </S.Form>
    </S.Root>
  );
};

export default ResetPassword;
