import React from "react";
import { map } from "lodash-es";
import type { AxiosResponse } from "axios";

import { DataSection, Status } from "components";
import { useFilterParams } from "hooks";
import { useGetOwner, useGetOwnerExcelDownload } from "services";
import { keys, downloadExcelFile } from "utils";
import { OWNER_TABLE_HEADER } from "assets";
import type { GetOwnerQueryModel } from "types";
import * as S from "./OwnerTable.styled";

const OwnerTable = () => {
  const { filters } = useFilterParams<GetOwnerQueryModel["query"]>([
    "page",
    "marketing",
    "nickname",
    "name",
    "phone",
    "dateEnd",
    "dateStart",
  ]);

  const { data, refetch, dataUpdatedAt } = useGetOwner({
    query: {
      ...filters,
      createdStart: filters.dateStart,
      createdEnd: filters.dateEnd,
    },
  });

  const { refetch: excelDownload } = useGetOwnerExcelDownload(
    {
      query: {
        ...filters,
        createdStart: filters.dateStart,
        createdEnd: filters.dateEnd,
      },
    },
    {
      onSuccess: (res: AxiosResponse<any, any>) => {
        downloadExcelFile(res);
      },
    },
  );

  const handleDownload = () => {
    excelDownload();
  };

  return (
    <DataSection
      totalData={data?.pageInfo.totalData!}
      refetch={refetch}
      dataUpdatedAt={dataUpdatedAt}
      activeBtns={
        <DataSection.ExcelDownloadBtn handleDownload={handleDownload} />
      }
    >
      <S.OwnerTable title="차주 리스트" columnTable={OWNER_TABLE_HEADER}>
        {map(data?.users, (rowData) => (
          <DataSection.Table.Row key={rowData.userId}>
            {map(keys(OWNER_TABLE_HEADER), (key) => (
              <DataSection.Table.Td key={key}>
                {key === "marketing" ? (
                  <Status status={rowData[key]} />
                ) : (
                  rowData[key]
                )}
              </DataSection.Table.Td>
            ))}
          </DataSection.Table.Row>
        ))}
      </S.OwnerTable>
    </DataSection>
  );
};

export default OwnerTable;
