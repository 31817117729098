import React from "react";

import { QueryPagination } from "components";
import { useGetComments } from "services";
import { useFilterParams } from "hooks";
import type { GetCommentsQueryModel } from "types";

const CommentPagination = () => {
  const { filters } = useFilterParams<GetCommentsQueryModel["query"]>([
    "page",
    "category",
    "status",
  ]);

  const { data } = useGetComments({
    query: filters,
  });

  return <QueryPagination pageInfo={data?.pageInfo!} />;
};

export default CommentPagination;
