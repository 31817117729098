import React from "react";
import { map } from "lodash-es";

import { Status, DataSection } from "components";
import { useGetOwnerSupports } from "services";
import { OWNER_SUPPORT_TABLE_HEADER } from "assets";
import { useFilterParams } from "hooks";
import { keys } from "utils/helper/table";
import type { GetOwnerSupportsQueryModel } from "types";
import * as S from "./OwnerSupportTable.styled";

const OwnerSupportTable = () => {
  const { filters } = useFilterParams<GetOwnerSupportsQueryModel["query"]>([
    "category",
    "status",
    "page",
  ]);

  const { data, refetch, dataUpdatedAt } = useGetOwnerSupports({
    query: filters,
  });

  return (
    <DataSection
      totalData={data?.pageInfo.totalData!}
      refetch={refetch}
      dataUpdatedAt={dataUpdatedAt}
    >
      <S.OwnerTable
        title="차주 1:1 문의"
        columnTable={OWNER_SUPPORT_TABLE_HEADER}
      >
        {map(data?.helps, (rowData) => (
          <DataSection.Table.SelectRowMovePage
            key={rowData.detailId}
            id={rowData.detailId}
            path={`/support/owner/${rowData.detailId}`}
          >
            {map(keys(OWNER_SUPPORT_TABLE_HEADER), (key) => (
              <DataSection.Table.Td key={key}>
                {key === "askStatus" ? (
                  <Status status={rowData[key]} />
                ) : (
                  rowData[key]
                )}
              </DataSection.Table.Td>
            ))}
          </DataSection.Table.SelectRowMovePage>
        ))}
      </S.OwnerTable>
    </DataSection>
  );
};

export default OwnerSupportTable;
