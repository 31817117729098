import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import { NOTICE_SUPPORT_TABLE_GRID_COLUMNS } from "assets";

export const checkTable = (theme: Theme) => css`
  height: calc(
    100vh - ${theme.size.TABLE_WRAPPER_EXCEPT_FILTER} -
      ${theme.size.TABLE_WRAPPER_TAB}
  );

  tr {
    grid-template-columns: ${NOTICE_SUPPORT_TABLE_GRID_COLUMNS};
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;
