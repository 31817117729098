import React from "react";

import * as S from "./FieldTableForm.styled";

interface FieldTableFormProps {
  className?: string;
  children: React.ReactNode;
  caption: string;
}

const FieldTableForm = ({
  className,
  children,
  caption,
}: FieldTableFormProps) => {
  return (
    <table className={className}>
      <S.Caption>{caption}</S.Caption>
      <tbody>{children}</tbody>
    </table>
  );
};

interface RowProps {
  className?: string;
  title: string;
  hasErr?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
}

FieldTableForm.Row = function Row({
  className,
  title,
  hasErr,
  disabled,
  children,
}: RowProps) {
  return (
    <S.Tr className={className} hasErr={hasErr} disabled={disabled}>
      <th scope="row">{title}</th>
      <td>{children}</td>
    </S.Tr>
  );
};

export default FieldTableForm;
