import React, { createContext, useContext, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { CalendarInput } from "components";
import type { FilterCondition } from "types";
import { useCondition, useRadioBtnFilter, useFilterCalendar } from "../hooks";
import FilterInput from "../input/FilterInput";
import * as S from "./FilterItem.styled";

interface FilterItemProps {
  condition: FilterCondition;
}

interface FilterItemContextValue {
  condition: FilterCondition;
  isChecked: (
    conditionKey: string,
    checkItemKey: string,
    defaultValue?: string,
  ) => boolean;
  isCheckAll: (conditionKey: string) => boolean;
  handleCreateConditionError: () => void;
  handleDeleteConditionError: () => void;
  handleFocusCondition: () => void;
  handleBlurCondition: () => void;
}

const FilterItemContext = createContext({} as FilterItemContextValue);

const FilterItem = ({ condition }: FilterItemProps) => {
  const [searchParams] = useSearchParams();

  const {
    isConditionFocus,
    isConditionError,
    handleCreateConditionError,
    handleDeleteConditionError,
    handleFocusCondition,
    handleBlurCondition,
  } = useCondition();

  const isCheckDefaultValue = (
    conditionKey: string,
    checkItemKey: string,
    defaultValue?: string,
  ) => !searchParams.get(conditionKey) && checkItemKey === defaultValue;

  const isCheckAll = useCallback(
    (conditionKey: string) => {
      return searchParams.getAll(conditionKey).length === 0;
    },
    [searchParams],
  );

  const isChecked = (
    conditionKey: string,
    checkItemKey: string,
    defaultValue?: string,
  ) =>
    searchParams.getAll(conditionKey).includes(checkItemKey) ||
    isCheckDefaultValue(conditionKey, checkItemKey, defaultValue);

  const conditionType = (type: FilterCondition["type"]) => {
    if (type === "null") return;

    const typeTable = {
      input: <FilterItem.Input />,
      notice: <FilterItem.Notice />,
      radio: <FilterItem.Radio />,
      calendar: <FilterItem.Calendar />,
    };

    return typeTable[type];
  };

  return (
    <FilterItemContext.Provider
      value={{
        condition,
        isChecked,
        isCheckAll,
        handleCreateConditionError,
        handleDeleteConditionError,
        handleFocusCondition,
        handleBlurCondition,
      }}
    >
      <S.Condition
        data-isconditionfocus={isConditionFocus}
        data-isconditionerror={isConditionError}
      >
        {condition.type === "null" ? null : (
          <>
            <S.ConditionName
              data-isconditionfocus={isConditionFocus}
              data-isconditionerror={isConditionError}
            >
              {condition.name}
            </S.ConditionName>
            {conditionType(condition.type)}
          </>
        )}
      </S.Condition>
    </FilterItemContext.Provider>
  );
};

FilterItem.Input = function FilterInputItem() {
  const {
    condition,
    handleCreateConditionError,
    handleDeleteConditionError,
    handleFocusCondition,
    handleBlurCondition,
  } = useContext(FilterItemContext);

  return (
    <S.ConditionChildrenWapper>
      <FilterInput
        condition={condition}
        handleFocusCondition={handleFocusCondition}
        handleBlurCondition={handleBlurCondition}
        handleCreateConditionError={handleCreateConditionError}
        handleDeleteConditionError={handleDeleteConditionError}
      />
    </S.ConditionChildrenWapper>
  );
};

FilterItem.Notice = function FilterNoticeItem() {
  const { condition, isChecked } = useContext(FilterItemContext);

  const { handleClickRadioBtn } = useRadioBtnFilter();

  return (
    <S.ConditionChildrenWapper>
      <S.FilterListWapper>
        {condition.filterList && (
          <>
            {condition.filterList.map((item, i) => (
              <S.FilterListItem key={item.key + i}>
                <input
                  id={item.label}
                  type="radio"
                  name={condition.key}
                  checked={isChecked(condition.key, item.key, "shipper")}
                  value={item.key}
                  onChange={handleClickRadioBtn(condition.key, item.key)}
                />
                <label htmlFor={item.label} tabIndex={0}>
                  {item.label}
                </label>
              </S.FilterListItem>
            ))}
          </>
        )}
      </S.FilterListWapper>
    </S.ConditionChildrenWapper>
  );
};

FilterItem.Radio = function FilterRadioItem() {
  const { condition, isChecked, isCheckAll } = useContext(FilterItemContext);

  const { handleClickRadioBtn, handleClickAllRadioBtn } = useRadioBtnFilter();

  return (
    <S.ConditionChildrenWapper>
      <S.FilterListWapper>
        {condition.filterList && (
          <>
            <S.FilterAllBtn>
              <input
                id={condition.key}
                type="radio"
                checked={isCheckAll(condition.key)}
                value="all"
                onChange={handleClickAllRadioBtn(condition.key)}
              />
              <label htmlFor={condition.key} tabIndex={0}>
                전체
              </label>
            </S.FilterAllBtn>
            {condition.filterList.map((item, i) => (
              <S.FilterListItem key={item.key + i}>
                <input
                  id={item.label}
                  type="radio"
                  name={condition.key}
                  checked={isChecked(condition.key, item.key)}
                  value={item.key}
                  onChange={handleClickRadioBtn(condition.key, item.key)}
                />
                <label htmlFor={item.label} tabIndex={0}>
                  {item.label}
                </label>
              </S.FilterListItem>
            ))}
          </>
        )}
      </S.FilterListWapper>
    </S.ConditionChildrenWapper>
  );
};

FilterItem.Calendar = function FilterCalendarItem() {
  const { condition, handleFocusCondition, handleBlurCondition } =
    useContext(FilterItemContext);

  const { selectedDates, selectedDate, handleChangeFilterDate } =
    useFilterCalendar(condition);

  return (
    <S.CalendarWrapper>
      <CalendarInput
        // css={calendar}
        type="free"
        selectedDate={selectedDates}
        value={selectedDate}
        handleChangeDate={handleChangeFilterDate}
        handleFocusCondition={handleFocusCondition}
        handleBlurCondition={handleBlurCondition}
      />
    </S.CalendarWrapper>
  );
};

export default FilterItem;
