export const OWNER_MARKETING_STATUS = {
  1: "동의",
  0: "거절",
} as const;

export const OWNER_GENDER_STATUS = {
  1: "남성",
  2: "여성",
  3: "남성",
  4: "여성",
} as const;

export const OWNER_DATE = {
  createdStart: "dateStart",
  createdEnd: "dateEnd",
} as const;
