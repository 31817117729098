import React, { Suspense } from "react";

import { Filter, PaginationSkeleton } from "components";
import { REPORT_USER_FILTER_CONDITIONS } from "assets";
import {
  UserReportTable,
  UserReportTableSkeleton,
  UserPagination,
} from "./containers";

const UserReport = () => {
  return (
    <>
      <Filter filterConditions={REPORT_USER_FILTER_CONDITIONS} />
      <Suspense fallback={<UserReportTableSkeleton />}>
        <UserReportTable />
      </Suspense>
      <Suspense fallback={<PaginationSkeleton />}>
        <UserPagination />
      </Suspense>
    </>
  );
};

export default UserReport;
