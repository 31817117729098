import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const labelContent = (theme: Theme) =>
  css`
    margin-bottom: 21px;

    & + & {
      margin-bottom: 41px;
    }

    & > div:nth-of-type(2) {
      margin-bottom: 14px;
    }

    & > div:nth-of-type(3) {
      border-top: 1px solid ${theme.color.gray_20};
    }
  `;

export const content = css`
  align-items: flex-start;
  min-height: 88px;

  & > dd {
    overflow: auto;
  }
`;

export const Description = styled.div`
  max-height: 557px;

  img {
    max-width: 100%;
  }

  ul,
  li {
    list-style: inside;
  }

  li {
    padding-left: 10px;
  }
`;

export const division = css`
  margin-bottom: 40px;
`;
