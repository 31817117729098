import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ArrowIcon } from "assets";

export const Root = styled.div`
  position: relative;
  width: 794px;
  height: 672px;
  border: 1px solid ${({ theme }) => theme.color.gray_30};
  padding: 20px 20px 0 20px;
  background-color: ${({ theme }) => theme.color.gray_10};
`;

export const ImgWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 582px;
    background-color: ${theme.color.gray_20};
    overflow: hidden;
  `}
`;

export const Img = styled.img<{
  position: { oldX: number; oldY: number; x: number; y: number };
  rangeValue: number;
  rotate: number;
}>`
  ${({ theme, rangeValue, position, rotate }) => css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: ${rangeValue === 1 ? "cursor" : "grab"};
    transform: ${`translate(${position.x}px, ${position.y}px) scale(${rangeValue})  rotate(${rotate}deg);`};
  `}
`;

export const BtnWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 21px;
    left: 50%;
    display: flex;
    align-items: center;
    column-gap: 8px;
    font: ${theme.font.regular_14};
    transform: translate(-50%);

    & > span {
      padding: 6px 5px;
    }
  `}
`;

export const LeftArrowIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
`;

export const ZoomBtnWrapper = styled.div`
  position: absolute;
  bottom: 21px;
  right: 15px;
  display: flex;
  align-items: center;
  column-gap: 12px;
`;

export const RangeBar = styled.input<{ zoomValue: string }>`
  ${({ theme, zoomValue }) => css`
    width: 60px;
    height: 10px;
    border-radius: 2px;
    border: 1px solid ${theme.color.gray_30};
    background-image: linear-gradient(
      ${theme.color.blue_10},
      ${theme.color.blue_10}
    );
    background-color: ${theme.color.gray_30};
    background-size: ${zoomValue} 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid ${theme.color.blue_10};
      background-color: ${theme.color.white};
      cursor: pointer;
    }
  `}
`;

export const Btn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    & > svg > path {
      fill: ${theme.color.gray_70};
    }

    &:disabled {
      cursor: not-allowed;

      & > svg > path {
        fill: ${theme.color.gray_30};
      }
    }
  `}
`;

export const RotateBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
`;
