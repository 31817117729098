import React from "react";

import { QueryPagination } from "components";
import { useGetTrucks } from "services";
import { useFilterParams } from "hooks";
import type { GetTrucksQueryModel } from "types";

const TruckPagination = () => {
  const { filters } = useFilterParams<GetTrucksQueryModel["query"]>([
    "page",
    "status",
    "brandId",
    "regNum",
  ]);

  const { data } = useGetTrucks({
    query: filters,
  });

  return <QueryPagination pageInfo={data?.pageInfo!} />;
};

export default TruckPagination;
