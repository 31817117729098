import React from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Button, Division, FormRadioBtn, LabelContent } from "components";
import { useBlockUser } from "services";
import { useToast } from "hooks";
import { RADIO_EXPOSURE, TOAST_MSG } from "assets";
import type { GetUserReportDetailClientModel } from "types";
import * as S from "./UserForm.styled";

interface UserFormProps {
  data: GetUserReportDetailClientModel;
}

const UserForm = ({ data }: UserFormProps) => {
  const { id } = useParams();
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<{
    status: (typeof RADIO_EXPOSURE)[number]["key"];
    reportId: string;
  }>({
    mode: "all",
  });

  const { mutate: blockUserMutate } = useBlockUser();

  const { addToast } = useToast();

  const handleClickSubmit = (data: {
    status: (typeof RADIO_EXPOSURE)[number]["key"];
    reportId: string;
  }) => {
    const query = { reportId: id!, command: data.status };
    blockUserMutate(
      { query },
      {
        onSuccess: () => {
          addToast(
            data.status === "blocked"
              ? TOAST_MSG.SUCCESS.USER_DISABLE
              : TOAST_MSG.SUCCESS.USER_ABLE,
          );
        },
        onError: (error) => {
          addToast({
            type: "warning",
            content: error.response?.data.message,
          });
        },
      },
    );
  };

  return (
    <>
      <Division css={S.division} />
      <LabelContent hasBg heading="처리 상세">
        {data.status === "미처리" ? (
          <form onSubmit={handleSubmit(handleClickSubmit)}>
            <LabelContent.ContentWrapper
              css={S.contentWrapper}
              hasErr={!!errors.status}
            >
              <LabelContent.Content heading="피신고자 커뮤니티 활동 처리">
                <FormRadioBtn
                  css={S.formRadioBtn}
                  radioList={[
                    { key: "sustained", label: "유지" },
                    { key: "blocked", label: "제한(처리일 기준 7일 간)" },
                  ]}
                  register={register("status", {
                    required: true,
                    onChange: (e) => {
                      if (e.target.value === "blocked") {
                        addToast(TOAST_MSG.ALERT.USER_STOP_RESET);
                      }
                    },
                  })}
                />
              </LabelContent.Content>
            </LabelContent.ContentWrapper>
            <Button
              type="submit"
              variant="primary"
              label="완료"
              isDisabled={!!Object.keys(errors).length}
            />
          </form>
        ) : (
          <LabelContent.ContentWrapper subItemCount={2}>
            <LabelContent.Content heading="피신고자 커뮤니티 활동 처리">
              {data.status}
            </LabelContent.Content>
            <LabelContent.Content heading="처리 일시">
              {data.updated}
            </LabelContent.Content>
          </LabelContent.ContentWrapper>
        )}
      </LabelContent>
    </>
  );
};

export default UserForm;
