import React from "react";
import { useParams } from "react-router-dom";

import { PageLayout, Report, Review } from "components";
import { useGetReviewDetail } from "services";
import { REVIEW_REPORT_DETAIL, REVIEW_REPORT_DETAIL_PAGE_LAYOUT } from "assets";
import * as S from "./ReviewReportDetail.styled";

const ReviewReportDetail = () => {
  const { id } = useParams();

  const { data } = useGetReviewDetail(id!);

  if (!data) return null;

  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={REVIEW_REPORT_DETAIL_PAGE_LAYOUT}
    >
      <Report
        data={data}
        contents={REVIEW_REPORT_DETAIL}
        linkId={data.repairshopId}
      />
      <Review data={data} />
    </PageLayout>
  );
};

export default ReviewReportDetail;
