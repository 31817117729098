import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useCreateRepairshop } from "services";
import { useToast } from "hooks";
import { phoneNumSubtractMinus } from "utils";
import { HOOKFORM_ERR_OBJ, TOAST_MSG } from "assets";
import type { RepairshopFormType } from "types";

export const useCreateRepairshopForm = () => {
  const navigate = useNavigate();

  const { addToast } = useToast();

  const { mutate: createRepairshopMutate } = useCreateRepairshop();

  const {
    register,
    watch,
    setValue,
    setError,
    setFocus,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = useForm<RepairshopFormType>({
    defaultValues: {
      name: "",
      email: "",
      emailError: "",
      phone: "",
      addr: "",
      addrDetail: "",
      zipcode: "",
      districtCode: "",
      longitude: "",
      latitude: "",
      brands: [],
      status: "inactive",
      available: "n",
    },
    mode: "all",
  });

  const checkValidate = () => {
    if (!watch("brands").length) setError("brands", HOOKFORM_ERR_OBJ.REQUIRED);
    if (!watch("addr")) setError("addr", HOOKFORM_ERR_OBJ.REQUIRED);
  };

  const handleCreateRepairshop = (data: RepairshopFormType) => {
    checkValidate();

    createRepairshopMutate(
      {
        body: {
          ...data,
          brands: data.brands.map(({ brandId }) => ({ brandId })),
          phone: phoneNumSubtractMinus(data.phone),
          longitude: +data.longitude,
          latitude: +data.latitude,
        },
      },
      {
        onSuccess: (res) => {
          addToast(TOAST_MSG.SUCCESS.REPAIRSHOP_CREATE);
          navigate(`/repairshop/${res.repairShopId}`);
        },
        onError: (error) => {
          if (error.response?.data.code === "REPAIRSHOP_ALREADY_EXISTS") {
            setError("emailError", {
              message: HOOKFORM_ERR_OBJ.EXIST_REPAIRSHOP_EMAIL.content,
            });
            setFocus("email");
          } else {
            addToast({
              type: "warning",
              content: error.response?.data.message,
            });
          }
        },
      },
    );
  };

  const handleErrorCreateRepairshop = () => checkValidate();

  return {
    register,
    watch,
    errors,
    setValue,
    setError,
    clearErrors,
    handleSubmit: handleSubmit(
      handleCreateRepairshop,
      handleErrorCreateRepairshop,
    ),
  };
};
