import { FilterCondition } from "types";

export const STATISTICS_FILTER_CONDITIONS: FilterCondition[][] = [
  [
    {
      searchLabel: [{ key: "repairshopName", name: "정비소명" }],
      name: "정비소명",
      key: "repairshopName",
      type: "input",
      placeholder: "정비소 이름을 입력하세요",
      maxLength: 50,
    },
    {
      searchLabel: [{ key: "date", name: "기간" }],
      name: "기간",
      key: "date",
      type: "calendar",
    },
  ],
];

export const REPAIRSHOP_FILTER_CONDITIONS: FilterCondition[][] = [
  [
    {
      searchLabel: [{ key: "brandId", name: "브랜드" }],
      name: "브랜드",
      key: "brandId",
      type: "radio",
    },
  ],
  [
    {
      searchLabel: [{ key: "status", name: "상태" }],
      name: "상태",
      key: "status",
      type: "radio",
      filterList: [
        { key: "active", label: "활성화" },
        { key: "inactive", label: "비활성화" },
        { key: "preparing", label: "준비중" },
      ],
    },
    {
      searchLabel: [{ key: "available", name: "인앱예약" }],
      name: "인앱 예약",
      key: "available",
      type: "radio",
      filterList: [
        { key: "y", label: "가능" },
        { key: "n", label: "불가" },
      ],
    },
  ],
  [
    {
      searchLabel: [{ key: "name", name: "정비소명" }],
      name: "정비소명",
      key: "name",
      type: "input",
      placeholder: "정비소명을 입력하세요",
      maxLength: 50,
    },
    {
      searchLabel: [{ key: "", name: "" }],
      name: "",
      key: "",
      type: "null",
    },
  ],
];

export const TRUCK_FILTER_CONDITIONS: FilterCondition[][] = [
  [
    {
      searchLabel: [{ key: "brandId", name: "브랜드" }],
      name: "브랜드",
      key: "brandId",
      type: "radio",
    },
  ],
  [
    {
      searchLabel: [{ key: "status", name: "처리 상태" }],
      name: "처리 상태",
      key: "status",
      type: "radio",
      filterList: [
        { key: "requested", label: "승인대기" },
        { key: "retried", label: "재요청" },
        { key: "active", label: "등록완료" },
        { key: "failed", label: "반려" },
      ],
    },
  ],
  [
    {
      searchLabel: [{ key: "regNum", name: "차량번호" }],
      name: "차량번호",
      key: "regNum",
      type: "input",
      placeholder: "차량번호를 입력하세요",
      maxLength: 10,
    },
    {
      searchLabel: [{ key: "", name: "" }],
      name: "",
      key: "",
      type: "null",
    },
  ],
];

export const OWNER_FILTER_CONDITIONS: FilterCondition[][] = [
  [
    {
      searchLabel: [{ key: "marketing", name: "마케팅 수신" }],
      name: "마케팅 수신",
      key: "marketing",
      type: "radio",
      filterList: [
        { key: "1", label: "동의" },
        { key: "0", label: "거절" },
      ],
    },
    {
      searchLabel: [{ key: "phone", name: "연락처" }],
      name: "연락처",
      key: "phone",
      type: "input",
      placeholder: "-없이 숫자만 입력하세요",
      maxLength: 11,
    },
  ],
  [
    {
      searchLabel: [{ key: "date", name: "기간" }],
      name: "날짜",
      key: "date",
      type: "calendar",
    },
    {
      searchLabel: [{ key: "", name: "" }],
      name: "",
      key: "",
      type: "null",
    },
  ],
  [
    {
      searchLabel: [{ key: "name", name: "차주명" }],
      name: "차주명",
      key: "name",
      type: "input",
      placeholder: "차주 이름을 입력하세요",
      maxLength: 20,
    },
    {
      searchLabel: [{ key: "nickname", name: "닉네임" }],
      name: "닉네임",
      key: "nickname",
      type: "input",
      placeholder: "닉네임을 입력하세요",
      maxLength: 10,
    },
  ],
];

export const SUPPORT_OWNER_FILTER_CONDITIONS: FilterCondition[][] = [
  [
    {
      searchLabel: [{ key: "category", name: "문의 유형" }],
      name: "문의 유형",
      key: "category",
      type: "radio",
      filterList: [
        { key: "서비스 이용", label: "서비스 이용" },
        { key: "증빙 서류 제출", label: "증빙서류제출" },
        { key: "시스템 오류", label: "시스템 오류" },
        { key: "건의사항", label: "건의사항" },
        { key: "기타", label: "기타" },
      ],
    },
  ],
  [
    {
      searchLabel: [{ key: "status", name: "처리상태" }],
      name: "처리상태",
      key: "status",
      type: "radio",
      filterList: [
        { key: "답변대기", label: "답변대기" },
        { key: "답변완료", label: "답변완료" },
      ],
    },
  ],
];

export const SUPPORT_REPAIRSHOP_FILTER_CONDITIONS: FilterCondition[][] = [
  [
    {
      searchLabel: [{ key: "category", name: "문의 유형" }],
      name: "문의 유형",
      key: "category",
      type: "radio",
      filterList: [
        { key: "서비스 이용", label: "서비스 이용" },
        { key: "시스템 오류", label: "시스템 오류" },
        { key: "건의사항", label: "건의사항" },
        { key: "기타", label: "기타" },
      ],
    },
  ],
  [
    {
      searchLabel: [{ key: "status", name: "처리상태" }],
      name: "처리상태",
      key: "status",
      type: "radio",
      filterList: [
        { key: "답변대기", label: "답변대기" },
        { key: "답변완료", label: "답변완료" },
      ],
    },
  ],
];

export const REPORT_REVEIW_FILTER_CONDITIONS: FilterCondition[][] = [
  [
    {
      searchLabel: [{ key: "category", name: "신고 사유" }],
      name: "신고 사유",
      key: "category",
      type: "radio",
      filterList: [
        { key: "광고/사기", label: "광고/사기" },
        { key: "음란성/선정성", label: "음란성/선정성" },
        { key: "욕설/인신공격", label: "욕설/인신공격" },
        { key: "개인정보노출", label: "개인정보노출" },
        { key: "기타", label: "기타" },
      ],
    },
  ],
  [
    {
      searchLabel: [{ key: "isChecked", name: "확인여부" }],
      name: "확인여부",
      key: "isChecked",
      type: "radio",
      filterList: [
        { key: "true", label: "확인" },
        { key: "false", label: "미확인" },
      ],
    },
  ],
];

export const REPORT_USER_FILTER_CONDITIONS: FilterCondition[][] = [
  [
    {
      searchLabel: [{ key: "category", name: "신고 사유" }],
      name: "신고 사유",
      key: "category",
      type: "radio",
      filterList: [
        { key: "광고/사기", label: "광고/사기" },
        { key: "음란성/선정성", label: "음란성/선정성" },
        { key: "욕설/인신공격", label: "욕설/인신공격" },
        { key: "개인정보노출", label: "개인정보노출" },
        { key: "기타", label: "기타" },
      ],
    },
  ],
  [
    {
      searchLabel: [{ key: "status", name: "처리 여부" }],
      name: "처리 여부",
      key: "status",
      type: "radio",
      filterList: [
        { key: "-", label: "미처리" },
        { key: "sustained", label: "허용" },
        { key: "blocked", label: "제한" },
      ],
    },
  ],
];

export const REPORT_BULLETIN_FILTER_CONDITIONS: FilterCondition[][] = [
  [
    {
      searchLabel: [{ key: "category", name: "신고 사유" }],
      name: "신고 사유",
      key: "category",
      type: "radio",
      filterList: [
        { key: "광고/사기", label: "광고/사기" },
        { key: "음란성/선정성", label: "음란성/선정성" },
        { key: "욕설/인신공격", label: "욕설/인신공격" },
        { key: "개인정보노출", label: "개인정보노출" },
        { key: "기타", label: "기타" },
      ],
    },
  ],
  [
    {
      searchLabel: [{ key: "status", name: "처리 여부" }],
      name: "처리 여부",
      key: "status",
      type: "radio",
      filterList: [
        { key: "-", label: "미처리" },
        { key: "sustained", label: "허용" },
        { key: "blocked", label: "차단" },
      ],
    },
  ],
];

export const REPORT_COMMENT_FILTER_CONDITIONS: FilterCondition[][] = [
  [
    {
      searchLabel: [{ key: "category", name: "신고 사유" }],
      name: "신고 사유",
      key: "category",
      type: "radio",
      filterList: [
        { key: "광고/사기", label: "광고/사기" },
        { key: "음란성/선정성", label: "음란성/선정성" },
        { key: "욕설/인신공격", label: "욕설/인신공격" },
        { key: "개인정보노출", label: "개인정보노출" },
        { key: "기타", label: "기타" },
      ],
    },
  ],
  [
    {
      searchLabel: [{ key: "status", name: "처리 여부" }],
      name: "처리 여부",
      key: "status",
      type: "radio",
      filterList: [
        { key: "-", label: "미처리" },
        { key: "sustained", label: "허용" },
        { key: "blocked", label: "차단" },
      ],
    },
  ],
];
