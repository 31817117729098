import { useQuery, useMutation } from "@tanstack/react-query";

import {
  getRepairshopListAPI,
  getRepairshopDetailAPI,
  createRepairshopAPI,
  editRepairshopAPI,
  deleteRepairshopAPI,
} from "api";
import {
  RECEIVE_ADVERTISING_EMAIL,
  REPAIRSHOP_ACTIVE_STATUS,
  REPAIRSHOP_AVAILABLE_STATUS,
} from "assets";
import { formatPhoneNum, utcTimeToLocalTime } from "utils";
import type {
  APIError,
  ChangeRepairshopAvailableQueryModel,
  ChangeRepairshopStatusQueryModel,
  CreateRepairshopQueryModel,
  CreateRepairshopServerModel,
  GetRepairshopsQueryModel,
  UpdateRepairshopQueryModel,
} from "types";

const repairshopKeys = {
  all: ["repairshop"],
  lists: () => [...repairshopKeys.all, "list"],
  list: (filters: GetRepairshopsQueryModel) => [
    ...repairshopKeys.lists(),
    { filters },
  ],
  details: () => [...repairshopKeys.all, "detail"],
  detail: (id: string) => [...repairshopKeys.details(), id],
};

export const useGetRepairshops = (req: GetRepairshopsQueryModel) => {
  return useQuery({
    queryKey: repairshopKeys.list(req),
    queryFn: () => getRepairshopListAPI(req),
    select: (res) => ({
      pageInfo: res.pageInfo,
      repairshops: res.repairShops.map((repairshop) => ({
        available: REPAIRSHOP_AVAILABLE_STATUS[repairshop.available],
        brands: repairshop.brands,
        memberCount: repairshop.memberCount,
        detailId: repairshop.repairShopId,
        repairshopName: repairshop.repairShopName,
        repairshopStatus: REPAIRSHOP_ACTIVE_STATUS[repairshop.status],
        reservationCount: repairshop.reservationCount,
        reviewCount: repairshop.reviewCount,
      })),
    }),
    suspense: true,
    keepPreviousData: true,
  });
};

export const useGetRepairshopDetail = (req: string) => {
  return useQuery({
    queryKey: repairshopKeys.detail(req),
    queryFn: () => getRepairshopDetailAPI(req),
    select: (res) => ({
      repairshopId: res.repairShopId,
      name: res.name,
      email: res.email,
      status: REPAIRSHOP_ACTIVE_STATUS[res.status],
      available: REPAIRSHOP_AVAILABLE_STATUS[res.available],
      phone: formatPhoneNum(res.phone),
      zipcode: res.zipcode,
      addr: res.addr,
      addrDetail: res.addrDetail,
      province: res.province,
      districtCode: res.districtCode,
      districtName: res.districtName,
      longitude: res.longitude,
      latitude: res.latitude,
      emailYn: res.emailYn ? RECEIVE_ADVERTISING_EMAIL[res.emailYn] : "-",
      requests: res.requests,
      brands: res.brands,
      created: res.created ? utcTimeToLocalTime(res.created) : "-",
    }),
    suspense: true,
  });
};

export const useCreateRepairshop = () => {
  return useMutation<
    CreateRepairshopServerModel,
    APIError,
    CreateRepairshopQueryModel
  >({
    mutationFn: (req) => createRepairshopAPI(req),
  });
};

export const useEditRepairshop = () => {
  return useMutation<unknown, APIError, UpdateRepairshopQueryModel>({
    mutationFn: (req) => editRepairshopAPI(req),
  });
};

export const useChangeRepairshopStatus = () => {
  return useMutation<unknown, APIError, ChangeRepairshopStatusQueryModel>({
    mutationFn: (req) => editRepairshopAPI(req),
  });
};

export const useChangeRepairshopAvailable = () => {
  return useMutation<unknown, APIError, ChangeRepairshopAvailableQueryModel>({
    mutationFn: (req) => editRepairshopAPI(req),
  });
};

export const useDeleteRepairshop = () => {
  return useMutation<unknown, APIError, string>({
    mutationFn: (req) => deleteRepairshopAPI(req),
  });
};
