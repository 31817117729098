import { css } from "@emotion/react";

export const division = css`
  margin-bottom: 40px;
`;

export const answerContent = css`
  & > dd {
    align-items: flex-start;
    height: 106px;
    padding: 13px 20px;
    white-space: pre-wrap;
    overflow-y: auto;
  }
`;
