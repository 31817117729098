import React from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Button, FormRadioBtn, LabelContent, Division } from "components";
import { usePatchBulletinReport } from "services";
import { useToast } from "hooks";
import { RADIO_EXPOSURE, TOAST_MSG } from "assets";
import type { GetBulletinReportDetailClientModel } from "types";
import * as S from "./BulletinForm.styled";

interface BulletinProps {
  data: GetBulletinReportDetailClientModel;
}

interface Form {
  status: (typeof RADIO_EXPOSURE)[number]["key"];
}

const BulletinForm = ({ data }: BulletinProps) => {
  const { id } = useParams();

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<Form>({ mode: "all" });

  const { mutate: patchBulletinReportMutate } = usePatchBulletinReport();

  const { addToast } = useToast();

  const handleClickSubmit = (data: Form) => {
    const query = { reportId: id!, command: data.status };

    patchBulletinReportMutate(
      { query },
      {
        onSuccess: () => {
          addToast(
            data.status === "blocked"
              ? TOAST_MSG.SUCCESS.BULLETIN_DISABLE
              : TOAST_MSG.SUCCESS.BULLETIN_ABLE,
          );
        },
        onError: () => addToast(TOAST_MSG.WARNING.FAIL),
      },
    );
  };

  return (
    <>
      <Division css={S.division} />
      <LabelContent hasBg heading="처리 상세">
        {data.status === "미처리" ? (
          <form onSubmit={handleSubmit(handleClickSubmit)}>
            <LabelContent.ContentWrapper
              css={S.contentWrapper}
              hasErr={!!errors.status}
            >
              <LabelContent.Content heading="게시글 노출">
                <FormRadioBtn
                  css={S.formRadioBtn}
                  radioList={RADIO_EXPOSURE}
                  register={register("status", {
                    required: true,
                    onChange: (e) => {
                      if (e.target.value === "blocked") {
                        addToast(TOAST_MSG.ALERT.BULLETIN_EXPOSURE_ALERT);
                      }
                    },
                  })}
                />
              </LabelContent.Content>
            </LabelContent.ContentWrapper>
            <Button
              type="submit"
              variant="primary"
              label="완료"
              isDisabled={!!Object.keys(errors).length}
            />
          </form>
        ) : (
          <LabelContent.ContentWrapper subItemCount={2}>
            <LabelContent.Content heading="게시글 노출">
              {data.status}
            </LabelContent.Content>
            <LabelContent.Content heading="처리 일시">
              {data.updated}
            </LabelContent.Content>
          </LabelContent.ContentWrapper>
        )}
      </LabelContent>
    </>
  );
};

export default BulletinForm;
