import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";

const scaleUp = keyframes`
  from { max-height: 0;}
  to {max-height: 100px;}
`;

const scaleDown = keyframes`
  from { max-height: 100px;}
  to { max-height: 0;}
 `;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-50%);}
  to { opacity: 1; transform: translateY(0)}
`;

const fadeOut = keyframes`
  from { opacity: 1; transform: translateY(0)}
  to { opacity: 0; transform: translateY(50%)}
 `;

export const ToastItem = styled.div<{ isClosing: boolean }>`
  ${({ isClosing }) => css`
    max-height: 0;
    overflow: visible;
    animation: ${isClosing ? scaleDown : scaleUp} 0.6s forwards;

    & > div {
      animation: ${isClosing ? fadeOut : fadeIn} 0.3s forwards;
    }
  `}
`;

export const Item = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 24px 240px;
    column-gap: 12px;
    align-items: center;
    width: 320px;
    border-radius: 5px;
    padding: 20px 24px;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.shadow_medium};

    & > p {
      font: ${theme.font.medium_14};
      color: ${theme.color.gray_70};
      white-space: pre-wrap;
    }

    &[data-type="warning"] {
      svg {
        fill: ${theme.color.err};
      }
    }
  `}
`;
