import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { STATICS_TABLE_GRID_COLUMNS } from "assets";

export const TableWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    border-top: 1px solid ${theme.color.gray_20};

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

export const Table = styled.table`
  ${({ theme }) => css`
    min-height: 580px;
    max-height: 880px;
    height: calc(
      100vh - ${theme.size.TABLE_WRAPPER_EXCEPT_FILTER} -
        ${theme.size.TABLE_WRAPPER_ONE_FILTER}
    );
    border-right: 1px solid ${theme.color.gray_20};
    border-left: 1px solid ${theme.color.gray_20};
    border-collapse: separate;
    font: ${theme.font.regular_13};
    overflow: auto;

    * {
      height: 40px;
    }

    & > thead {
      position: sticky;
      top: ${`calc(${theme.size.HEADER_HEIGHT} + ${theme.size.TABLE_HEADER_HEIGHT})`};
      z-index: ${theme.zIndex.STICKY};

      tr {
        border-top: 1px solid ${theme.color.gray_20};
      }
    }

    & > tbody > tr:nth-of-type(20) {
      border-bottom: 0;
    }
  `}
`;

export const Thead = styled.thead`
  ${({ theme }) => css`
    position: sticky;
    top: 0;
    z-index: ${theme.zIndex.STICKY};
  `}
`;

export const Tbody = styled.tbody`
  height: 500px;
  overflow: auto;
`;

export const FirstHeadTr = styled.tr`
  ${({ theme }) => css`
    display: flex;
    border-top: 1px solid ${theme.color.gray_20};
    font: ${theme.font.medium_13};

    & > th {
      height: 42px;
      border-bottom: 1px solid ${theme.color.gray_20};
      padding: 10px 0;
    }

    & > :nth-of-type(1) {
      min-width: 246px;
      min-height: 80px;
      padding: 30px 12px 30px 24px;
      color: ${theme.color.gray_60};
      text-align: left;
      background-color: ${theme.color.gray_10};
    }

    & > th:nth-of-type(2) {
      min-width: 569px;
      color: ${theme.color.blue_10};
      vertical-align: middle;
      background-color: ${theme.color.blue_10_10};
    }

    & > :nth-of-type(3) {
      min-width: 479px;
      color: ${theme.color.gray_80};
      vertical-align: middle;
      background-color: ${theme.color.gray_30};
    }
  `}
`;

export const SecondHeadTr = styled.tr`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;

    & > th {
      width: 90px;
      border-top: 1px solid ${theme.color.gray_20};
      border-bottom: 1px solid ${theme.color.gray_20};
    }

    & > th:nth-of-type(6),
    & > th:nth-of-type(11) {
      font: ${theme.font.medium_13};
    }

    & > th {
      width: 90px;
      background-color: ${theme.color.gray_10};
    }

    & > th:first-of-type,
    & > th:nth-of-type(7) {
      width: 110px;
      padding: 0 12px 0 32px;
    }

    & > :not(th:first-of-type),
    & > :not(th:nth-of-type(7)) {
      padding: 11px 12px;
      text-align: right;
    }

    & > th:nth-of-type(6) {
      width: 99px;
      color: ${theme.color.blue_10};
      background-color: ${theme.color.blue_10_10};
    }

    & > th:last-of-type {
      width: 99px;
      color: ${theme.color.blue_10};
      background-color: ${theme.color.gray_20};
    }
  `}
`;

export const BodyTr = styled.tr`
  ${({ theme }) => css`
    position: relative;
    display: grid;
    grid-template-columns: ${STATICS_TABLE_GRID_COLUMNS};
    border-bottom: 1px solid ${theme.color.gray_20};

    &:nth-of-type(20) {
      border-bottom: 0;

      & > td:nth-of-type(1),
      & > td:nth-of-type(7),
      & > td:last-of-type {
        border-bottom: 0;
      }
    }

    & :not(:nth-of-type(1)) {
      padding: 0x 12px;
      text-align: right;
    }

    & > td:nth-of-type(1) {
      padding: 0 0 0 24px;
      color: ${theme.color.black};
      background-color: ${theme.color.gray_10};
      border-bottom: 1px solid ${theme.color.gray_20};
    }

    & > td:nth-of-type(7) {
      background-color: ${theme.color.blue_sub};
      border-bottom: 1px solid ${theme.color.gray_20};
    }

    & > td:last-of-type {
      background-color: ${theme.color.gray_10};
      border-bottom: 1px solid ${theme.color.gray_20};
    }
  `}
`;

export const Td = styled.td`
  ${({ theme }) => css`
    display: block;
    padding: 0px 12px;
    font: ${theme.font.regular_13};
    color: ${theme.color.black};
    text-align: left;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;
