import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { ArrowIcon } from "assets/icon";

interface RootProps {
  as?: string;
}

export const Root = styled.dialog<RootProps>`
  ${({ theme, as }) => css`
    position: ${as === "dialog" ? "absolute" : "static"};
    top: calc(100% + 4px);
    width: 292px;
    height: 408px;
    border: 1px solid ${theme.color.gray_20};
    border-radius: 5px;
    padding: 19px;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.shadow_regular};
    z-index: ${theme.zIndex.CALENDAR};
  `}
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const MonthYear = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;

    & > span {
      ${theme.font.medium_16};
      color: ${theme.color.black};
    }

    &[aria-expanded="true"] {
      svg {
        transform: rotate(180deg);
      }
    }

    :hover {
      > div:last-of-type {
        border-radius: 50%;
        background-color: ${theme.color.gray_10};
      }
    }
  `}
`;

export const MonthYearContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    column-gap: 4px;

    span {
      font: ${theme.font.medium_15};
    }
  `}
`;

export const MonthWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 20px;

  & > li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
  }
`;

export const MonthBtn = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border-radius: 100px;
    font: ${theme.font.regular_14};
    color: ${theme.color.black};

    &:hover {
      background-color: ${theme.color.blue_10_10};
    }

    &[aria-selected="false"] {
      &[aria-current="true"] {
        border: 1px solid ${theme.color.black};

        &:hover {
          background-color: ${theme.color.gray_10};
        }
      }
    }

    &[aria-selected="true"] {
      color: ${theme.color.white};
      background-color: ${theme.color.blue_10};
    }
  `}
`;

export const OpenMonthWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    & > svg {
      fill: ${theme.color.gray_60};
    }
  `}
`;

export const WeekRow = styled.ul`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(7, 36px);
    margin-bottom: 12px;

    > li {
      font: ${theme.font.regular_12};
      color: ${theme.color.gray_70};
      text-align: center;
    }
  `}
`;

export const DateRow = styled.ul`
  ${({ theme }) => css`
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 36px);
    row-gap: 4px;
    margin-bottom: 16px;
    font: ${theme.font.regular_13};
    color: ${theme.color.gray_70};
  `}
`;

export const MoveBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const MoveBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    & > svg {
      fill: ${theme.color.gray_60};
    }

    :hover {
      border-radius: 50%;
      background-color: ${theme.color.gray_10};
    }
  `}
`;

export const ArrowLeftIcon = styled(ArrowIcon)`
  transform: rotate(90deg);
`;

export const ArrowRightIcon = styled(ArrowIcon)`
  transform: rotate(-90deg);
`;

export const BtnWrapper = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const ResetBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 79px;
    height: 40px;
    border-radius: 2px;
    padding: 0 18px;
    border: 1px solid ${theme.color.gray_20};
    font: ${theme.font.medium_14};
    color: ${theme.color.gray_60};
    background-color: ${theme.color.gray_10};

    &:not(:disabled):hover {
      border: 1px solid ${theme.color.gray_30};
      background-color: ${theme.color.gray_20};
    }

    &:disabled {
      opacity: 0.6;
    }
  `}
`;

export const ApplyBtn = styled.button`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 74px;
    height: 40px;
    border-radius: 2px;
    padding: 0 18px;
    font: ${theme.font.medium_14};
    color: ${theme.color.white};
    background-color: ${theme.color.blue_10};

    &:not(:disabled):hover {
      background-color: ${theme.color.blue_20};
    }

    &:disabled {
      opacity: 0.6;
    }
  `}
`;
