import React, { useEffect } from "react";

import { ArrowIcon, ImgRotateIcon, ZoomInIcon, ZoomOutIcon } from "assets/icon";
import { alternateImage } from "assets/image";
import { useImgZoom, useImgPen, useImgRotate, useImgBtn } from "./hooks";
import * as S from "./ImgZoom.styled";

interface ImgZoomProps {
  imgArr: string[];
}

const ImgZoom = ({ imgArr }: ImgZoomProps) => {
  const { imgIdx, handlePrevImg, handleNextImg } = useImgBtn();
  const {
    minZoomLevel,
    maxZoomLevel,
    zoomLevel,
    zoomValue,
    handleChangeZoomLevel,
    handleZoomIn,
    handleZoomOut,
  } = useImgZoom(1, 5, imgIdx);
  const { position, handleMouseDown } = useImgPen(zoomLevel);
  const { rotate, handleLeftRotate } = useImgRotate(imgIdx);

  const handleErrorImg = (e: any) => {
    e.target.src = alternateImage;
  };

  useEffect(() => {
    const images = [];

    imgArr.forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl;
      images.push(img);
    });
  }, []);

  return (
    <S.Root>
      {imgArr && (
        <>
          <S.ImgWrapper onMouseDown={handleMouseDown}>
            <S.Img
              draggable="true"
              src={imgArr[imgIdx]}
              alt="이미지"
              rangeValue={zoomLevel}
              position={position}
              rotate={rotate}
              onError={handleErrorImg}
            />
          </S.ImgWrapper>
          <S.BtnWrapper>
            <S.Btn onClick={handlePrevImg} disabled={imgIdx === 0}>
              <S.LeftArrowIcon />
            </S.Btn>
            <span>{`${imgIdx + 1} / ${imgArr.length}`}</span>
            <S.Btn
              onClick={handleNextImg}
              disabled={imgIdx === imgArr.length - 1}
            >
              <ArrowIcon />
            </S.Btn>
          </S.BtnWrapper>
          <S.ZoomBtnWrapper>
            <S.RotateBtn type="button">
              <ImgRotateIcon
                width={24}
                height={24}
                onClick={handleLeftRotate}
              />
            </S.RotateBtn>
            <S.Btn type="button" onClick={handleZoomOut}>
              <ZoomOutIcon width={20} height={20} />
            </S.Btn>
            <S.RangeBar
              type="range"
              value={zoomLevel}
              zoomValue={zoomValue}
              min={minZoomLevel.current}
              max={maxZoomLevel.current}
              onChange={handleChangeZoomLevel}
            />
            <S.Btn onClick={handleZoomIn}>
              <ZoomInIcon width={20} height={20} />
            </S.Btn>
          </S.ZoomBtnWrapper>
        </>
      )}
    </S.Root>
  );
};

export default ImgZoom;
