import React from "react";

import { selectFilterName } from "utils/helper/filter";
import { CloseIcon, ResetIcon } from "assets/icon";
import * as S from "./FilterSearchLabel.styled";

interface FilterSearchLabelProps {
  filterKeyNames: { searchLabel: { key: string; name: string }[] }[];
  searchLabel: { [key: string]: string | string[] };
  handleClickReset: () => void;
  handleDeleteSearchLabel: (target: {
    key: string;
    value: string;
  }) => () => void;
}

const FilterSearchLabel = ({
  filterKeyNames,
  searchLabel,
  handleClickReset,
  handleDeleteSearchLabel,
}: FilterSearchLabelProps) => {
  return (
    <S.SearchLabelBoxWrapper>
      <S.ResetBtn type="button" onClick={handleClickReset}>
        <ResetIcon />
        초기화
      </S.ResetBtn>
      <S.SearchLabelWrapper>
        {Object.entries(searchLabel).map(([key, value], i) => {
          const searchName = selectFilterName(filterKeyNames, key);

          return [value].flat().map((item) => (
            <li key={item + i}>
              <S.SearchLabel>
                <span>
                  <S.SearchName>{searchName}:</S.SearchName>
                  <S.SearchValue>{item}</S.SearchValue>
                </span>
                <S.CloseBtn
                  type="button"
                  onClick={handleDeleteSearchLabel({ key, value: item })}
                >
                  <CloseIcon />
                </S.CloseBtn>
              </S.SearchLabel>
            </li>
          ));
        })}
      </S.SearchLabelWrapper>
    </S.SearchLabelBoxWrapper>
  );
};

export default FilterSearchLabel;
