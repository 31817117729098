import React from "react";
import { useParams } from "react-router-dom";
import dompurify from "dompurify";

import { Division, LabelContent } from "components";
import { useGetNoticeSupportDetail } from "services";
import * as S from "./NoticeSupportDetailTable.styled";

const NoticeSupportDetailTable = () => {
  const { id } = useParams();
  const { data } = useGetNoticeSupportDetail(id!);

  const sanitizer = dompurify.sanitize;

  if (!data) return null;

  return (
    <>
      <Division css={S.division} />
      <LabelContent css={S.labelContent} heading="상세 내용" hasBg>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content heading="위치">
            {data.type}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={2}>
          <LabelContent.Content heading="등록 일시">
            {data.created}
          </LabelContent.Content>
          <LabelContent.Content heading="수정 일시">
            {data.updated}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content heading="제목">
            {data.title}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content css={S.content} heading="내용">
            <S.Description
              dangerouslySetInnerHTML={{
                __html: sanitizer(data.content),
              }}
            />
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
      </LabelContent>
    </>
  );
};

export default NoticeSupportDetailTable;
