import React from "react";

import { QueryPagination } from "components";
import { useGetRepairshops } from "services";
import { useFilterParams } from "hooks";
import type { GetRepairshopsQueryModel } from "types";

const ReparishopPagination = () => {
  const { filters } = useFilterParams<GetRepairshopsQueryModel["query"]>([
    "page",
    "brandId",
    "status",
    "available",
    "name",
  ]);

  const { data } = useGetRepairshops({
    query: filters,
  });

  return <QueryPagination pageInfo={data?.pageInfo!} />;
};

export default ReparishopPagination;
