import React from "react";

import { LabelContentSkeleton } from "components";
import {
  REPARISHOP_DETAIL_REGISTRATION_INFO,
  REPARISHOP_DETAIL_SETTING,
} from "assets";
import * as S from "./RepairshopDataTable.styled";

const RepairshopDataTableSkeleton = () => {
  return (
    <>
      <LabelContentSkeleton
        css={S.registrationLabelContent}
        hasBg
        heading="등록정보"
        info={REPARISHOP_DETAIL_REGISTRATION_INFO}
      />
      <LabelContentSkeleton
        css={S.labelContent}
        hasBg
        heading="설정"
        info={REPARISHOP_DETAIL_SETTING}
      />
    </>
  );
};

export default RepairshopDataTableSkeleton;
