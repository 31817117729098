import { useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

const useCheckTableWithCondition = <T extends { noticeId: string }>(
  tableDatas: T[] = [],
  condition?: (tableRowData: T) => boolean,
  isCheckableTable = false,
) => {
  const [searchParams] = useSearchParams();

  const [isCheckTable, setIsCheckTable] = useState(isCheckableTable);
  const [checkedIds, setCheckedIds] = useState<string[]>([]);
  const isChecked = (noticeId: string) => checkedIds.includes(noticeId);

  const checkableIds = useMemo(() => {
    const data = condition ? tableDatas.filter(condition) : tableDatas;
    return data.map((tableData) => tableData.noticeId);
  }, [tableDatas, condition]);

  const isCheckable = (noticeId: string) => checkableIds.includes(noticeId);

  const isAllChecked = () => {
    if (!checkableIds.length) return false;

    return checkableIds.length === checkedIds.length;
  };

  const handleCheck = (noticeId: string) => () =>
    checkedIds.includes(noticeId)
      ? setCheckedIds(checkedIds.filter((checkId) => checkId !== noticeId))
      : setCheckedIds([...checkedIds, noticeId]);

  const handleAllCheck = () =>
    isAllChecked() ? setCheckedIds([]) : setCheckedIds(checkableIds);

  const handleTransformCheckTable = () => {
    setIsCheckTable(true);
  };
  const handleTransformTable = () => {
    setIsCheckTable(false);
  };

  const handleAllUnCheck = () => setCheckedIds([]);

  useEffect(() => {
    setCheckedIds([]);
    setIsCheckTable(false);
  }, [searchParams.get("page")]);

  return {
    isCheckTable,
    isCheckable,
    isChecked,
    isAllChecked,
    checkedIds,
    handleCheck,
    handleAllCheck,
    handleAllUnCheck,
    handleTransformCheckTable,
    handleTransformTable,
  };
};

export default useCheckTableWithCondition;
