import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";

import * as S from "./AnswerTextarea.styled";

interface TextareaProps {
  maxLength: number;
  textareaValue: string;
  register: UseFormRegisterReturn;
  className?: string;
}

const AnswerTextarea = ({
  maxLength,
  textareaValue,
  register,
  className,
}: TextareaProps) => {
  return (
    <S.AnswerTextarea className={className}>
      <S.Text
        placeholder="답글을 입력하세요."
        maxLength={maxLength}
        {...register}
      />
      <S.Length>{`(${textareaValue?.length ?? 0}/${maxLength})`}</S.Length>
    </S.AnswerTextarea>
  );
};

AnswerTextarea.displayName = "AnswerTextarea";

export default AnswerTextarea;
