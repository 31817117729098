import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const ErrorMsg = styled.span`
  ${({ theme }) => css`
    display: block;
    font: ${theme.font.regular_13};
    color: ${theme.color.red_30};
  `}
`;
