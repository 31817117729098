import React from "react";
import { useParams } from "react-router-dom";

import {
  PageLayout,
  TruckOwnerInfo,
  ImgZoom,
  TruckDetailTable,
  TruckDetailCompleteTable,
} from "components";
import { useGetTruckDetail } from "services";
import { TRUCK_DETAIL_PAGE_LAYOUT } from "assets";
import * as S from "./TruckDetail.styled";

const TruckDetail = () => {
  const { id } = useParams();

  const { data } = useGetTruckDetail(id!);

  if (!data) return null;

  return (
    <PageLayout css={S.pageLayout} layoutStaticData={TRUCK_DETAIL_PAGE_LAYOUT}>
      <TruckOwnerInfo
        css={S.truckOwnerInfo}
        ownerName={data.userName}
        status={data.status}
      />
      <S.FlexWrapper>
        <ImgZoom imgArr={data.imgUrls} />
        {data.status === "등록완료" || data.status === "반려" ? (
          <TruckDetailCompleteTable data={data} />
        ) : (
          <TruckDetailTable data={data} />
        )}
      </S.FlexWrapper>
    </PageLayout>
  );
};

export default TruckDetail;
