import { useState, useEffect, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { map } from "lodash-es";

import { formatDate } from "utils/helper/formatter";
import { getQueryDate, getAllQuery } from "utils/helper/filter";
import type { FilterCondition } from "types";

const useFilterCalendar = (condition: FilterCondition) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedDates, setSelectedDates] = useState<string[]>(
    getQueryDate(searchParams),
  );

  const selectedDate = selectedDates[0]
    ? `${selectedDates[0]} ~ ${selectedDates[selectedDates.length - 1]}`
    : "";

  const handleChangeFilterDate = useCallback(
    (date: dayjs.Dayjs[] | []) => {
      if (date.length === 0) {
        setSelectedDates([]);
      } else {
        const [dateStart, dateEnd] = map(date, (d) =>
          formatDate(d, "YYYY/MM/DD"),
        );
        setSelectedDates([dateStart, dateEnd ?? dateStart]);

        setSearchParams({
          ...getAllQuery(searchParams),
          page: "1",
          dateStart,
          dateEnd: dateEnd ?? dateStart,
        });
      }
    },
    [selectedDates, searchParams],
  );

  useEffect(() => {
    if (searchParams.get("dateStart")) return;
    setSelectedDates([]);
  }, [searchParams.get("dateStart")]);

  useEffect(() => {
    const dateStart = searchParams.get("dateStart");

    if (dateStart) {
      setSearchParams({
        ...getAllQuery(searchParams),
        page: "1",
      });
    }
  }, []);

  return {
    selectedDates,
    selectedDate,
    handleChangeFilterDate,
  };
};

export default useFilterCalendar;
