import React from "react";

import { Header, Navbar } from "components";
import * as S from "./PrivateLayout.styled";

interface PrivateLayoutProps {
  children: React.ReactNode;
}

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  return (
    <>
      <Header />
      <Navbar />
      <S.PrivateMain>{children}</S.PrivateMain>
    </>
  );
};

export default PrivateLayout;
