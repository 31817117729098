import React from "react";
import { v4 as uuidv4 } from "uuid";

import * as S from "./CheckboxBtn.styled";

interface CheckboxBtnProps {
  disabled?: boolean;
  isChecked?: boolean;
  handleCheck?: () => void;
}

const CheckboxBtn = ({
  disabled,
  isChecked = false,
  handleCheck,
}: CheckboxBtnProps) => {
  const uuid = uuidv4();

  return (
    <S.Wrapper>
      <S.Input
        disabled={disabled}
        checked={isChecked}
        type="checkbox"
        readOnly
        id={uuid}
        onChange={handleCheck}
      />
      <S.CheckboxClickAbleArea className="label" htmlFor={uuid} tabIndex={0}>
        <S.CheckBox className="box" />
      </S.CheckboxClickAbleArea>
    </S.Wrapper>
  );
};

export default CheckboxBtn;
