import axios from "axios";
import { ax } from "api/axios";

import { getS3FileFormData, makeBase64ImageToFile } from "utils";
import type { Brand, S3PresignedServerModel } from "types";

export const getTruckBrandAPI = async () => {
  const { data } = await ax.get<{ brands: Brand[] }>("/brands");

  return data.brands;
};

export const getPresignedAPI = async (presignedKey: string) => {
  const { data } = await ax.get<{ url: string }>(`/presigned/${presignedKey}`);

  return data.url;
};

export const getDistrictAPI = () => ax.get(`/districts`);

export const sendPublicS3PresignedAPI = async (
  key: string,
  file: File,
): Promise<any> => {
  const res: S3PresignedServerModel = await ax.post(`/presigned/public/${key}`);

  const formData = getS3FileFormData(res, file);

  return await axios.post(res.data.url, formData);
};

export const uploadBase64ImageToS3API = async (htmlCode: string) => {
  const base64ImageRegex = /<img src="(data:image\/[^;]+;base64[^"]+)"/g;

  const fileNames: string[] = [];

  let updatedContent = htmlCode;

  let match;
  while ((match = base64ImageRegex.exec(htmlCode)) !== null) {
    const base64Image = match[1];

    const { file, fileName } = makeBase64ImageToFile(base64Image);

    fileNames.push(fileName);

    const { status } = await sendPublicS3PresignedAPI(fileName, file);

    if (status !== 204) return null;

    updatedContent = updatedContent.replace(
      base64Image,
      `${process.env.REACT_APP_AWS_URL}/public/${fileName}`,
    );
  }

  return { content: updatedContent, fileNames };
};
