import React, { Suspense } from "react";

import { Filter, PaginationSkeleton } from "components";
import { REPORT_COMMENT_FILTER_CONDITIONS } from "assets";
import {
  CommentReportTable,
  CommentReportTableSkeleton,
  CommentPagination,
} from "./containers";

const CommentReport = () => {
  return (
    <>
      <Filter filterConditions={REPORT_COMMENT_FILTER_CONDITIONS} />
      <Suspense fallback={<CommentReportTableSkeleton />}>
        <CommentReportTable />
      </Suspense>
      <Suspense fallback={<PaginationSkeleton />}>
        <CommentPagination />
      </Suspense>
    </>
  );
};

export default CommentReport;
