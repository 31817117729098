import React, { Suspense } from "react";

import {
  Filter,
  RepairshopSupportPagination,
  RepairshopSupportTable,
  RepairshopSupportTableSkeleton,
  PaginationSkeleton,
} from "components";
import { SUPPORT_REPAIRSHOP_FILTER_CONDITIONS } from "assets";

const RepairshopSupport = () => {
  return (
    <>
      <Filter filterConditions={SUPPORT_REPAIRSHOP_FILTER_CONDITIONS} />
      <Suspense fallback={<RepairshopSupportTableSkeleton />}>
        <RepairshopSupportTable />
      </Suspense>
      <Suspense fallback={<PaginationSkeleton />}>
        <RepairshopSupportPagination />
      </Suspense>
    </>
  );
};

export default RepairshopSupport;
