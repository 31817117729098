import React from "react";

import { LabelContent, WriteAnswer, Division } from "components";
import { useCreateAnswerRepairshopSupport } from "services";
import type { GetRepairshopSupportDetailServerModel } from "types";
import * as S from "./RepairshopSupportForm.styled";

interface RepairshopSupportFormProps {
  data: GetRepairshopSupportDetailServerModel;
}
const RepairshopSupportForm = ({ data }: RepairshopSupportFormProps) => {
  const { mutate: registAnswerRepairshopMutate } =
    useCreateAnswerRepairshopSupport();

  return (
    <>
      <Division css={S.division} />
      {data.status === "답변완료" ? (
        <LabelContent heading="답글 상세" hasBg>
          <LabelContent.ContentWrapper subItemCount={1}>
            <LabelContent.Content heading="제목">
              {data.answerTitle}
            </LabelContent.Content>
          </LabelContent.ContentWrapper>
          <LabelContent.ContentWrapper subItemCount={1}>
            <LabelContent.Content css={S.answerContent} heading="내용">
              {data.answerContent}
            </LabelContent.Content>
          </LabelContent.ContentWrapper>
        </LabelContent>
      ) : (
        <WriteAnswer
          helpId={data.helpId}
          submitFn={registAnswerRepairshopMutate}
        />
      )}
    </>
  );
};

export default RepairshopSupportForm;
