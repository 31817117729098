import React, { Suspense } from "react";

import { Filter, PaginationSkeleton } from "components";
import { REPORT_REVEIW_FILTER_CONDITIONS } from "assets";
import {
  ReviewReportTable,
  ReviewReportTableSkeleton,
  ReviewPagination,
} from "./containers";

const ReviewReport = () => {
  return (
    <>
      <Filter filterConditions={REPORT_REVEIW_FILTER_CONDITIONS} />
      <Suspense fallback={<ReviewReportTableSkeleton />}>
        <ReviewReportTable />
      </Suspense>
      <Suspense fallback={<PaginationSkeleton />}>
        <ReviewPagination />
      </Suspense>
    </>
  );
};

export default ReviewReport;
