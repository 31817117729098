import React, { Suspense } from "react";

import {
  Filter,
  OwnerSupportPagination,
  OwnerSupportTable,
  OwnerSupportTableSkeleton,
} from "components";
import { SUPPORT_OWNER_FILTER_CONDITIONS } from "assets";
import { PaginationSkeleton } from "components/common";

const OwnerSupport = () => {
  return (
    <>
      <Filter filterConditions={SUPPORT_OWNER_FILTER_CONDITIONS} />
      <Suspense fallback={<OwnerSupportTableSkeleton />}>
        <OwnerSupportTable />
      </Suspense>
      <Suspense fallback={<PaginationSkeleton />}>
        <OwnerSupportPagination />
      </Suspense>
    </>
  );
};

export default OwnerSupport;
