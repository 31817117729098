import React from "react";

import { ImgPreviewLinks, LabelContent, Division } from "components";
import type { GetOwnerSupportDetailItem } from "types";
import * as S from "./OwnerSupportDetailTable.styled";

interface OwnerSupportDetailTableProps {
  data: GetOwnerSupportDetailItem;
}

const OwnerSupportDetailTable = ({ data }: OwnerSupportDetailTableProps) => {
  return (
    <>
      <Division css={S.division} />
      <LabelContent css={S.labelContent} heading="문의글 상세" hasBg>
        <LabelContent.ContentWrapper subItemCount={2}>
          <LabelContent.Content heading="문의 유형">
            {data.category}
          </LabelContent.Content>
          <LabelContent.Content heading="등록 일시">
            {data.created}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content heading="작성자명(차주)">
            {data.userName}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content heading="제목">
            {data.title}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        {!!data.imgUrls.length && (
          <LabelContent.ContentWrapper subItemCount={1}>
            <LabelContent.Content heading="첨부 이미지">
              <ImgPreviewLinks imgUrls={data.imgUrls} />
            </LabelContent.Content>
          </LabelContent.ContentWrapper>
        )}
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content css={S.content} heading="문의내용">
            {data.content}
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
      </LabelContent>
    </>
  );
};

export default OwnerSupportDetailTable;
