import type { AxiosResponse } from "axios";

import { ax } from "api/axios";
import { getPresignedAPI } from "./commonApi";
import type {
  GetTrucksQueryModel,
  GetTrucksServerModel,
  GetTruckDetailServerModel,
  ApprovalTruckQueryModel,
  DenyTruckQueryModel,
  TruckVinServerModel,
} from "types";

export const getTruckListAPI = async (req: GetTrucksQueryModel) => {
  const { data } = await ax.get<GetTrucksServerModel>("/admin/trucks", {
    params: req.query,
  });

  return data;
};

export const getTruckDetailAPI = async (
  truckId: string,
): Promise<GetTruckDetailServerModel> => {
  const { data }: AxiosResponse<GetTruckDetailServerModel> = await ax.get(
    `/admin/trucks/${truckId}`,
  );

  const imgUrls = [];

  imgUrls.push(data.register ? getPresignedAPI(data.register) : "");
  imgUrls.push(data.front ? getPresignedAPI(data.front) : "");

  const [register, front] = await Promise.all(imgUrls);

  return {
    ...data,
    imgUrls: [register ?? "", front ?? ""],
  };
};

export const approvalTruckAPI = (req: ApprovalTruckQueryModel) =>
  ax.patch(`/admin/trucks/${req.truckId}/admit`, {
    addr: req.body.addr,
    brandId: req.body.brandId,
    modelName: req.body.modelName,
    modelYear: req.body.modelYear,
    regNum: req.body.regNum,
    vin: req.body.vin,
  });

export const denyTruckAPI = (req: DenyTruckQueryModel) =>
  ax.patch(`/admin/trucks/${req.truckId}/deny`, req.body);

export const getCheckVinAPI = async (vin: string) => {
  const { data } = await ax.get<TruckVinServerModel>(`/admin/trucks/${vin}`);

  return data;
};

export const deleteTruckAPI = (truckId: string) =>
  ax.delete(`/admin/trucks/${truckId}`);
