import React from "react";

import { ImgPreviewLinks, LabelContent, CommentContent } from "components";
import type { GetCommentReportDetailClientModel } from "types";
import * as S from "./Comment.styled";

interface CommentProps {
  data: GetCommentReportDetailClientModel;
}

const Comment = ({ data }: CommentProps) => {
  const commentContent = data.comments.find(
    (item) => item.nickname === data.commentUserNickname,
  );

  return (
    <LabelContent css={S.labelContent} hasBg heading="댓글 상세">
      <LabelContent.ContentWrapper subItemCount={2}>
        <LabelContent.Content heading="작성자">
          {data.commentUserNickname}
        </LabelContent.Content>
        <LabelContent.Content heading="작성 일시">
          {data.postCreated}
        </LabelContent.Content>
      </LabelContent.ContentWrapper>
      <LabelContent.ContentWrapper subItemCount={1}>
        <LabelContent.Content heading="내용">
          {commentContent && commentContent.content}
        </LabelContent.Content>
      </LabelContent.ContentWrapper>
      <LabelContent.ContentWrapper subItemCount={1}>
        <LabelContent.Content css={S.content} heading="게시글 내용">
          {data.postContent}
        </LabelContent.Content>
      </LabelContent.ContentWrapper>
      {!!data.imgUrls.length && (
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content heading="첨부 이미지">
            <ImgPreviewLinks imgUrls={data.imgUrls} />
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
      )}
      <LabelContent.ContentWrapper subItemCount={1}>
        <LabelContent.Content heading="댓글 내역">
          <CommentContent
            commentData={data.comments}
            commentId={data.commentId}
          />
        </LabelContent.Content>
      </LabelContent.ContentWrapper>
    </LabelContent>
  );
};

export default Comment;
