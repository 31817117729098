/* eslint-disable no-useless-escape */

export const checkPasswordValidationError = (password: any) => {
  //  8~16자의 영문 대문자, 숫자, 특수문자 중 최소 2종류
  const checkSpeacial = /(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])/; //  특수 문자 체크
  const checkNumber = /(?=.*[0-9])/; //  숫자 체크
  const checkBigLetter = /(?=.*[A-Z])/; //  대문자 체크
  const checkSmallLetter = /(?=.*[a-z])/; // 소문자 체크
  const checkLength = /^.{8,16}$/; //  길이 체크

  const checkList = [
    checkSpeacial.test(password),
    checkNumber.test(password),
    checkBigLetter.test(password),
    checkSmallLetter.test(password),
  ];

  const boolenCheckThreeOfTwo =
    checkList.filter((element) => true === element).length >= 2;
  // 4개 중 2개가 true 이면 true

  return !(checkLength.test(password) && boolenCheckThreeOfTwo);
};

export const checkEmailValidation = (email: any) => {
  const checkEmail = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;

  return checkEmail.test(email);
};

export const checkNumberValidate = (number: any) => {
  const check = /[^0-9]/g;

  return (number = number.replace(check, ""));
};

export const checkPhoneNumberValidation = (phoneNumber: any) => {
  const regExp = /^\d{3}-\d{3,4}-\d{4}$/;

  return regExp.test(phoneNumber);
};

export const checkTelephoneNumberValidation = (telephoneNumber: any) => {
  const regExp = /^\d{2,3}-\d{3,4}-\d{4}$/;

  return regExp.test(telephoneNumber);
};

export const checkPasswordThreeValidation = (password: string) => {
  //  8~16자의 영문 대문자, 숫자, 특수문자 중 최소 3종류
  const checkSpeacial = /(?=.*[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/])/; //  특수 문자 체크
  const checkNumber = /(?=.*[0-9])/; //  숫자 체크
  const checkBigLetter = /(?=.*[A-Z])/; //  대문자 체크
  const checkSmallLetter = /(?=.*[a-z])/; // 소문자 체크

  const checkList = [
    checkSpeacial.test(password),
    checkNumber.test(password),
    checkBigLetter.test(password),
    checkSmallLetter.test(password),
  ];

  const boolenCheckFourOfThree =
    checkList.filter((element) => true === element).length >= 3;
  // 4개 중 3개가 true 이면 true

  return !boolenCheckFourOfThree;
};

export const checkPasswordLengthValidation = (password: string) => {
  const checkLength = /^.{8,16}$/; //  길이 체크

  return !checkLength.test(password);
};
