import styled from "@emotion/styled";
export const Header = styled.header`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 0 40px;
  background-color: ${({ theme }) => theme.color.header};
  z-index: ${({ theme }) => theme.zIndex.HEADER_FOOTER};

  button {
    font: ${({ theme }) => theme.font.regular_13};
    color: ${({ theme }) => theme.color.gray_70};
  }
`;
