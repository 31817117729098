import React from "react";

import {
  ListPageLayout,
  BulletinReport,
  CommentReport,
  ReviewReport,
  Tab,
  UserReport,
} from "components";
import * as S from "./Report.styled";

const Report = () => {
  const REPORT_TABS = {
    review: { component: <ReviewReport />, tabName: "리뷰 신고" },
    user: { component: <UserReport />, tabName: "유저 신고" },
    bulletin: { component: <BulletinReport />, tabName: "게시글 신고" },
    comment: { component: <CommentReport />, tabName: "댓글 신고" },
  };

  return (
    <ListPageLayout css={S.heading} heading="신고 관리">
      <Tab tabList={REPORT_TABS} />
    </ListPageLayout>
  );
};
export default Report;
