import React from "react";
import { useParams } from "react-router-dom";

import {
  PageLayout,
  OwnerSupportDetailTable,
  OwnerSupportForm,
} from "components";
import { useGetOwnerSupportDetail } from "services";
import { OWNER_SUPPORT_DETAIL_PAGE_LAYOUT } from "assets";
import * as S from "./OwnerSupportDetail.styled";

const OwnerSupportDetail = () => {
  const { id } = useParams();

  const { data } = useGetOwnerSupportDetail(id!);

  if (!data) return null;

  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={OWNER_SUPPORT_DETAIL_PAGE_LAYOUT}
    >
      <OwnerSupportDetailTable data={data} />
      <OwnerSupportForm data={data} />
    </PageLayout>
  );
};

export default OwnerSupportDetail;
