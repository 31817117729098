import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const TextareaWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 13px 20px 37px;
`;

export const TextareaContent = styled.textarea`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    border: 0;
    font: ${theme.font.regular_14};
    resize: none;

    &[aria-invalid="true"] {
      border: 1px solid ${theme.color.red_20};
    }

    :disabled {
      color: inherit;
      background-color: inherit;
    }

    ::placeholder {
      color: ${theme.color.gray_50};
    }
  `}
`;

export const Length = styled.span`
  ${({ theme }) => css`
    position: absolute;
    bottom: 13px;
    left: 20px;
    color: ${theme.color.gray_50};
  `}
`;

export const Box = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 13px;
    left: 20px;
    width: 100%;
    height: 80px;
    background-color: ${theme.color.gray_10};
  `}
`;
