import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const fieldTableForm = (theme: Theme) => css`
  width: 476px;
  margin-bottom: 20px;

  tr > th {
    padding: 14px 20px;
  }

  tr:last-of-type {
    padding: 0;

    th {
      padding: 13px 20px;
    }
  }

  caption {
    font: ${theme.font.medium_13};
  }
`;

export const row = (theme: Theme) => css`
  border-top: 1px solid ${theme.color.gray_20};
  margin-top: 14px;

  td {
    padding: 0;
  }

  td > div:nth-of-type(1) {
    padding: 13px 20px;
  }

  td > div:nth-of-type(2) {
    width: 100%;
    height: 240px;

    & > span {
      left: 20px;
    }
  }
`;

export const input = (theme: Theme) => css`
  height: 20px;

  & > input {
    border: 0;
    padding: 0;

    ::placeholder {
      font: ${theme.font.regular_13};
    }

    :focus {
      border: 0;
    }
  }
`;

export const vinWrapper = css`
  position: relative;
`;

export const vinInput = (theme: Theme) => css`
  height: 20px;

  & > input {
    border: 0;
    padding: 0 83px 0 0;

    ::placeholder {
      font: ${theme.font.regular_13};
    }

    :focus {
      border: 0;
    }
  }
`;

export const vinButton = (theme: Theme, isVinCheck: boolean) => css`
  position: absolute;
  right: 12px;
  top: 9px;
  height: 28px;
  border-radius: 2px;
  border: 0;
  padding: 5px 10px;
  font: ${theme.font.medium_13};
  color: ${isVinCheck ? theme.color.green_20 : theme.color.blue_10};
  background-color: ${!isVinCheck ? theme.color.gray_10 : "transparent"};

  :focus {
    border: 0;
    color: ${isVinCheck ? theme.color.green_20 : theme.color.blue_20};
    background-color: ${!isVinCheck && theme.color.gray_20};
  }

  &:not(:disabled):hover {
    border: 0;
    color: ${isVinCheck ? theme.color.green_20 : theme.color.blue_20};
    background-color: ${isVinCheck ? "transparent" : theme.color.gray_20};
    cursor: ${isVinCheck && "not-allowed"};
  }

  :disabled {
    color: ${theme.color.blue_10};
    background-color: ${theme.color.gray_10};
    opacity: 0.2;
    cursor: not-allowed;
  }
`;

export const dropdown = (theme: Theme) => css`
  height: 20px;

  & > button {
    border: 0;
    padding: 0;

    &[aria-expanded="true"] {
      border: 0;
    }
  }

  & > ul {
    top: 40px;
    left: -13px;
    width: 298px;
    height: 180px;

    & > li > {
      button {
        min-height: 38px;

        &[data-selected="true"] {
          background-color: ${theme.color.blue_sub};
        }
      }
    }
  }
`;

export const textarea = (theme: Theme) => css`
  width: 274px;
  height: 200px;
  border-top: 1px solid ${theme.color.gray_20};
  padding: 13px 20px 35px 20px;

  & > span {
    left: 0;
  }

  ::placeholder {
    font: ${theme.font.regular_13};
  }
`;

export const button = css`
  margin-left: auto;
`;

export const FailedReason = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    border-top: 1px solid ${theme.color.gray_20};
    padding: 13px 20px 37px;
    font: ${theme.font.regular_14};
  `}
`;

export const formRadioBtn = css`
  column-gap: 40px;
`;

export const disabledColor = (theme: Theme, isData?: boolean) => css`
  position: relative;

  & > th {
    background-color: ${isData && theme.color.gray_10};
  }

  & > td {
    color: ${isData && theme.color.black};
    background-color: ${isData && theme.color.white};
  }
`;

export const deleteBtn = (theme: Theme) => css`
  margin: 12px 0;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.5px;
    background-color: ${theme.color.gray_70};
  }

  &:not(:disabled):hover {
    &::before {
      background-color: ${theme.color.gray_80};
    }
  }
`;
