import React from "react";

import { ConfirmModal } from "components/common";

interface ReparishopDeleteModalProps {
  handleDelete: () => void;
}

const ReparishopDeleteModal = React.forwardRef(
  (
    { handleDelete }: ReparishopDeleteModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    return (
      <ConfirmModal
        ref={ref}
        title="회원(정비소) 강제 탈퇴"
        activeBtnName="탈퇴"
        btnType="alert"
        desc={"회원 강제 탈퇴 시 등록정보가 모두 삭제됩니다."}
        activeFn={handleDelete}
      />
    );
  },
);

ReparishopDeleteModal.displayName = "ReparishopDeleteModal";

export default ReparishopDeleteModal;
