import { ax } from "api/axios";

import { getPresignedAPI } from "./commonApi";
import type {
  GetReviewReportsQueryModel,
  GetReviewReportsServerModel,
  GetReviewReportDetailServerModel,
  GetUserReportsQueryModel,
  GetUserReportsServerModel,
  GetUserReportDetailServerModel,
  GetBulletinsQueryModel,
  GetBulletinsServerModel,
  GetCommentsServerModel,
  GetCommentsQueryModel,
  GetBulletinReportDetailServerModel,
  GetCommentReportDetailServerModel,
  PatchUserReportQueryModel,
  PatchBulletinReportQueryModel,
  PatchCommentReportQueryModel,
} from "types";

export const getReviewReportListAPI = async (
  req: GetReviewReportsQueryModel,
) => {
  const { data } = await ax.get<GetReviewReportsServerModel>(
    "/admin/reports/reviews",
    { params: req.query },
  );

  return data;
};

export const getReviewReportDetailAPI = async (req: string) => {
  const { data } = await ax.get<GetReviewReportDetailServerModel>(
    `/admin/reports/reviews/${req}`,
  );

  return data;
};

export const getUserReportListAPI = async (req: GetUserReportsQueryModel) => {
  const { data } = await ax.get<GetUserReportsServerModel>(
    "/admin/reports/users",
    { params: req.query },
  );

  return data;
};

export const getUserReportDetailAPI = async (req: string) => {
  const { data } = await ax.get<GetUserReportDetailServerModel>(
    `/admin/reports/users/${req}`,
  );

  return data;
};

export const userActivityBlockedAPI = (req: PatchUserReportQueryModel) =>
  ax.patch(`/admin/reports/users/${req.query.reportId}/${req.query.command}`);

export const getBulletinReportListAPI = async (req: GetBulletinsQueryModel) => {
  const { data } = await ax.get<GetBulletinsServerModel>(
    "/admin/reports/posts",
    { params: req.query },
  );

  return data;
};

export const getBulletinReportDetailAPI = async (req: string) => {
  const { data } = await ax.get<GetBulletinReportDetailServerModel>(
    `/admin/reports/posts/${req}`,
  );

  const imgUrls = await Promise.all(
    data.attach.map((item) => getPresignedAPI(item)),
  );

  return { ...data, imgUrls: imgUrls.length ? imgUrls : [] };
};

export const deleteBulletinReportDetailAPI = (req: string) =>
  ax.delete(`/admin/reports/posts/${req}`);

export const patchBulletinReport = (req: PatchBulletinReportQueryModel) =>
  ax.patch(`/admin/reports/posts/${req.query.reportId}/${req.query.command}`);

export const getCommentReportListAPI = async (req: GetCommentsQueryModel) => {
  const { data } = await ax.get<GetCommentsServerModel>(
    "/admin/reports/comments",
    { params: req.query },
  );

  return data;
};

export const getCommentReportDetailAPI = async (req: string) => {
  const { data } = await ax.get<GetCommentReportDetailServerModel>(
    `/admin/reports/comments/${req}`,
  );

  const imgUrls = await Promise.all(
    data.attach.map((item) => getPresignedAPI(item)),
  );

  return { ...data, imgUrls: imgUrls.length ? imgUrls : [] };
};

export const deleteCommentReportDetailAPI = (req: string) =>
  ax.delete(`/admin/reports/comments/${req}`);

export const patchCommentReport = (req: PatchCommentReportQueryModel) =>
  ax.patch(
    `/admin/reports/comments/${req.query.reportId}/${req.query.command}`,
  );
