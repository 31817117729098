import { useSearchParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useResetPassword } from "services";
import { useToast } from "hooks";
import { TOAST_MSG } from "assets";

interface ResetPasswordProps {
  newPassword: string;
  confirmPassword: string;
}

const useResetpasswordForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { mutate: resetPasswordMutate } = useResetPassword();

  const { addToast } = useToast();

  const handleResetPassword = (data: ResetPasswordProps) => {
    resetPasswordMutate(
      {
        id: searchParams.get("id")!,
        auth: searchParams.get("auth")!,
        email: searchParams.get("email")!,
        password: data.newPassword,
      },
      {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.PASSWORD_RESET_SUCCESS);
          navigate("/");
        },
        onError: (error) => {
          addToast({ type: "warning", content: error.response?.data.message });
        },
      },
    );
  };

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    clearErrors,
    setError,
  } = useForm({
    mode: "all",
    defaultValues: { newPassword: "", confirmPassword: "" },
  });

  return {
    register,
    watch,
    errors,
    setError,
    clearErrors,
    handleSubmit: handleSubmit(handleResetPassword),
  };
};

export default useResetpasswordForm;
