import React from "react";

import { AccountInput, Button, ErrorMsg } from "components";
import { EMAIL_REG } from "utils";
import { HOOKFORM_ERR_OBJ, TruckDoctorIcon } from "assets";
import useLoginForm from "./hooks/useLoginForm";
import * as S from "./Login.styled";

const Login = () => {
  const { register, watch, errors, clearErrors, handleSubmit } = useLoginForm();

  return (
    <S.Root>
      <TruckDoctorIcon css={S.loginLogo} />
      <S.Form onSubmit={handleSubmit}>
        <S.InputWrapper>
          <AccountInput
            value={watch("email")}
            label="이메일 주소"
            id="email"
            maxLength={250}
            isError={!!errors.email}
            register={register("email", {
              required: "이메일 주소를 입력해주세요",
              pattern: {
                value: EMAIL_REG,
                message: HOOKFORM_ERR_OBJ.EMAIL_FORMAT.message,
              },
              onChange: () => clearErrors("loginError"),
            })}
          />
          <AccountInput
            value={watch("password")}
            label="비밀번호"
            id="password"
            type="password"
            maxLength={16}
            isError={!!errors.password}
            register={register("password", {
              required: "비밀번호를 입력하세요",
              onChange: () => clearErrors("loginError"),
            })}
          />
          {errors?.loginError?.message
            ? errors?.loginError?.message && (
                <ErrorMsg errorMsg={errors?.loginError?.message} />
              )
            : errors?.email?.message
            ? errors?.email?.message && (
                <ErrorMsg errorMsg={errors?.email?.message} />
              )
            : errors?.password?.message && (
                <ErrorMsg errorMsg={errors?.password?.message} />
              )}
        </S.InputWrapper>
        <Button
          css={S.submitBtn}
          variant="primary"
          type="submit"
          isDisabled={Object.keys(errors).length !== 0}
          label="로그인"
        />
      </S.Form>
    </S.Root>
  );
};

export default Login;
