import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { PageLayout, NoticeForm } from "components";
import {
  REPAIRSHOP_NOTICE_SUPPORT_EDIT_PAGE_LAYOUT,
  USER_NOTICE_SUPPORT_EDIT_PAGE_LAYOUT,
} from "assets";
import { useGetNoticeSupportDetail } from "services";
import * as S from "./EditNotice.styled";

const EditNotice = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const isRepairshopNotice = pathname.split("/")[2].includes("repairshop");

  const { data } = useGetNoticeSupportDetail(id!);

  if (!data) return null;

  return (
    <PageLayout
      css={S.pageLayout}
      layoutStaticData={
        isRepairshopNotice
          ? REPAIRSHOP_NOTICE_SUPPORT_EDIT_PAGE_LAYOUT
          : USER_NOTICE_SUPPORT_EDIT_PAGE_LAYOUT
      }
    >
      <NoticeForm data={{ title: data.title, content: data.content }} />
    </PageLayout>
  );
};

export default EditNotice;
