import React from "react";
import { useNavigate } from "react-router-dom";

import { useLogout } from "services/auth";
import { LogoIcon } from "assets/icon/index";
import { PATH } from "utils";
import * as S from "./Header.styled";

const Header = () => {
  const navigate = useNavigate();

  const { mutate: logoutMutate } = useLogout();

  const handleLogout = () => {
    logoutMutate(undefined, {
      onSuccess: () => {
        navigate(PATH.root);
      },
    });
  };

  return (
    <S.Header>
      <LogoIcon />
      <button type="button" onClick={handleLogout}>
        로그아웃
      </button>
    </S.Header>
  );
};

export default Header;
