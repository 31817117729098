import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const AccountInput = styled.div<{ hasValue: boolean }>`
  ${({ theme, hasValue }) => css`
    position: relative;
    display: flex;
    flex-flow: column;
    width: 400px;

    label {
      top: ${hasValue ? "0px" : "16px"};
      font: ${hasValue ? theme.font.regular_12 : theme.font.regular_15};
      transition-duration: 0.2s;
    }

    &:focus-within {
      label {
        top: 0px;
        font: ${theme.font.regular_12};
        transition-duration: 0.2s;
      }
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    position: absolute;
    color: ${theme.color.gray_50};
  `}
`;

export const Input = styled.input<{ isError: boolean }>`
  ${({ theme, isError }) => css`
    border: 0;
    border-bottom: 1px solid
      ${isError ? theme.color.red_30 : theme.color.gray_30};
    padding: 16px 0 12px;
    margin-bottom: 24px;
    font: ${theme.font.regular_15};

    :focus {
      border-bottom: 1px solid ${theme.color.blue_10};
      outline: none;
    }
  `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
`;

export const PasswordShowButton = styled.button`
  ${({ theme }) => css`
    width: 24px;
    height: 24px;
    background-color: inherit;

    & > svg > path {
      fill: ${theme.color.gray_40};
    }
  `}
`;
