import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Navbar = styled.nav`
  ${({ theme }) => css`
    position: fixed;
    display: flex;
    flex-flow: column;
    width: 64px;
    height: calc(100vh - 56px);
    margin-top: 56px;
    background-color: ${theme.color.gray_80};
    z-index: ${theme.zIndex.NAV};
  `}
`;

export const navLink = (theme: Theme, content: string) => css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;

  svg {
    width: 24px;
    height: 24px;
    fill: ${theme.color.nav_icon_unactive};
  }

  &:hover {
    background-color: ${theme.color.white10_10};
  }

  &.active {
    background-color: ${theme.color.white10_10};
    svg {
      fill: ${theme.color.nav_icon_active};
    }
  }

  &:hover {
    &::after {
      content: "${content}";
      position: absolute;
      top: 50%;
      left: 75px;
      transform: translateY(-50%);
      width: max-content;
      border-radius: 2px;
      padding: 2px 8px;
      font: ${theme.font.regular_11};
      color: ${theme.color.white};
      background-color: ${theme.color.gray_80};
    }
  }
`;
