import { useState } from "react";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";

import { useFetchUnrestricLogin } from "services";
import { useToast } from "hooks";
import { TOAST_MSG } from "assets";

const useUnrestrictLoginForm = () => {
  const [disabledBtn, setDisabledBtn] = useState(false);

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({ mode: "all", defaultValues: { email: "" } });
  const { addToast } = useToast();
  const { refetch } = useFetchUnrestricLogin(
    { email: watch("email").trim() },
    {
      onSuccess: () => {
        addToast(TOAST_MSG.SUCCESS.LOGIN_VERIFICATION_LINK);
        Cookies.remove("TRUCK_DOCTOR_ACCESSTOKEN");
        Cookies.remove("TRUCK_DOCTOR_REFRESHTOKEN");
        Cookies.remove("TRUCK_DOCTOR_PWUPDATED");
      },
      onError: (error: any) => {
        if (error.response.data.code === "NOT_FOUND") {
          addToast(TOAST_MSG.WARNING.UNRESTRICTED_LOGIN);
        }
      },
    },
  );

  const handleUnrestrictLogin = () => {
    refetch();
    setDisabledBtn(true);
  };

  return {
    disabledBtn,
    setDisabledBtn,
    register,
    watch,
    errors,
    handleSubmit: handleSubmit(handleUnrestrictLogin),
  };
};

export default useUnrestrictLoginForm;
