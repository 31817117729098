import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Wrapper = styled.div``;

export const Dropdown = styled.div`
  position: relative;
  height: 40px;
`;

export const DropdownBtn = styled.button<{ hasError?: boolean }>`
  ${({ hasError, theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid ${hasError ? theme.color.red_30 : theme.color.gray_30};
    padding: 0 12px;
    font: ${theme.font.regular_14};

    &[disabled] {
      background-color: ${theme.color.gray_10};
    }

    &[data-haserr="true"] {
      border-color: ${theme.color.red_30};
    }

    &[aria-expanded="true"] {
      border: 1px solid ${theme.color.blue_10};

      & > svg {
        transform: rotate(180deg);
      }
    }

    & > svg {
      fill: ${theme.color.black};
    }
  `}
`;

export const SelectedValue = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.black};
  `}
`;

export const Placeholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.color.gray_60};
  `}
`;

export const checkboxIcon = css`
  display: none;
  width: 12px;
  height: 8px;
`;

export const OptionWrapper = styled.ul<{ isOpen: boolean }>`
  ${({ theme, isOpen }) => css`
    position: relative;
    z-index: 10;
    overflow: hidden;
    @supports not (overflow: overlay) {
      overflow: auto;
    }

    ::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${theme.color.gray_20};
      margin-right: 2px;
      border-radius: 50px;
    }
    @supports not (overflow: overlay) {
      scrollbar-width: thin;
      scrollbar-color: ${theme.color.gray_20} transparent;
    }

    :hover {
      overflow-y: overlay;
    }

    position: absolute;
    display: ${isOpen ? "inherit" : "none"};
    top: calc(100% + 4px);
    width: 100%;
    max-height: 402px;
    border: 1px solid ${theme.color.gray_30};
    border-bottom: 0;
    background-color: ${theme.color.white};
    box-shadow: ${theme.boxShadow.shadow_bold};
    z-index: ${theme.zIndex.DROPDOWN};
  `}
`;

export const Option = styled.li`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.color.gray_30};
  `}
`;

export const OptionBtn = styled.button<{ status?: string }>`
  ${({ theme, status }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 40px;
    padding: 0 12px;
    font: ${theme.font.regular_14};
    color: ${theme.color.gray_60};
    background-color: ${theme.color.white};
    word-break: break-all;

    :hover {
      color: ${theme.color.gray_70};
      background-color: ${theme.color.gray_10};
    }

    &[data-selected="true"] {
      color: ${status === "default" && theme.color.blue_10};
      background-color: ${status === "default" && theme.color.blue_10_10};

      svg {
        display: ${status === "default" && "block"};

        path {
          fill: ${status === "default" && theme.color.blue_10};
        }
      }
    }
  `}
`;

export const NoOptionData = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    column-gap: 8px;
    border-bottom: 1px solid ${theme.color.gray_30};
    padding: 28px 0;

    svg {
      width: 20px;
      height: 20px;
    }
  `}
`;

export const NoOptionDataMsg = styled.p`
  ${({ theme }) => css`
    font: ${theme.font.regular_14};
    color: ${theme.color.gray_70};
  `}
`;

export const BrandWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

export const BrandItem = styled.li`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    max-width: fit-content;
    height: 40px;
    border: 1px solid ${theme.color.gray_30};
    border-radius: 2px;
    padding: 10px 53px 10px 13px;
    font: ${theme.font.regular_14};
    color: ${theme.color.black};
  `}
`;

export const DeleteBtn = styled.button`
  position: absolute;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
`;
