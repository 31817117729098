import React from "react";

import { History } from "components";
import type { Histories } from "types";
import * as S from "./PageLayout.styled";

interface PageLayoutProps {
  className?: string;
  children: React.ReactNode;
  layoutStaticData: {
    heading: string;
    subHeading?: string;
    histories?: Histories;
  };
}

const PageLayout = ({
  className,
  children,
  layoutStaticData,
}: PageLayoutProps) => {
  return (
    <S.PageLayout className={className}>
      {layoutStaticData.histories && (
        <History histories={layoutStaticData.histories} />
      )}
      <S.Heading>{layoutStaticData.heading}</S.Heading>
      {layoutStaticData.subHeading && (
        <S.SubHeading>{layoutStaticData.subHeading}</S.SubHeading>
      )}
      {children}
    </S.PageLayout>
  );
};

export default PageLayout;
