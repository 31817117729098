import { useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";

import { toastAtom } from "stores/common";

const useToast = () => {
  const setToastCompo = useSetRecoilState<any>(toastAtom);

  const addToast = (toast: any) => {
    const id = uuidv4();

    setToastCompo((prev: any) => [{ id, ...toast }, ...prev]);
  };

  const removeToast = (id: any) =>
    setToastCompo((prev: any) => prev.filter((toast: any) => toast.id !== id));

  return { addToast, removeToast };
};

export default useToast;
