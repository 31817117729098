import React from "react";

import { ImgPreviewLinks, LabelContent } from "components";
import type { GetBulletinReportDetailClientModel } from "types";
import * as S from "./Bulletin.styled";

interface BulletinProps {
  data: GetBulletinReportDetailClientModel;
}

const Bulletin = ({ data }: BulletinProps) => {
  return (
    <LabelContent css={S.labelContent} hasBg heading="게시글 상세">
      <LabelContent.ContentWrapper subItemCount={2}>
        <LabelContent.Content heading="작성자">
          {data.postUserNickname}
        </LabelContent.Content>
        <LabelContent.Content heading="작성 일시">
          {data.postCreated}
        </LabelContent.Content>
      </LabelContent.ContentWrapper>
      <LabelContent.ContentWrapper subItemCount={1}>
        <LabelContent.Content heading="카테고리">
          {data.postCategory}
        </LabelContent.Content>
      </LabelContent.ContentWrapper>
      <LabelContent.ContentWrapper subItemCount={1}>
        <LabelContent.Content css={S.content} heading="내용">
          {data.postContent}
        </LabelContent.Content>
      </LabelContent.ContentWrapper>
      {!!data.imgUrls.length && (
        <LabelContent.ContentWrapper subItemCount={1}>
          <LabelContent.Content heading="첨부 이미지">
            <ImgPreviewLinks imgUrls={data.imgUrls} />
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
      )}
    </LabelContent>
  );
};

export default Bulletin;
