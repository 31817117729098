import { useState, useEffect, useCallback } from "react";

export const useImgRotate = (imgUrlIndex: number) => {
  const [rotate, setRotate] = useState(0);

  const handleLeftRotate = useCallback(() => {
    setRotate(rotate - 90);
  }, [rotate]);

  const handleRightRotate = useCallback(() => {
    setRotate(rotate + 90);
  }, [rotate]);

  useEffect(() => {
    setRotate(0);
  }, [imgUrlIndex]);

  return {
    rotate,
    handleLeftRotate,
    handleRightRotate,
  };
};

export default useImgRotate;
