import React from "react";
import { map } from "lodash-es";

import { DataSection, Status } from "components";
import { useGetReviews } from "services";
import { REVIEW_REPORT_TABLE_HEADER } from "assets";
import { useFilterParams } from "hooks";
import { keys } from "utils/helper/table";
import type { GetReviewReportsQueryModel } from "types";
import * as S from "./ReviewReportTable.styled";

const ReviewReportTable = () => {
  const { filters } = useFilterParams<GetReviewReportsQueryModel["query"]>([
    "page",
    "category",
    "isChecked",
  ]);

  const { data, refetch, dataUpdatedAt } = useGetReviews({ query: filters });

  return (
    <DataSection
      totalData={data?.pageInfo.totalData!}
      refetch={refetch}
      dataUpdatedAt={dataUpdatedAt}
    >
      <S.ReviewTable title="리뷰 신고" columnTable={REVIEW_REPORT_TABLE_HEADER}>
        {map(data?.reports, (rowData) => (
          <DataSection.Table.SelectRowMovePage
            key={rowData.detailId}
            id={rowData.detailId}
            path={`/report/review/${rowData.detailId}`}
          >
            {map(keys(REVIEW_REPORT_TABLE_HEADER), (key) => (
              <DataSection.Table.Td key={key}>
                {key === "checked" ? (
                  <Status status={rowData[key]} />
                ) : (
                  rowData[key]
                )}
              </DataSection.Table.Td>
            ))}
          </DataSection.Table.SelectRowMovePage>
        ))}
      </S.ReviewTable>
    </DataSection>
  );
};

export default ReviewReportTable;
