import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { DataSection } from "components";
import { REPAIRSHOP_SUPPORT_TABLE_GRID_COLUMNS } from "assets";

export const RepairshopTable = styled(DataSection.Table)`
  ${({ theme }) => css`
    height: calc(
      100vh - ${theme.size.TABLE_WRAPPER_EXCEPT_FILTER} -
        ${theme.size.TABLE_WRAPPER_TWO_FILTER} - ${theme.size.TABLE_WRAPPER_TAB}
    );

    tr {
      grid-template-columns: ${REPAIRSHOP_SUPPORT_TABLE_GRID_COLUMNS};
    }
  `}
`;
