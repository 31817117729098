import React from "react";
import { useForm } from "react-hook-form";
import type { UseMutateFunction } from "@tanstack/react-query";

import { AnswerTextarea, Button, LabelContent, AnswerInput } from "components";
import { useToast } from "hooks";
import { HOOKFORM_ERR_OBJ, TOAST_MSG } from "assets";
import type { CreateSupportServerModel, CreateSupportQueryModel } from "types";
import * as S from "./WriteAnswer.styled";

interface WriteAnswerProps {
  helpId: string;
  submitFn: UseMutateFunction<any, unknown, CreateSupportQueryModel, unknown>;
}

const WriteAnswer = ({ helpId, submitFn }: WriteAnswerProps) => {
  const {
    formState: { errors },
    register,
    watch,
    handleSubmit,
  } = useForm({
    mode: "all",
    defaultValues: { answerTitle: "", answerContent: "" },
  });

  const { addToast } = useToast();

  const handleRegistAnswer = (data: CreateSupportServerModel) => {
    submitFn(
      {
        body: {
          answerTitle: data.answerTitle.trim(),
          answerContent: data.answerContent.trim(),
        },
        helpId,
      },
      {
        onSuccess: () => {
          addToast(TOAST_MSG.SUCCESS.SUPPORT_ANSWER);
        },
        onError: () => {
          addToast(TOAST_MSG.WARNING.FAIL);
        },
      },
    );
  };

  return (
    <LabelContent heading="답글 상세" hasBg>
      <S.Form onSubmit={handleSubmit(handleRegistAnswer)}>
        <LabelContent.ContentWrapper
          subItemCount={1}
          hasErr={!!errors?.answerTitle}
        >
          <LabelContent.Content css={S.content} heading="제목">
            <AnswerInput
              css={S.title}
              value={watch("answerTitle")}
              maxLength={30}
              register={register("answerTitle", {
                maxLength: 30,
                required: "제목을 입력하세요",
                validate: (value) =>
                  value.trim().length > 0 || HOOKFORM_ERR_OBJ.REQUIRED.message,
              })}
            />
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <LabelContent.ContentWrapper
          subItemCount={1}
          hasErr={!!errors?.answerContent}
        >
          <LabelContent.Content css={S.content} heading="내용">
            <AnswerTextarea
              css={S.answerContent}
              textareaValue={watch("answerContent")}
              maxLength={500}
              register={register("answerContent", {
                required: "내용을 입력하세요",
                maxLength: 500,
                validate: (value) =>
                  value.trim().length > 0 || HOOKFORM_ERR_OBJ.REQUIRED.message,
              })}
            />
          </LabelContent.Content>
        </LabelContent.ContentWrapper>
        <Button
          variant="primary"
          type="submit"
          label="등록"
          isDisabled={Object.keys(errors).length !== 0}
        />
      </S.Form>
    </LabelContent>
  );
};

export default WriteAnswer;
