import React from "react";
import { useNavigate } from "react-router-dom";

import { Division, LabelContent } from "components";
import { TOAST_MSG } from "assets";
import { useToast } from "hooks";
import * as S from "./Report.styled";

interface ReportProps<T, K> {
  data: T;
  contents: K;
  linkId?: string;
}

const Report = <
  T extends Record<string, any>,
  K extends readonly (readonly { key: string; heading: string }[])[],
>({
  data,
  contents,
  linkId,
}: ReportProps<T, K>) => {
  const navigate = useNavigate();
  const { addToast } = useToast();

  const content = (key: keyof T) => {
    switch (key) {
      case "repairshopName":
        const handleClickLink = () => {
          if (data.repairShopStatus === "active") {
            navigate(`/repairshop/${linkId}`);
          } else {
            addToast(TOAST_MSG.WARNING.DELETED_REPAIRSHOP);
          }
        };

        return (
          <>
            <S.LinkButton onClick={handleClickLink}>{data[key]}</S.LinkButton>
          </>
        );

      case "targetStatus":
        const blockedStart = `${data.blockedStart}`.split(" ")[0];
        const blockedEnd = `${data.blockedEnd}`.split(" ")[0];

        return data[key] === "제한" ? (
          <S.RedText>
            {data[key]} ({blockedStart} ~ {blockedEnd})
          </S.RedText>
        ) : (
          data[key]
        );

      default:
        return data[key];
    }
  };

  return (
    <>
      <Division css={S.division} />
      <S.CusomtLabelContent hasBg heading="신고 상세">
        {contents.map((row, i) => (
          <LabelContent.ContentWrapper key={i} subItemCount={row.length}>
            {row.map(({ key, heading }) => (
              <LabelContent.Content key={key} heading={heading}>
                {content(key)}
              </LabelContent.Content>
            ))}
          </LabelContent.ContentWrapper>
        ))}
      </S.CusomtLabelContent>
    </>
  );
};

export default Report;
