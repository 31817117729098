import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

import type { ButtonVariant } from "./Button";

const mixinContents = css`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

const mixinBtn = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 2px;
  padding: 0 20px;
`;

const primary = (theme: Theme) => css`
  ${mixinBtn};
  font: ${theme.font.medium_14};
  color: ${theme.color.white};
  background-color: ${theme.color.blue_10};

  &:not(:disabled):hover {
    background-color: ${theme.color.blue_20};
  }

  &:disabled {
    background-color: ${theme.color.blue_10_20};
    cursor: not-allowed;
  }
`;

export const secondary = (theme: Theme) => css`
  ${mixinBtn};
  font: ${theme.font.medium_14};
  border: 1px solid ${theme.color.gray_20};
  color: ${theme.color.gray_70};
  background-color: ${theme.color.gray_10};

  &:not(:disabled):hover {
    border: 1px solid ${theme.color.gray_30};
    background-color: ${theme.color.gray_20};
  }

  &:disabled {
    opacity: 0.6;
  }
`;

const third = (theme: Theme) => css`
  ${mixinBtn};
  font: ${theme.font.medium_14};
  border: 1px solid ${theme.color.gray_20};
  color: ${theme.color.blue_10};
  background-color: ${theme.color.gray_10};

  &:not(:disabled):hover {
    border: 1px solid ${theme.color.gray_30};
    color: ${theme.color.blue_20};
    background-color: ${theme.color.gray_20};
  }

  &:disabled {
    opacity: 0.2;
  }
`;

const error = (theme: Theme) => css`
  ${mixinBtn};
  font: ${theme.font.medium_14};
  color: ${theme.color.red_20};
  background-color: ${theme.color.gray_10};
  border: 1px solid ${theme.color.gray_20};

  &:not(:disabled):hover {
    border: 1px solid ${theme.color.gray_30};
    background-color: ${theme.color.gray_20};
  }

  &:disabled {
    opacity: 0.2;
    background-color: ${theme.color.gray_10};
  }
`;

interface ButtonProps {
  variant: ButtonVariant;
  isLoading: boolean;
}

export const Button = styled.button<ButtonProps>`
  ${({ theme, variant, isLoading }) => css`
    ${mixinContents};
    ${variant === "primary" && primary(theme)};
    ${variant === "secondary" && secondary(theme)};
    ${variant === "third" && third(theme)};
    ${variant === "error" && error(theme)};
    pointer-events: ${isLoading && "none"};
  `}
`;

export const LoadingContents = styled.div`
  ${mixinContents};
  visibility: hidden;
`;

export const spinner = css`
  position: absolute;
`;
