import React from "react";

import NotFound from "pages/notFound/NotFound";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  notFoundError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, notFoundError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true });

    if (error.name === "AxiosError") {
      this.setState({ notFoundError: true });
    }
  }

  resetErrorState() {
    this.setState({ hasError: false, notFoundError: false });
  }

  render() {
    if (this.state.notFoundError) {
      return <NotFound resetErrorState={this.resetErrorState.bind(this)} />;
    }
    return this.props.children;
  }
}
