export const TRUCK_FILTER_STATUS = {
  requested: "승인대기",
  retried: "재요청",
  failed: "반려",
  active: "등록완료",
};

export const TRUCK_DETAIL_STATUS = {
  active: "등록완료",
  requested: "승인대기",
  failed: "반려",
  retried: "재요청",
} as const;

export const ASK_STATUS = {
  답변대기: "답변대기",
  답변완료: "답변완료",
} as const;

export const REPAIRSHOP_STATUS = {
  active: "활성화",
  inactive: "비활성화",
} as const;
