import React from "react";
import { map } from "lodash-es";

import { DataSection, Status } from "components";
import { useGetComments } from "services";
import { COMMENT_REPORT_TABLE_HEADER } from "assets";
import { useFilterParams } from "hooks";
import { keys } from "utils/helper/table";
import type { GetCommentsQueryModel } from "types";
import * as S from "./CommentReportTable.styled";

const CommentReportTable = () => {
  const { filters } = useFilterParams<GetCommentsQueryModel["query"]>([
    "page",
    "category",
    "status",
  ]);

  const { data, refetch, dataUpdatedAt } = useGetComments({ query: filters });

  return (
    <DataSection
      totalData={data?.pageInfo.totalData!}
      refetch={refetch}
      dataUpdatedAt={dataUpdatedAt}
    >
      <S.CommentTable
        title="댓글 신고"
        columnTable={COMMENT_REPORT_TABLE_HEADER}
      >
        {map(data?.reports, (rowData) => (
          <DataSection.Table.SelectRowMovePage
            key={rowData.detailId}
            id={rowData.detailId}
            path={`/report/comment/${rowData.detailId}`}
          >
            {map(keys(COMMENT_REPORT_TABLE_HEADER), (key) => (
              <DataSection.Table.Td key={key}>
                {key === "status" ? (
                  <Status status={rowData[key]} />
                ) : (
                  rowData[key]
                )}
              </DataSection.Table.Td>
            ))}
          </DataSection.Table.SelectRowMovePage>
        ))}
      </S.CommentTable>
    </DataSection>
  );
};

export default CommentReportTable;
